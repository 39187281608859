import gql from 'graphql-tag';
import CheckoutFragmentContext from '../fragments/checkoutFragmentContext';
import CheckoutReachFragment from '../fragments/checkoutReachFragment';

/*

Checkout create

*/
export const checkoutCreate = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!, $country: CountryCode)
  @inContext(country: $country) {
    checkoutCreate(input: $input) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragmentContext
      }
    }
  }
  ${CheckoutFragmentContext}
`;

/*

Add a Line Item

*/
export const checkoutLineItemsAdd = gql`
  mutation checkoutLineItemsAdd(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
    $country: CountryCode
  ) @inContext(country: $country){
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragmentContext
      }
    }
  }
  ${CheckoutFragmentContext}
`;

/*

Update a Line Item

*/
export const checkoutLineItemsUpdate = gql`
  mutation checkoutLineItemsUpdate(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemUpdateInput!]!
    $country: CountryCode
  ) @inContext(country: $country) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragmentContext
      }
    }
  }
  ${CheckoutFragmentContext}
`;

/*

Remove a Line Item

*/
export const checkoutLineItemsRemove = gql`
  mutation checkoutLineItemsRemove(
    $checkoutId: ID!
    $lineItemIds: [ID!]!
    $country: CountryCode
  ) @inContext(country: $country) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragmentContext
      }
    }
  }
  ${CheckoutFragmentContext}
`;

/*

Update Checkout Note

*/
export const checkoutNoteUpdate = gql`
  mutation checkoutAttributesUpdateV2(
    $checkoutId: ID!
    $input: CheckoutAttributesUpdateV2Input!
    $country: CountryCode
  ) @inContext(country: $country) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragmentContext
      }
    }
  }
  ${CheckoutFragmentContext}
`;

/**
 * updates checkout shipping address
 */
export const checkoutShippingAddressUpdate = gql`
  mutation checkoutShippingAddressUpdateV2(
    $checkoutId: ID!
    $input: MailingAddressInput!
  ){
    checkoutShippingAddressUpdateV2(
      checkoutId: $checkoutId
      shippingAddress: $input
    ) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutReachFragment
      }
    }
  }
  ${CheckoutReachFragment}
`;

/**
 * updates checkout shipping line
 */
export const checkoutShippingLineUpdate = gql`
  mutation checkoutShippingLineUpdate(
    $checkoutId: ID!
    $shippingRateHandle: String!
    $country: CountryCode
  ) @inContext(country: $country) {
    checkoutShippingLineUpdate(
      checkoutId: $checkoutId
      shippingRateHandle: $shippingRateHandle
    ) {
      checkoutUserErrors {
        message
        field
      }
      checkout {
        ...CheckoutReachFragment
      }
    }
  }
  ${CheckoutReachFragment}
`;
