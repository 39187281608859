import {
  compareStrings,
  sortObjectEntries,
  getKeyByValue,
  getLabel,
} from '@/utils/index';
import { ICmsTagsGroup } from '@/interfaces/index';
import {
  ICollectionTagFields,
  ISettingsFields,
} from '@/types/contentful/contentful';
import { Facet, FacetValue, ProductSearch } from '@/lib/models/types/product';

export const CATALOG_BASE_URL = `${process.env.NEXT_PUBLIC_CATALOG_API}/products`;
// filters whose drawer height is not limited
export const UNLIMITED_DRAWER = ['price'];

// filters whose values must not be sorted alphabetically
const EXCLUDE_FROM_SORTING = ['delivery-time', 'price'];

//  filters to be shown in FiltersPanel on CollectionPage
const FACETS_TO_BE_SHOWN = [
  'artisan',
  'color',
  'gender',
  'material',
  // 'markup', //temporarily hidden
  'delivery-time',
  'price',
  'b2b-minimum-order-quantity',
  'season',
];

//  filters to be retrieved in FiltersPanel on CollectionPage
export const FACETS_TO_BE_RETRIEVED = ['category-path', ...FACETS_TO_BE_SHOWN];

// all valid Url params accepted in collections page url
const VALID_COLLECTIONS_URL_PARAMS = [
  'p',
  'sort_by',
  'collection',
  'season',
  'made-in',
  ...FACETS_TO_BE_RETRIEVED,
];

export const CATALOG_QUERY_DEFAULT_PARAMS = {
  'not-color': 'custom',
  //Buti cannot show its products in storefront
  'not-artisan-registry-id': '1004',
  'sold-out': 'false',
};

export const filterValidQueryParams = (queryParameters: {
  [key: string]: string | string[] | undefined;
}) => {
  if (queryParameters) {
    const queryParametersEntries = Object.entries(queryParameters);
    if (queryParametersEntries.length > 0) {
      return Object.fromEntries(
        queryParametersEntries.filter(([key]) =>
          VALID_COLLECTIONS_URL_PARAMS.includes(key)
        )
      );
    }
  }

  return {};
};

export const mashFacetsWithCms = (
  filterFacets: Facet[],
  filterCollectionTags: ICmsTagsGroup,
  currencySymbol: string | null
) => {
  const isFilterToBeShown = (facet: Facet) => {
    return FACETS_TO_BE_SHOWN.includes(facet.id.toLowerCase());
  };

  let result: ICmsTagsGroup = {};

  if (filterFacets && filterFacets.length > 0) {
    filterFacets.filter(isFilterToBeShown).forEach(facet => {
      const facetID = facet.id;
      if (facet.values) {
        result[facetID] = [];
        if (!EXCLUDE_FROM_SORTING.includes(facetID)) {
          sortFacetValues(facet.values);
        }
        if (facetID === 'gender') {
          let filteredGenders = [];
          if (
            filterCollectionTags['gender'] &&
            filterCollectionTags['gender'].length > 0
          ) {
            filteredGenders = facet.values.map(facetValue => {
              const facetValuetag =
                facet.displayValue.toUpperCase() +
                '_' +
                facetValue.displayValue;
              let cmsGender = filterCollectionTags['gender'].find(gender => {
                return gender.tag === facetValuetag;
              });

              return {
                tag: facetValuetag,
                slug: cmsGender?.slug || facetValue.id,
                label: cmsGender?.label || facetValue.displayValue,
              };
            });

            result[facetID] = filteredGenders;
          }
        } else if (facetID === 'color' || facetID === 'artisan') {
          let colors = facet.values.map(facetValue => {
            return {
              tag: '',
              slug: facetValue.id,
              label: facetValue.displayValue,
            };
          });
          result[facetID] = colors;
        } else {
          let collectionTagFilter: ICollectionTagFields[] | null = null;

          if (Object.keys(filterCollectionTags).includes(facetID)) {
            collectionTagFilter = filterCollectionTags[facetID];
          }

          result[facetID] = [];

          let label;
          facet.values.forEach(value => {
            label = getLabel(
              value.queryValue,
              collectionTagFilter,
              value.displayValue,
              facetID,
              currencySymbol
            );

            result[facetID].push({
              slug: value.queryValue,
              tag: facet.displayValue.toUpperCase() + '_' + value.displayValue,
              label: label,
            });
          });
        }
      }
    });
  }

  return result;
};

export const getCategoryPathFacetValues = (
  queryOptions: Record<string, string>,
  data: {
    url: string;
    result: ProductSearch;
  }[]
) => {
  let categoryPathFacet = null;
  const categoryPathUrl =
    getKeyByValue(queryOptions, 'category-path') ||
    getKeyByValue(queryOptions, '*') ||
    null; // *

  const categoryPathData = data.find(addData => {
    return addData.url === categoryPathUrl;
  });

  if (categoryPathData) {
    const categoryPathDataFacets = categoryPathData.result.facets;

    if (categoryPathDataFacets && categoryPathDataFacets.length > 0) {
      categoryPathFacet =
        categoryPathDataFacets.find(f => {
          return f.id === 'category-path';
        }) ?? null;
    }
  }
  if (categoryPathFacet?.values) {
    sortFacetValues(categoryPathFacet?.values);
  }

  return categoryPathFacet?.values;
};

export const getFilters = (
  catalogResults: ProductSearch | null,
  filterCollectionTags: {
    [key: string]: ICollectionTagFields[];
  },
  currencySymbol: string
): ICmsTagsGroup | null => {
  let mashedFilters = null;

  if (catalogResults) {
    mashedFilters = mashFacetsWithCms(
      catalogResults.facets,
      filterCollectionTags,
      currencySymbol
    );

    mashedFilters = sortObjectEntries(mashedFilters);
  }
  return mashedFilters;
};

export const sortFacetValues = (facetValues: FacetValue[]) => {
  facetValues.sort((obj1, obj2) =>
    compareStrings(obj1.displayValue, obj2.displayValue)
  );
  facetValues.forEach(({ values = [] }) => sortFacetValues(values));
};

export const getCollapseByParam = (
  currentUrl: string,
  settings?: ISettingsFields
) => {
  const toBeCollapsedByName = (settings?.collapsedByNameCollections || []).some(
    rgx => {
      const regex = new RegExp(rgx, 'g');
      return regex.test(currentUrl);
    }
  );

  return toBeCollapsedByName ? 'name' : 'handle';
};
