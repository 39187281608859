import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
interface ITooltip extends TooltipProps {}

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

const Tooltip: React.FC<ITooltip> = ({ children, ...props }) => {
  return <LightTooltip {...props}>{children}</LightTooltip>;
};

export default Tooltip;
