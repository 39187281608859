export class AppError<O = any> extends Error {
  constructor(message?: string, public original?: O) {
    super(message);
  }
}

export class FetchError<O = any> extends AppError<O> {
  _tag = 'fetchError' as const;
}

export class DecodingError<O = any> extends AppError<O> {
  _tag = 'decodingError' as const;
}
