import countryCodes from '@/utils/currencyByCountryCode.json';
import { CurrencyCode } from '@/types/shopify/admin/globalTypes';
import { CountryCode } from '@/types/shopify/globalTypes';

export type CurrenciesData = Record<
  // TODO:  make cohabit CountryCode enum with this new Record Type and then make this type available throughout the entire application
  string,
  {
    label: string;
    name: string;
    name_plural: string;
    symbol: string;
    locale: string;
    isoCountryCode: string;
  }
>;

export type CountryCurrency = Record<
  // TODO:  make cohabit CountryCode enum with this new Record Type and then make this type available throughout the entire application
  CountryCode,
  CurrencyCode
>;

export const DEFAULT_CURRENCY = 'EUR' as CurrencyCode;
export const SUPPORTED_CURRENCIES: CurrencyCode[] = [
  CurrencyCode.EUR,
  CurrencyCode.AED,
  CurrencyCode.AUD,
  CurrencyCode.CAD,
  CurrencyCode.CNY,
  CurrencyCode.DKK,
  CurrencyCode.GBP,
  CurrencyCode.HKD,
  CurrencyCode.JPY,
  CurrencyCode.KRW,
  CurrencyCode.NOK,
  CurrencyCode.SEK,
  CurrencyCode.SGD,
  CurrencyCode.TWD,
  CurrencyCode.USD,
];

export const isCurrencySupported = (
  currencyCode: CurrencyCode,
  supportedCurrencyCodes: CurrencyCode[]
) => {
  return supportedCurrencyCodes.includes(currencyCode);
};

export const retrieveCurrency = (customerCountryCode: CountryCode | null) => {
  const currenciesData = countryCodes as unknown as CountryCurrency;
  if (
    customerCountryCode &&
    currenciesData &&
    currenciesData[customerCountryCode]
  ) {
    if (
      isCurrencySupported(
        currenciesData[customerCountryCode],
        SUPPORTED_CURRENCIES
      )
    ) {
      return currenciesData[customerCountryCode] as CurrencyCode;
    }
  }
  return DEFAULT_CURRENCY as CurrencyCode;
};
