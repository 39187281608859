import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setWishlist } from '@/store/actions/wishlist';
import { RootState } from '@/store/store';
import {
  setLocalStorage,
  resetLocalStorage,
  getLocalStorage,
} from '@/utils/index';
import { getProductsByHandles } from '@/lib/models/product';
import { ProductWithVariants } from '@/lib/models/types/product';
import { IWishlist, IWishlistItem } from '@/types/wishlist';
import { FetchError } from './typings/errors';
import { isOk, Result } from './typings/result';
import { foldEnvironment } from './environment';
import { httpGateway } from '@/shared/HttpGateway';

function Wishlist() {
  const wishlistLocalKey = foldEnvironment(
    () => 'mirtaWishlistDev',
    () => 'mirtaWishlist'
  );

  const addListItem = useSelector(
    (state: RootState) => state.wishlistReducer.addListItem
  );

  const wishlist = useSelector(
    (state: RootState) => state.wishlistReducer.wishlist
  );

  const removeListItem = useSelector(
    (state: RootState) => state.wishlistReducer.removeListItem
  );

  const customer = useSelector(state => (state as any).userReducer.customer);
  const loadingCustomer = useSelector(
    state => (state as any).userReducer.loadingCustomer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    let mirtaWishlist = getMirtaWishlist();

    if (!loadingCustomer && !customer) {
      resetLocalStorage('wishlistSession');
      syncWishlistNoLogged(mirtaWishlist);
    }
    if (!loadingCustomer && customer) {
      const localStorageWishlistSession = getLocalStorage('wishlistSession');

      if (localStorageWishlistSession && localStorageWishlistSession !== '') {
        const wishlistSession = JSON.parse(localStorageWishlistSession);
        const regid = wishlistSession.regid;
        const sessionid = wishlistSession.sessionid;

        initWishlist(regid, sessionid);
      } else {
        httpGateway
          .get<{ regid: string; sessionid: string } | null>(
            `/api/v1/wishlist/init?email=${encodeURIComponent(customer.email)}`
          )
          .then(data => {
            if (isOk(data) && data.result) {
              const regid = data.result.regid;
              const sessionid = data.result.sessionid;

              initWishlist(regid, sessionid);
            }
          });
      }
    }
  }, [customer, loadingCustomer]);

  useEffect(() => {
    if (addListItem && wishlist) {
      const result = wishlist.filter(
        w => w.empi.toString() === addListItem.empi.toString()
      );
      if (result.length === 0) {
        const newItems = [addListItem, ...wishlist];
        setLocalStorage(wishlistLocalKey, JSON.stringify(newItems));
        dispatch(setWishlist(newItems));
        if (!loadingCustomer && customer) {
          manageList([addListItem], 'a');
        }
      }
    }
  }, [addListItem]);

  useEffect(() => {
    if (wishlist && removeListItem) {
      const result = wishlist.filter(item => {
        return item.empi.toString() !== removeListItem.empi.toString();
      });

      setLocalStorage(wishlistLocalKey, JSON.stringify(result));
      dispatch(setWishlist(result));

      if (!loadingCustomer && customer) {
        manageList([removeListItem], 'd');
      }
    }
  }, [removeListItem]);

  const initWishlist = (regid: string, sessionid: string) => {
    fetchList(regid, sessionid).then(res => {
      if (res && res.lid && res.listcontents && res.listcontents.length >= 0) {
        setLocalStorage(
          'wishlistSession',
          JSON.stringify({
            regid,
            sessionid,
            lid: res.lid,
          })
        );

        syncWishlist(res.listcontents);
      } else {
        createList(regid, sessionid).then(res => {
          if (res && res.listcontents && res.listcontents.length > 0) {
            setLocalStorage(
              'wishlistSession',
              JSON.stringify({
                regid,
                sessionid,
                lid: res.lid,
              })
            );

            syncWishlist(res.listcontents);
          }
        });
      }
    });
  };

  const getMirtaWishlist = () => {
    let mirtaWishlist: IWishlistItem[] = [];

    const localStorageWishlist = getLocalStorage(wishlistLocalKey);
    if (localStorageWishlist && localStorageWishlist !== '') {
      mirtaWishlist = JSON.parse(localStorageWishlist) as IWishlistItem[];
    }
    return mirtaWishlist;
  };

  const fetchList = (
    regid: string,
    sessionid: string
  ): Promise<IWishlist | void> => {
    return new Promise(resolve => {
      fetch(`/api/v1/wishlist/fetchlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          regid,
          sessionid,
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data && data.length > 0) {
            const { listcontents, lid } = data[0];
            resolve({ lid, listcontents });
          } else {
            resolve();
          }
        });
    });
  };

  const createList = (
    regid: string,
    sessionid: string
  ): Promise<IWishlist | void> => {
    return new Promise(resolve => {
      fetch(`/api/v1/wishlist/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          regid,
          sessionid,
          lname: 'My Wishlist',
        }),
      })
        .then(response => response.json())
        .then(result => {
          if (result && result.lid) {
            resolve({ lid: result.lid, listcontents: [] });
          } else {
            resolve();
          }
        });
    });
  };

  const manageList = (data: IWishlistItem[], action: 'a' | 'd'): void => {
    const localStorageWishlistSession = getLocalStorage('wishlistSession');
    if (!localStorageWishlistSession) return;

    let payload: IWishlistItem[] = [];
    data.forEach(item => {
      payload.push({
        ...item,
        du: process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN + '/products/' + item.du,
      });
    });

    const body = {
      ...JSON.parse(localStorageWishlistSession),
      // lid: initData.lid,
      // regid: initData.regid,
      // sessionid: initData.sessionid,
      action: action,
      payload: payload,
    };
    fetch(`/api/v1/wishlist/manage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  };

  const syncWishlistNoLogged = async (
    mirtaWishlist: IWishlistItem[]
  ): Promise<void> => {
    let payload: string[] = [];

    mirtaWishlist.forEach(item => {
      const subItemDu = item.du.slice(item.du.lastIndexOf('/') + 1);
      payload.push(subItemDu);
    });

    let products: Result<ProductWithVariants[], FetchError> | null = null;
    if (payload.length > 0) {
      products = await getProductsByHandles(payload);
    }

    if (products && isOk(products)) {
      const transformedNode = products.result.flatMap(item => {
        if (item.id && item.variant.id && item.handle) {
          return [
            {
              empi: item.id,
              epi: item.variant.id,
              du: item.handle,
            },
          ];
        } else {
          return [];
        }
      });

      setLocalStorage(wishlistLocalKey, JSON.stringify(transformedNode));

      dispatch(setWishlist(transformedNode));
    }
  };

  const syncWishlist = async (wishlistList: IWishlistItem[]): Promise<void> => {
    let mirtaWishlist = getMirtaWishlist();

    let result: IWishlistItem[] = [];
    mirtaWishlist.forEach(w => {
      const res = wishlistList.filter(
        item => item.empi.toString() === w.empi.toString()
      );

      if (res && res.length === 0) {
        result.push(w);
      }
    });

    const newItems = [...result, ...wishlistList];

    let payload: string[] = [];

    newItems.forEach((item: IWishlistItem) => {
      const subItemDu = item.du.slice(item.du.lastIndexOf('/') + 1);
      payload.push(subItemDu);
    });

    const products: Result<ProductWithVariants[], FetchError> =
      await getProductsByHandles(payload);

    if (products && isOk(products)) {
      const transformedNode = products.result.flatMap(item => {
        if (item.id && item.variant.id && item.handle) {
          return [
            {
              empi: item.id,
              epi: item.variant.id,
              du: item.handle,
            },
          ];
        } else {
          return [];
        }
      });

      let nodesById: string[] = products.result.flatMap(p =>
        p.id ? [p.id] : []
      );
      let nodesToCancel: IWishlistItem[] = [];
      const nodesToAdd: IWishlistItem[] = [];

      result.forEach(item => {
        if (nodesById.indexOf(item.empi) > -1) {
          nodesToAdd.push(item);
        } else {
          nodesToCancel.push(item);
        }
      });

      setLocalStorage(wishlistLocalKey, JSON.stringify(transformedNode));

      dispatch(setWishlist(transformedNode));
      if (nodesToAdd.length > 0) {
        manageList(nodesToAdd, 'a');
      }
      if (nodesToCancel.length > 0) {
        manageList(nodesToCancel, 'd');
      }
    }
  };

  return null;
}

export default Wishlist;
