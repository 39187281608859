import gql from 'graphql-tag';
import CheckoutFragmentContext from '../fragments/checkoutFragmentContext';
import CheckoutReachFragment from '../fragments/checkoutReachFragment';

export const checkoutQuery = gql`
  query checkoutQuery($id: ID!, $country: CountryCode)
  @inContext(country: $country) {
    node(id: $id) {
      id
      ... on Checkout {
        ...CheckoutFragmentContext
      }
    }
  }
  ${CheckoutFragmentContext}
`;

export const checkoutReachQuery = gql`
  query checkoutReachQuery($id: ID!, $country: CountryCode)
  @inContext(country: $country) {
    node(id: $id) {
      id
      ... on Checkout {
        ...CheckoutReachFragment
      }
    }
  }
  ${CheckoutReachFragment}
`;

export const checkoutShippingRatesQuery = gql`
  query checkoutShippingRatesQuery($checkoutId: ID!, $country: CountryCode)
  @inContext(country: $country) {
    node(id: $checkoutId) {
      id
      ... on Checkout {
        availableShippingRates {
          ready
          shippingRates {
            handle
            price {
              amount
              currencyCode
            }
            title
          }
        }
      }
    }
  }
`;

export const checkoutProductHandleQuery = gql`
  query checkoutProductHandleQuery($id: ID!) {
    node(id: $id) {
      ... on Checkout {
        lineItems(first: 250) {
          edges {
            node {
              variant {
                product {
                  handle
                  vendor
                }
              }
            }
          }
        }
      }
    }
  }
`;
