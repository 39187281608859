export const ARTISANS_PATH = 'brands';
export const MAGAZINE_PATH = 'magazine';
export const ASC_ORDER = 'ASC';
export const DESC_ORDER = 'DESC';
export const PRICE_ORDER_DESC = 'Price High-Low';
export const PRICE_ORDER_ASC = 'Price Low-High';
export const DEFAULT_ORDER_LABEL = 'Featured';
export const DEFAULT_ORDER = 'relevance';
export const DEFAULT_ORDER_COLLECTION = 'COLLECTION_DEFAULT';
export const NEW_IN_ORDER_LABEL = 'What’s New';
export const NEW_IN_ORDER = 'CREATED_AT-DESC';
export const NEW_IN_ORDER_COLLECTION = 'CREATED-DESC';
export const META_ROBOTS_DEFAULT =
  'index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large';
export const META_ROBOTS_ADV = 'noindex, follow';
export const B2B_CHECKOUT_MIN_AMOUNT = 400;
export const B2B_APPROVED_COOKIE = "L'utente risulta approvato";
export const B2B_NOT_APPROVED_COOKIE = "L'utente non risulta approvato";
export const DEFAULT_PAGE_SECONDS_TTL = 60;
export const A_Z_ORDER = 'A-Z';
export const BRANDS_PER_PAGE = 20;
export const AMOUNT_WELCOLME_100 = 1000;
export const PERSONALIZED_CAROUSELS = 3;
export const FALLBACK_PERSONALIZED_CAROUSEL = 'best-seller';
