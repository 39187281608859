import { IColorsObj } from '@/utils/Colors';
import { SetColorsAction } from '../actions/colors';
import { actionTypes } from '../actions/actionTypes';

type Action = SetColorsAction;

export interface ColorsState {
  colors: IColorsObj;
}

const initialState: ColorsState = {
  colors: {},
};

const colorsReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_COLORS:
      return {
        ...state,
        colors: action.payload,
      };
    default:
      return state;
  }
};

export default colorsReducer;
