import { actionTypes } from '../actions/actionTypes';
import { CheckoutFragment } from '@/types/shopify/CheckoutFragment';

import {
  ToggleCartAction,
  LoadingCartAction,
  SetCartAction,
  SetCartErrorAction,
  AddIdWithNoMinQtyAction,
  DeleteIdWithNoMinQty,
  SetCartHandlesAction,
} from '../actions/cart';

type Action =
  | ToggleCartAction
  | LoadingCartAction
  | SetCartAction
  | SetCartErrorAction
  | AddIdWithNoMinQtyAction
  | DeleteIdWithNoMinQty
  | SetCartHandlesAction;
export interface CartState {
  isDrawerOpen: boolean;
  loadingCart: boolean;
  cart: CheckoutFragment | null;
  cartLoaded: boolean;
  errorSetCart: null | string;
  cartTotal: number;
  idsWithNoMinQty: string[];
  cartHandles: string[];
}

const initialState: CartState = {
  isDrawerOpen: false,
  loadingCart: false,
  cart: null,
  cartLoaded: false,
  errorSetCart: null,
  cartTotal: 0,
  idsWithNoMinQty: [],
  cartHandles: [],
};

const cartReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_CART:
      return {
        ...state,
        isDrawerOpen: action.payload,
      };
    case actionTypes.LOADING_CART:
      return {
        ...state,
        loadingCart: true,
      };
    case actionTypes.SET_CART:
      let total = 0;
      action.payload.lineItems.edges.forEach(
        (item: { node: { quantity: number } }) => {
          total += item.node.quantity;
        }
      );
      return {
        ...state,
        loadingCart: false,
        cartLoaded: true,
        cart: action.payload,
        cartTotal: total,
        errorSetCart: null,
      };

    case actionTypes.ADD_ID_WITH_NO_MIN_QTY:
      const foundID = state.idsWithNoMinQty.find(id => id === action.payload);

      return {
        ...state,
        idsWithNoMinQty: foundID
          ? [...state.idsWithNoMinQty]
          : [...state.idsWithNoMinQty, action.payload],
      };
    case actionTypes.DELETE_ID_WITH_NO_MIN_QTY:
      return {
        ...state,
        idsWithNoMinQty: [
          ...state.idsWithNoMinQty.filter(id => id !== action.payload),
        ],
      };
    case actionTypes.SET_CART_ERROR:
      return {
        ...state,
        loadingCart: false,
        errorSetCart: action.payload,
      };
    case actionTypes.SET_CART_HANDLES:
      return {
        ...state,
        cartHandles: action.payload,
      };

    default:
      return state;
  }
};

export default cartReducer;
