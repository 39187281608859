export const makeLink = (
  linkType: string,
  href: string,
  parentHref?: string | null
) => {
  let url = '';
  const _href = href || '';
  if (linkType !== undefined && linkType === 'url') {
    url = `${_href}`;
  }
  if (linkType !== undefined && linkType === 'pages') {
    url = `/m/${_href}`;
  }
  if (linkType !== undefined && linkType === 'marketing') {
    url = `/l/${_href}`;
  }
  if (linkType !== undefined && linkType === 'custom') {
    url = `/${_href}`;
  }
  if (linkType !== undefined && linkType === 'collections') {
    url = `/collections/${_href}`;
  }
  if (linkType !== undefined && linkType === 'tag') {
    if (parentHref) {
      url = `/collections/${parentHref}/${_href}/`;
    }
  }
  if (linkType !== undefined && linkType === 'products') {
    url = `/products/${_href}`;
  }

  if (linkType !== undefined && linkType === 'artisans') {
    url = `/brands/${_href}`;
  }

  if (linkType !== undefined && linkType === 'magazine') {
    url = `/magazine/${_href}`;
  }
  if (linkType !== undefined && linkType === 'email') {
    url = `mailto:${_href}`;
  }

  return url;
};
