import getApolloClient from '@/lib/apolloClient';
import { FetchError } from '@/utils/typings/errors';
import { err, ok, Result } from '@/utils/typings/result';
import {
  ApolloCache,
  DefaultContext,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from '@apollo/client';

type ApolloFetcherInput<T = any, TVariables = OperationVariables> =
  | QueryOptions<TVariables, T>
  | MutationOptions<T, TVariables, DefaultContext, ApolloCache<any>>;

export async function safeFetcher<T = any, TVariables = OperationVariables>(
  data: ApolloFetcherInput<T, TVariables>
): Promise<Result<T, FetchError>> {
  const apolloClient = getApolloClient(false);

  try {
    const result =
      'query' in data
        ? await apolloClient.query<T, TVariables>(data)
        : await apolloClient.mutate<T, TVariables>(data);

    if (result.data) {
      return ok(result.data);
    } else {
      return err(
        new FetchError('apollo call returned an error', result.errors)
      );
    }
  } catch (e) {
    return err(new FetchError('unknown error', e));
  }
}

export async function fetcher(payload: string) {
  if (!payload) {
    return null;
  }
  const data = JSON.parse(payload);
  const apolloClient = getApolloClient(false);
  let input;

  if (!('query' in data || 'mutation' in data)) {
    throw new Error('Invalid query/mutation provided to fetcher');
  }

  if (data.query) {
    input = {
      query: data.query,
      variables: data.variables,
    };
  } else {
    input = {
      mutation: data.mutation,
      variables: data.variables,
    };
  }

  const result =
    'query' in input
      ? await apolloClient.query(input)
      : await apolloClient.mutate(input);

  return result.data;
}

export async function getInstagramFeed() {
  return fetch(`${process.env.NEXT_PUBLIC_INSTAGRAM_API}`).then(response =>
    response.json()
  );
}

interface IResData {
  status: string;
  message?: string;
}

export async function postEmailKlaviyo(
  email: string,
  gender: string,
  source: string,
  g: string
) {
  let curUUID = window.Mirta.getCurrentId()['uuid'];
  var _uuid = window.Mirta.create_UUID();
  if (curUUID && curUUID != '') {
    _uuid = curUUID;
  }

  window.Mirta.klaviyo.identifyInCookie(email, _uuid);

  return fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: new URLSearchParams({
      email: email,
      $consent: 'email',
      $source: source,
      $fields: '$consent,$source,GENDER,$id',
      GENDER: gender,
      g: g,
      //g: 'ULGhZ6',
      $id: _uuid,
    }),
  }).then(response => {
    const result = response.json();

    return new Promise<IResData>(resolve => {
      result
        .then(res => {
          if (res.data.is_subscribed) {
            const analyticsPayload = window.Mirta.addUserInfoToAnalyticsPayload(
              {
                pageUrl: location.href,
                subscriptionType: source,
                UUID: _uuid,
                gender: gender,
              }
            );
            window.Mirta.pushEventToAnalytics(
              'subscriptionError',
              analyticsPayload
            );

            resolve({
              status: 'ko',
              message: `This email is already subscribed <br/>to our newsletter`,
            });
          } else {
            const analyticsPayload = window.Mirta.addUserInfoToAnalyticsPayload(
              {
                pageUrl: location.href,
                subscriptionType: source,
                UUID: _uuid,
                gender: gender,
              }
            );

            window.Mirta.pushEventToAnalytics('subscription', analyticsPayload);
            resolve({
              status: 'ok',
            });
          }
        })
        .catch(_ => {
          const analyticsPayload = window.Mirta.addUserInfoToAnalyticsPayload({
            pageUrl: location.href,
            subscriptionType: source,
            UUID: _uuid,
            gender: gender,
          });

          window.Mirta.pushEventToAnalytics(
            'subscriptionError',
            analyticsPayload
          );

          resolve({
            status: 'ko',
            message: `There was an error during your subscription`,
          });
        });
    });
  });
}

export function isPreview(cookie: string | undefined) {
  if (!cookie) return false;
  return cookie.indexOf('preview=true') > -1;
}
