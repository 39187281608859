import React from 'react';
import styled, { css } from 'styled-components';

export type TypoDataType =
  | 'heading_1'
  | 'heading_2'
  | 'heading_3'
  | 'heading_4'
  | 'heading_5'
  | 'subtitle_1'
  | 'subtitle_2'
  | 'body_1'
  | 'caption'
  | 'caption_1'
  | 'caption_2'
  | 'link';

type KnownTags = keyof JSX.IntrinsicElements;
interface ITypo extends React.HTMLAttributes<HTMLDivElement> {
  component?: KnownTags;
  color?: string;
  children?: React.ReactNode;
  textcolor?: string;
  align?: 'center' | 'right' | 'left';
  datatype?: TypoDataType;
  clampMiddleValue?: string;
}

const CustomTypo = styled.div<ITypo>`
  color: ${props => (props.textcolor ? props.textcolor : '')};
  text-align: ${props => (props.align ? props.align : '')};

  ${props =>
    props.datatype === 'heading_1' &&
    css`
      font-weight: 500;
      /* line-height: clamp(4.5rem, 4.3725rem + 0.5229vw, 5rem); //72px - 80px */

      /* font-size: clamp(
        4.1725rem,
        4.0207rem + 0.6229vw,
        4.768125rem
      ); // 66.76px - 76.29 */

      line-height: clamp(4rem, 3.8725rem + 0.5229vw, 4.5rem); //64 - 72

      font-size: clamp(3.815rem, 3.572rem + 0.9967vw, 4.768125rem);
    `}

  ${props =>
    props.datatype === 'heading_2' &&
    css`
      font-weight: 500;
      line-height: clamp(3.5rem, 3.3725rem + 0.5229vw, 4rem); //56 - 64
      /* font-size: clamp(
        3.338125rem,
        3.2166rem + 0.4987vw,
        3.815rem
      ); //53.41 - 61.04 */

      /* font-size: clamp(3.099375rem, 2.917rem + 0.7484vw, 3.815rem); */

      font-size: clamp(3.051875rem, 2.8574rem + 0.798vw, 3.815rem);
    `}

   

    ${props =>
    props.datatype === 'heading_3' &&
    css`
      font-weight: 500;
      line-height: clamp(3rem, 2.8725rem + 0.5229vw, 3.5rem); //48 - 56
      /* font-size: clamp(
        2.67rem,
        2.5727rem + 0.3993vw,
        3.051875rem
      ); //42.72 - 48.83 */

      font-size: clamp(2.44125rem, 2.2856rem + 0.6386vw, 3.051875rem);
    `}

    ${props =>
    props.datatype === 'heading_4' &&
    css`
      font-weight: 500;
      line-height: clamp(2rem, 1.8725rem + 0.5229vw, 2.5rem); //40 - 48
      /* font-size: clamp(
        2.13625rem,
        2.0585rem + 0.319vw,
        2.44125rem
      ); //34.18 - 39.06 */

      font-size: clamp(1.953125rem, 1.8287rem + 0.5105vw, 2.44125rem);
    `}

    ${props =>
    props.datatype === 'heading_5' &&
    css`
      font-weight: 500;
      line-height: clamp(2rem, 1.8725rem + 0.5229vw, 2.5rem); //32 - 40
      font-size: clamp(
        1.70875rem,
        1.6465rem + 0.2556vw,
        1.953125rem
      ); //27.34 - 31.25

      font-size: clamp(1.5625rem, 1.4629rem + 0.4085vw, 1.953125rem);
    `}

    ${props =>
    props.datatype === 'subtitle_1' &&
    css`
      font-weight: 500;
      line-height: clamp(2rem, 1.9363rem + 0.2614vw, 2.25rem); //32px - 36px
      /* font-size: clamp(
        1.3675rem,
        1.3178rem + 0.2039vw,
        1.5625rem
      ); //21.88 - 25.00 */

      font-size: clamp(1.25rem, 1.1703rem + 0.3268vw, 1.5625rem);
    `}

    ${props =>
    props.datatype === 'subtitle_2' &&
    css`
      font-weight: 500;
      /* font-size: clamp(1.09375rem, 1.0539rem + 0.1634vw, 1.25rem); // 17.50 - 20 */
      line-height: clamp(1.75rem, 1.6863rem + 0.2614vw, 2rem); //28px - 32px

      /* font-size: clamp(1.015625rem, 0.9559rem + 0.2451vw, 1.25rem); */

      font-size: clamp(1rem, 0.9363rem + 0.2614vw, 1.25rem);
      /* font-size: 16px;
      ${props => props.theme.breakpoints.up('sm')} {
        font-size: 20px;
      } */
    `}

    
    ${props =>
    props.datatype === 'body_1' &&
    css`
      font-weight: 500;
      line-height: clamp(1.5rem, 1.5rem + 0vw, 1.5rem);
      /* font-size: clamp(0.875rem, 0.8431rem + 0.1307vw, 1rem); //14 - 16 */
      /* font-size: 15px;
      ${props => props.theme.breakpoints.up('sm')} {
        font-size: 16px;
      } */

      font-size: clamp(0.9375rem, 0.9216rem + 0.0654vw, 1rem); //15 - 16
    `}

    ${props =>
    props.datatype === 'caption' &&
    css`
      font-weight: 500;
      line-height: clamp(1.5rem, 1.5rem + 0vw, 1.5rem); //24 - 24
      font-size: clamp(0.875rem, 0.875rem + 0vw, 0.875rem); //14 - 14
    `}

    ${props =>
    props.datatype === 'caption_1' &&
    css`
      font-weight: 500;
      line-height: clamp(1rem, 1rem + 0vw, 1rem); //16 - 16
      font-size: clamp(0.7rem, 0.6745rem + 0.1046vw, 0.8rem); //11.20px - 12.80
    `}

    ${props =>
    props.datatype === 'caption_2' &&
    css`
      font-weight: 500;
      line-height: clamp(1rem, 1rem + 0vw, 1rem); //16 - 16
      font-size: clamp(
        0.625rem,
        0.6212rem + 0.0157vw,
        0.64rem
      ); //8.96 - 10.24px
    `}


    
    

  ${props =>
    props.datatype === 'link' &&
    css`
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;

      // alternative to text-decoration: underline to have more space between text and underline
      // text-underline-offset css property would do that but it's not fully supported yet
      border-bottom-style: solid;
      border-bottom-width: 2px;
      width: fit-content;
    `}
`;

const Typography: React.FC<ITypo> = ({
  component = 'p',
  color,
  children,
  clampMiddleValue = '4vw',
  ...props
}) => {
  return (
    <CustomTypo as={component} {...props} clampMiddleValue={clampMiddleValue}>
      {children}
    </CustomTypo>
  );
};

Typography.defaultProps = {
  datatype: 'body_1',
};

export default Typography;
