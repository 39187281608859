type NoUndefinedValues<T extends Record<string, unknown>> = {
  [K in keyof T as T[K] extends undefined ? never : K]-?: Exclude<
    T[K],
    undefined
  >;
};

export function removeUndefinedValues<
  T extends Record<string, unknown | undefined>
>(obj: T): NoUndefinedValues<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => typeof value !== 'undefined')
  ) as NoUndefinedValues<T>;
}

export function stringifyValues<K extends string>(
  obj: Record<K, unknown>
): Record<K, string> {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, String(value)])
  ) as Record<K, string>;
}
