import React, { useEffect } from 'react';
import { ILineItemMutation } from '@/types/index';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadCart,
  createCart,
  cloneCart,
  setCartHandles,
} from '@/store/actions/cart';
import { RootState } from '@/store/store';

import { CheckoutFragment, CheckoutFragment_lineItems_edges } from '@/types/shopify/CheckoutFragment';
import { getLastCart } from '@/lib/api.checkout';
import { retrieveCurrency } from '../Currency';
import { getCurrencyInfoObj } from './Currency/Currency';
import { onlyUniqueFromArray } from '@/utils/index';

const Cart: React.FC = () => {
  const reduxCustomer = useSelector(
    (state: RootState) => state.userReducer.customer
  );
  const currencyFromCustomerCountry = retrieveCurrency(
    reduxCustomer?.defaultAddress?.countryCodeV2
  );
  const currencyInfo = currencyFromCustomerCountry
    ? getCurrencyInfoObj(currencyFromCustomerCountry)
    : null;
  const activeCart = reduxCustomer?.activeCart;

  const cart = useSelector((state: RootState) => state.cartReducer.cart);
  const isCartLoaded = useSelector(
    (state: RootState) => state.cartReducer.cartLoaded
  );

  const cartError = useSelector(
    (state: RootState) => state.cartReducer.errorSetCart
  );

  const dispatch = useDispatch();

  const getLineItems = (cart: CheckoutFragment | null) => {
    let lineItems: ILineItemMutation[] = [];
    if (cart && cart.lineItems) {
      cart.lineItems.edges.forEach(function (
        lineItem: CheckoutFragment_lineItems_edges
      ) {
        if (lineItem?.node?.variant) {
          lineItems.push({
            variantId: lineItem.node.variant.id,
            quantity: lineItem.node.quantity,
          });
        }
      });
    }
    return lineItems;
  }

  useEffect(() => {
    if (cartError === 'create cart failed' && currencyInfo) {

      dispatch(createCart(getLineItems(cart), '', currencyInfo, false));
    }

  }, [cartError]);

  useEffect(() => {
    const fetchCart = async () => {
      if (currencyInfo && typeof activeCart !== 'undefined') {
        const mirtaCheckout = await getLastCart();

        if (mirtaCheckout) {
          if (
            mirtaCheckout.currency === currencyInfo.isoCode &&
            !isCartLoaded
          ) {
            dispatch(loadCart(currencyInfo, mirtaCheckout.id));
          } else {
            if (
              mirtaCheckout.currency !== currencyInfo.isoCode &&
              cart &&
              isCartLoaded
            ) {
              dispatch(createCart(getLineItems(cart), cart.note, currencyInfo));
            }

            if (
              mirtaCheckout.currency !== currencyInfo.isoCode &&
              !isCartLoaded
            ) {
              dispatch(cloneCart(currencyInfo, mirtaCheckout.id));
            }
          }
        } else {
          dispatch(createCart([], '', currencyInfo));
        }
      }
    };
    fetchCart();
  }, [currencyInfo, activeCart]);

  useEffect(() => {
    if (cart && cart.lineItems) {
      let handles: string[] = [];
      cart.lineItems.edges.forEach(element => {
        if (element.node.variant) {
          handles.push(element.node.variant.product.handle);
        }
      });
      const uniqueHandles = handles.filter(onlyUniqueFromArray);
      dispatch(setCartHandles(uniqueHandles));
    }
  }, [cart]);

  return null;
};

export default Cart;
