import { actionTypes } from './actionTypes';
import { IOrderPopupTimer } from '@/types/index';
import { SnackBar } from '../reducers/ui';

export const openModal = (
  modalType: string,
  modalSource?: string,
  modalWho?: 'login' | 'register',
  modalCloseCallback?: () => void
) => {
  return {
    type: actionTypes.OPEN_MODAL,
    payload: { modalType, modalSource, modalWho, modalCloseCallback },
  };
};

export const closeModal = () => {
  return {
    type: actionTypes.CLOSE_MODAL,
    payload: { modalType: null },
  };
};

export const setOrderPopupTimer = (order: IOrderPopupTimer[]) => {
  return {
    type: actionTypes.SET_ORDER_POPUPTIMER,
    payload: order,
  };
};

export interface OpenSnackbarAction {
  type: actionTypes.OPEN_SNACKBAR;
  payload: {
    snackBarType: SnackBar['type'];
    snackBarPosition: SnackBar['position'];
    snackBarMessage: SnackBar['message'];
    snackBarCloseCallback?: SnackBar['closeCallback'];
  };
}

export const openSnackbar = (
  snackBarType: SnackBar['type'],
  snackBarPosition: SnackBar['position'],
  snackBarMessage: SnackBar['message'],
  snackBarCloseCallback?: SnackBar['closeCallback']
): OpenSnackbarAction => {
  return {
    type: actionTypes.OPEN_SNACKBAR,
    payload: {
      snackBarType,
      snackBarPosition,
      snackBarMessage,
      snackBarCloseCallback,
    },
  };
};

export interface CloseSnackbarAction {
  type: actionTypes.CLOSE_SNACKBAR;
  payload: {
    snackBarType: null;
  };
}

export const closeSnackbar = (): CloseSnackbarAction => {
  return {
    type: actionTypes.CLOSE_SNACKBAR,
    payload: { snackBarType: null },
  };
};

export const closePromoBar = () => {
  return {
    type: actionTypes.CLOSE_PROMO_BAR,
  };
};

export const stopSendImpression = () => {
  return {
    type: actionTypes.STOP_SEND_IMPRESSION,
  };
};
