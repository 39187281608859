import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { setProgressBar } from '@/store/actions/progressBar';
import { RootState } from '@/store/store';
const Nprogress = require('nprogress');

interface IProgressBar {
  waitData?: boolean;
}
const ProgressBar: React.FC<IProgressBar> = () => {
  Nprogress.configure({ easing: 'ease', speed: 700, trickleSpeed: 400 });
  const router = useRouter();
  const dispatch = useDispatch();
  const progressBarState = useSelector(
    (state: RootState) => state.progressBarReducer.isActive
  );
  const handleStart = () => {
    dispatch(setProgressBar(true));
  };
  const handleStop = () => {
    dispatch(setProgressBar(false));
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeError', handleStop);
    router.events.on('routeChangeComplete', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeError', handleStop);
      router.events.off('routeChangeComplete', handleStop);
    };
  }, [router]);

  useEffect(() => {
    if (progressBarState) {
      Nprogress.start();
    } else {
      Nprogress.done();
    }
    return () => {
      Nprogress.done();
    };
  }, [progressBarState]);

  return null;
};

export default ProgressBar;
