export enum actionTypes {
  SET_CURRENCY = 'SET_CURRENCY',
  SET_CURRENCIES_INFOS = 'SET_CURRENCIES_INFOS',

  TOGGLE_CART = 'TOGGLE_CART',
  LOADING_CART = 'LOADING_CART',
  SET_CART = 'SET_CART',
  SET_CART_ERROR = 'SET_CART_ERROR',
  INIT_CART = 'INIT_CART',
  ADD_ID_WITH_NO_MIN_QTY = 'ADD_ID_WITH_NO_MIN_QTY',
  DELETE_ID_WITH_NO_MIN_QTY = 'DELETE_ID_WITH_NO_MIN_QTY',
  SET_CART_HANDLES = 'SET_CART_HANDLES',

  LOGIN_START = 'LOGIN_START',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',

  LOGOUT_START = 'LOGOUT_START',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_ERROR = 'LOGOUT_ERROR',

  CUSTOMER_START = 'CUSTOMER_START',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
  CUSTOMER_ERROR = 'CUSTOMER_ERROR',
  CUSTOMER_EMPTY = 'CUSTOMER_EMPTY',
  LOADING_START = 'LOADING_START',
  LOADING_END = 'LOADING_END',
  CUSTOMER_UPDATE_ACTIVE_CART = 'CUSTOMER_UPDATE_ACTIVE_CART',
  SET_USER_PROFILING = 'SET_USER_PROFILING',
  CUSTOMER_UPDATE_ADDRESS = 'CUSTOMER_UPDATE_ADDRESS',
  CUSTOMER_UPDATE_REGISTRATION_PREFERENCES = 'CUSTOMER_UPDATE_REGISTRATION_PREFERENCES',
  CUSTOMER_UPDATE_SEGMENT_PRICE = 'CUSTOMER_UPDATE_SEGMENT_PRICE',

  FULL_CUSTOMER_START = 'FULL_CUSTOMER_START',
  FULL_CUSTOMER_SUCCESS = 'FULL_CUSTOMER_SUCCESS',
  FULL_CUSTOMER_ERROR = 'FULL_CUSTOMER_ERROR',

  SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS',
  SET_COLORS = 'SET_COLORS',

  SET_IMPRESSION = 'SET_IMPRESSION',
  SLICE_IMPRESSION = 'SLICE_IMPRESSION',

  CAROUSEL_MOVED = 'CAROUSEL_MOVED',

  PRODUCT_CLICK_EVENT = 'PRODUCT_CLICK_EVENT',

  SET_COUNTDOWN_BAR = 'SET_COUNTDOWN_BAR',
  SET_COUNTDOWN_BAR_DESTROY = 'SET_COUNTDOWN_BAR_DESTROY',

  SET_COLLECTION_INFO = 'SET_COLLECTION_INFO',
  SET_LAST_COLLECTION = 'SET_LAST_COLLECTION',
  SET_COLLECTION_PRODUCTS_INFO = 'SET_COLLECTION_PRODUCTS_INFO',
  SET_PROGRESS_BAR = 'SET_PROGRESS_BAR',
  SET_LOADING = 'SET_LOADING',

  SET_ITEM_TO_WISHLIST = 'SET_ITEM_TO_WISHLIST',
  REMOVE_ITEM_TO_WISHLIST = 'REMOVE_ITEM_TO_WISHLIST',
  SET_WISHLIST = 'SET_WISHLIST',

  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',

  OPEN_SNACKBAR = 'OPEN_SNACKBAR',
  CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',

  SET_ORDER_POPUPTIMER = 'SET_ORDER_POPUPTIMER',

  CLOSE_PROMO_BAR = 'CLOSE_PROMO_BAR',
  STOP_SEND_IMPRESSION = 'STOP_SEND_IMPRESSION',
}
