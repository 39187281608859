import { useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  sendImpressionsEvent,
  pageViewEvent,
  addToCartEvent,
  changeCartEvent,
  removeFromCartEvent,
  productDetailEvent,
  modalImpressionEvent,
  registerEvent,
  loginEvent,
  logoutEvent,
  removeFromWishlistEvent,
  addToWishlistEvent,
  addToWishlistNotLoggedEvent,
  productClickEventV2,
} from '@/lib/tracking';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { ICartItem, ICurrencyInfo } from '../types';
import { CheckoutFragment_lineItems_edges_node } from '@/types/shopify/CheckoutFragment';
import { Product } from '@/lib/models/types/product';

export type ProductCardProductV2 = {
  product: Product;
  page: string;
  positionindex?: number;
  type?: string;
};

export type AddToCartPayload = {
  lineItems: CheckoutFragment_lineItems_edges_node[];
  currency: ICurrencyInfo;
  qty: number;
  foundItem: boolean;
};

export type ProductCardPayloadV2 = {
  ref: HTMLElement;
  payload: ProductCardProductV2;
};

export type CustomEventDetailsMap = {
  productDetails: { product: Product; currency: ICurrencyInfo };
  productCardV2: ProductCardPayloadV2;
  productCardClickV2: {
    product: Product;
    path: string;
    type: string | undefined;
    currency: ICurrencyInfo | null;
  };
  addedToCart: AddToCartPayload;
  updatedCart: {
    lineItem: ICartItem;
    currency: ICurrencyInfo;
    qty: number;
  };
  removedFromCart: {
    lineItem: ICartItem;
    currency: ICurrencyInfo;
  };
  register: {
    formData: Record<string, any>;
    modalSource: string;
    customerId: string;
  };
  login: {
    email: string | null;
    isApproved: boolean;
    customerId?: string;
    hasOrders: string;
    segmentPrice: number | null;
    hasRegistrationPreferences: number;
  };
  logout: {
    email: string;
    customerId: string;
  };
  promoBarImpression: {
    event: 'view_promotion';
    promotion_name: string;
  };
  addedToWishlist: {
    productTitle: string;
  };
  removedFromWishlist: {
    productTitle: string;
  };
  addToWishlistNotLogged: {
    productTitle: string;
  };
};

export type CustomEventMap = {
  [K in keyof CustomEventDetailsMap]: CustomEvent<CustomEventDetailsMap[K]>;
};

interface ImpressionsProps {
  pageTitle?: string;
}

function Impressions({ pageTitle }: ImpressionsProps) {
  const router = useRouter();

  const modalType = useSelector(
    (state: RootState) => state.uiReducer.modalType
  );

  const modalSource = useSelector(
    (state: RootState) => state.uiReducer.modalSource
  );

  const modalOpen = useSelector(
    (state: RootState) => state.uiReducer.modalOpen
  );

  let observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: [0.0, 0.75],
  };

  let adObserver: IntersectionObserver;
  let currency: ICurrencyInfo | null = null;
  let impressions: ProductCardPayloadV2[] = [];
  let viewedImpressions: ProductCardPayloadV2[] = [];

  const sendImpressions = () => {
    if (viewedImpressions.length > 0) {
      window.Mirta.pushEventToAnalytics(
        'view_item_list',
        sendImpressionsEvent(viewedImpressions, currency)
      );
      viewedImpressions = [];
    }
  };

  useEffect(() => {
    adObserver = new IntersectionObserver(
      intersectionCallback,
      observerOptions
    );

    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false
    );

    document.addEventListener('beforeunload', handleBeforeUnload, false);

    document.addEventListener('productDetails', productDetailEventHandler);

    document.addEventListener('productCardV2', productCardHandler);

    document.addEventListener('productCardClickV2', productClickEventHandlerV2);

    document.addEventListener('addedToCart', addToCartEventHandler);

    document.addEventListener('updatedCart', changeCartEventHandler);

    document.addEventListener('removedFromCart', removeFromCartEventHandler);

    document.addEventListener('register', registerEventHandler);
    document.addEventListener('login', loginEventHandler);
    document.addEventListener('logout', logoutEventHandler);

    document.addEventListener('promoBarImpression', promoBarImpressionHandler);

    document.addEventListener('addedToWishlist', addToWishlistEventHandler);
    document.addEventListener(
      'removedFromWishlist',
      removeFromWishlistEventHandler
    );
    document.addEventListener(
      'addToWishlistNotLogged',
      addToWishlistNotLoggedEventHandler
    );

    router.events.on('routeChangeStart', sendImpressions);

    return () => {
      document.removeEventListener(
        'visibilitychange',
        handleVisibilityChange,
        false
      );

      document.removeEventListener('beforeunload', handleBeforeUnload, false);
      document.removeEventListener('productDetails', productDetailEventHandler);
      document.removeEventListener('productCardV2', productCardHandler);
      document.removeEventListener(
        'productCardClickV2',
        productClickEventHandlerV2
      );
      document.removeEventListener('addedToCart', addToCartEventHandler);
      document.removeEventListener('updatedCart', changeCartEventHandler);
      document.removeEventListener(
        'removedFromCart',
        removeFromCartEventHandler
      );

      document.removeEventListener(
        'addedToWishlist',
        addToWishlistEventHandler
      );
      document.removeEventListener(
        'removedFromWishlist',
        removeFromWishlistEventHandler
      );
      document.removeEventListener(
        'addToWishlistNotLogged',
        addToWishlistNotLoggedEventHandler
      );

      document.removeEventListener('register', registerEventHandler);
      document.removeEventListener('login', loginEventHandler);
      document.removeEventListener('logout', logoutEventHandler);

      document.removeEventListener(
        'promoBarImpression',
        promoBarImpressionHandler
      );

      router.events.off('routeChangeStart', sendImpressions);

      adObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    window.Mirta.pushEventToAnalytics(
      'Pageview',
      pageViewEvent(router.asPath, pageTitle ?? 'Unknown Page title')
    );
  }, [router.asPath]);

  useEffect(() => {
    if (modalOpen && modalType === 'register') {
      window.Mirta.pushEventToAnalytics(
        'registrationPopupImpression',
        modalImpressionEvent(modalSource)
      );
    }
  }, [modalOpen, modalType]);

  const handleVisibilityChange = () => {
    if (document.visibilityState !== 'visible') {
      sendImpressions();
    }
  };

  const productDetailEventHandler = (e: CustomEventMap['productDetails']) => {
    const payload = e.detail;
    window.Mirta.pushEventToAnalytics(
      'view_item',
      productDetailEvent(payload.product, payload.currency)
    );
  };

  const productCardHandler = (e: CustomEventMap['productCardV2']) => {
    impressions.push(e.detail);
    adObserver.observe(e.detail.ref);
  };

  const productClickEventHandlerV2 = (
    e: CustomEventMap['productCardClickV2']
  ) => {
    const payload = e.detail;
    window.Mirta.pushEventToAnalytics(
      'select_item',
      productClickEventV2(payload)
    );
  };

  const addToCartEventHandler = (e: CustomEventMap['addedToCart']) => {
    const payload = e.detail;
    if (payload.foundItem) {
      window.Mirta.pushEventToAnalytics(
        'change_cart_quantity',
        changeCartEvent(payload.lineItems[0], payload.qty, payload.currency)
      );
    } else {
      window.Mirta.pushEventToAnalytics(
        'add_to_cart',
        addToCartEvent(payload.lineItems, payload.qty, payload.currency)
      );
    }
  };

  const changeCartEventHandler = (e: CustomEventMap['updatedCart']) => {
    const payload = e.detail;
    window.Mirta.pushEventToAnalytics(
      'change_cart_quantity',
      changeCartEvent(payload.lineItem, payload.qty, payload.currency)
    );
  };

  const removeFromCartEventHandler = (e: CustomEventMap['removedFromCart']) => {
    const payload = e.detail;
    window.Mirta.pushEventToAnalytics(
      'remove_from_cart',
      removeFromCartEvent(
        payload.lineItem,
        payload.lineItem.quantity,
        payload.currency
      )
    );
  };

  const addToWishlistEventHandler = (e: CustomEventMap['addedToWishlist']) => {
    const payload = e.detail;
    window.Mirta.pushEventToAnalytics(
      'add_to_wishlist',
      addToWishlistEvent(payload.productTitle)
    );
  };
  const removeFromWishlistEventHandler = (
    e: CustomEventMap['removedFromWishlist']
  ) => {
    const payload = e.detail;
    window.Mirta.pushEventToAnalytics(
      'remove_from_wishlist',
      removeFromWishlistEvent(payload.productTitle)
    );
  };

  const addToWishlistNotLoggedEventHandler = (
    e: CustomEventMap['addToWishlistNotLogged']
  ) => {
    const payload = e.detail;
    window.Mirta.pushEventToAnalytics(
      'add_to_wishlist',
      addToWishlistNotLoggedEvent(payload.productTitle)
    );
  };

  const registerEventHandler = (e: CustomEventMap['register']) => {
    const payload = e.detail;
    const email = payload.formData.email;
    window.Mirta.pushEventToAnalytics(
      'sign_up',
      registerEvent(payload.formData, payload.modalSource, payload.customerId)
    );

    const { uuid: _uuid, $email: _email } = window.Mirta.getCurrentId();
    const dataLayerPayload = window.Mirta.addUserInfoToAnalyticsPayload(
      {
        visitorLoginState: 1,
        customer_approval: 1,
        is_customer: 1,
        email: email,
        hasOrders: 0,
        segmentPrice: null,
        hasRegistrationPreferences: 0,
      },
      _email,
      _uuid
    );
    window.gtag('set', dataLayerPayload);
  };

  const loginEventHandler = (e: CustomEventMap['login']) => {
    const payload = e.detail;
    const email = payload.email;
    window.Mirta.pushEventToAnalytics(
      'login',
      loginEvent(
        email,
        payload.isApproved,
        payload.customerId,
        payload.hasOrders,
        payload.segmentPrice,
        payload.hasRegistrationPreferences
      )
    );

    const hasMirtaOrders = window.Mirta.getCookie('hasMirtaOrders');
    const segmentPrice = window.Mirta.getCookie('segmentPrice');
    const hasRegistrationPreferences = window.Mirta.getCookie(
      'registrationPreferences'
    )
      ? 1
      : 0;
    const { uuid: _uuid, $email: _email } = window.Mirta.getCurrentId();
    const dataLayerPayload = window.Mirta.addUserInfoToAnalyticsPayload(
      {
        visitorLoginState: 1,
        customer_approval: 1,
        is_customer: 1,
        email: email,
        hasOrders: hasMirtaOrders === 'true' ? 1 : 0,
        segmentPrice: segmentPrice ? parseInt(segmentPrice) : null,
        hasRegistrationPreferences: hasRegistrationPreferences,
      },
      _email,
      _uuid
    );
    window.gtag('set', dataLayerPayload);
  };

  const logoutEventHandler = (e: CustomEventMap['logout']) => {
    const payload = e.detail;
    window.Mirta.pushEventToAnalytics(
      'logout',
      logoutEvent(payload.email, payload.customerId)
    );
  };

  const handleBeforeUnload = () => {
    sendImpressions();
  };

  const checkImpressionsToSend = () => {
    if (viewedImpressions.length >= 12) {
      sendImpressions();
    }
  };

  const promoBarImpressionHandler = (
    e: CustomEventMap['promoBarImpression']
  ) => {
    const payload = e.detail;
    window.Mirta.pushEventToAnalytics('promoBarImpression', payload);
  };

  const intersectionCallback: IntersectionObserverCallback = entries => {
    entries.forEach(function (entry) {
      let adBox = entry.target;
      if (entry.isIntersecting) {
        if (entry.intersectionRatio >= 0.75) {
          adObserver.unobserve(adBox);
          impressions.forEach(imp => {
            if (imp.ref == adBox) {
              viewedImpressions.push(imp);
              checkImpressionsToSend();
            }
          });
        }
      }
    });
  };

  return null;
}

export default Impressions;

export function createCustomEvent<K extends keyof CustomEventMap>(
  event: K,
  payload: CustomEventMap[K]['detail']
): CustomEvent {
  return new CustomEvent(event, {
    detail: payload,
  });
}

export function dispatchCustomEvent<K extends keyof CustomEventMap>(
  event: K,
  payload: CustomEventMap[K]['detail']
): void {
  document.dispatchEvent(createCustomEvent(event, payload));
}

declare global {
  interface Document {
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
      options?: boolean | AddEventListenerOptions
    ): void;

    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
      options?: boolean | EventListenerOptions
    ): void;
  }
}
