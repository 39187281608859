import { VariantOption } from '@/types/catalog';
import {
  ICollectionTagFields,
  IFooterFields,
} from '@/types/contentful/contentful';
import { IOrderPopupTimer } from '@/types/index';
import { IMeta } from '@/types/index';
import { IWishlistItem } from '@/types/wishlist';
import { DefaultTheme } from 'styled-components';

var slugify = require('slugify');
slugify.extend({ ':': '', "'": '-', '+': '-' });

export const slugifyString = (str: string) => {
  const slug = slugify(str, {
    lower: true, // converts to lower case
    remove: /[*+~.()'"!:@]/g,
  });
  const newSlug = slug.replace('--', '-');
  return newSlug;
};

export const redirect = (res: any, path: string) => {
  res.setHeader('location', path);
  res.statusCode = 302;
  res.end();
};

export const deleteCookie = (cname: string) => {
  setCookieByExpDate(cname, '', 'Thu, 01 Jan 1970 00:00:01 GMT');
};

export const setCookieByExpDate = (
  cname: string,
  cvalue: string,
  expDate: string
) => {
  var d = new Date(expDate);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const setCookie = (cname: string, cvalue: string, expDays: number) => {
  var d = new Date();
  d.setTime(d.getTime() + expDays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = (cname: string) => {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);

  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const truncateString = (source: string, size: number) => {
  return source.length > size ? source.slice(0, size - 1) + '…' : source;
};

export const throttle = (fn: () => unknown, wait: number) => {
  let time = Date.now();
  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
};

export const validateEmail = (email: string) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const validatePassword = (password: string) => {
  var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(password);
};

export const checkIfCrawler = (userAgent: string) => {
  const crawlerRegex = /bot|google|baidu|bing|msn|teoma|slurp|yandex/i;
  return crawlerRegex.test(userAgent);
};

export const splitTag = (tag: String) => {
  let [prefix, suffix] = tag.toLowerCase().split('_');
  prefix = prefix === 'bag category' ? 'bags' : prefix;
  let isCategoryAll = suffix ? suffix.startsWith('all ') : false;
  return { prefix, suffix, isCategoryAll }; // { prefix, suffix, isCategoryAll: suffix === `all ${prefix}` };
};

export const groupCollectionTags = (
  collectionFilters: ICollectionTagFields[]
) => {
  const categories: { [key: string]: ICollectionTagFields[] } = {};
  const filters: { [key: string]: ICollectionTagFields[] } = {};
  const groups: { [key: string]: ICollectionTagFields[] } = {};
  for (const filter of collectionFilters) {
    if (filter.tag) {
      let { prefix, isCategoryAll } = splitTag(filter.tag);

      prefix = slugifyString(prefix);

      if (!groups[prefix]) {
        groups[prefix] = [filter];
      } else {
        groups[prefix].push(filter);
      }

      if (isCategoryAll) {
        categories[prefix] = groups[prefix];
      }
    }
  }

  for (const key of Object.keys(groups)) {
    if (categories[key] === undefined) {
      filters[key] = groups[key];
    }
  }

  return { categories, filters };
};

export const sortObjectEntries = (
  obj_1: { [key: string]: any } | null
): { [key: string]: any } | null => {
  if (obj_1) {
    let sortedKeys = Object.keys(obj_1).sort(function order(key1, key2) {
      if (key1 < key2) return -1;
      else if (key1 > key2) return +1;
      else return 0;
    });

    var temp: { [key: string]: any } = {};

    for (let i = 0; i < sortedKeys.length; i++) {
      temp[sortedKeys[i]] = obj_1[sortedKeys[i]];
      delete obj_1[sortedKeys[i]];
    }

    for (var i = 0; i < sortedKeys.length; i++) {
      obj_1[sortedKeys[i]] = temp[sortedKeys[i]];
    }
  }

  return obj_1;
};

export const replaceWithRegex = (
  str: string,
  placeholders: { [key: string]: string }
) => {
  let replacedString = str;

  for (const p in placeholders) {
    replacedString = replacedString.replace('$' + p + '$', placeholders[p]);
  }
  return replacedString;
};

export const getPageName = (page: string) => {
  const localPage = page.split('?')[0];

  if (localPage === '/') {
    return 'home page';
  } else if (localPage.includes('products')) {
    return 'product page';
  } else if (localPage !== '/brands' && localPage.includes('brands')) {
    return 'brand page';
  }

  return localPage;
};

export const getObjectfromArray = (array: VariantOption[], key: string) => {
  let result = array.filter(obj => {
    return obj.name.toLowerCase() === key.toLowerCase();
  });
  return result;
};

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const setSession = (
  gaSessionIdCookie: string,
  gaSessionDuration: string,
  gaPageViews: string
) => {
  var sessionId: any = getCookie(gaSessionIdCookie);
  var sessionDuration: any =
    getCookie(gaSessionDuration) != '' ? getCookie(gaSessionDuration) : 0;
  var pageViews: any =
    getCookie(gaPageViews) != '' ? getCookie(gaPageViews) : 0;
  var timestamp = new Date().getTime();
  if (sessionId === '') {
    sessionId = timestamp;
    setCookie(gaSessionIdCookie, sessionId, 30);
  } else {
    sessionDuration = timestamp - sessionId;
  }
  setCookie(gaPageViews, (parseInt(pageViews, 10) + 1).toString(), 365);
  setCookie(gaSessionDuration, sessionDuration, 365);
};

//TODO: rewrite this with typescript in mind
export const debounce = (func: (value?: any) => unknown, wait: number) => {
  let timeout: unknown;
  return function (...args: any[]) {
    //@ts-ignore
    const context = this;
    if (timeout) {
      //@ts-ignores
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      //@ts-ignore
      func.apply(context, args);
    }, wait);
  };
};
export const fetcher = <T = any>(
  url: string
): Promise<{ url: string; result: T }> =>
  fetch(url)
    .then(response => response.json())
    .then(result => ({ url, result }));

export const fetcherAll = (...urls: string[]) => {
  return Promise.all(urls.map(fetcher));
};

export const drawerPreventScroll = (
  document: Document,
  window: Window,
  isOpen: boolean
) => {
  if (isOpen) {
    document.body.style.overflow = 'hidden';
    document.body.style.width = '100%';
    // document.body.style.height = '100vh';
    document.body.style.top = -window.pageYOffset + 'px';
    document.body.style.position = 'fixed';
  } else {
    const scrollTo = parseInt(document.body.style.top, 10);
    document.body.style.overflow = 'auto';
    document.body.style.width = '100%';
    // document.body.style.height = '100vh';
    document.body.style.top = 0 + 'px';
    document.body.style.position = 'relative';
    window.scroll(0, -scrollTo);
  }

  return true;
};

export const overrideMetadata = (metadata: IMeta, metaPlaceholders: {}) => {
  if (metadata.metaDescription) {
    const metaDescReplaced = replaceWithRegex(
      metadata.metaDescription,
      metaPlaceholders
    );
    metadata.metaDescription = metaDescReplaced;
  }
  if (metadata.title) {
    const metaTitleReplaced = replaceWithRegex(
      metadata.title,
      metaPlaceholders
    );
    metadata.title = metaTitleReplaced;
  }
};

export const supports_local_storage = () => {
  try {
    return 'localStorage' in window && window['localStorage'] !== null;
  } catch (e) {
    return false;
  }
};

export const transformWishlistPayload = (payload: IWishlistItem) => {
  const empi = payload.empi.replace('gid://shopify/Product/', '');

  const epi = payload.epi.replace('gid://shopify/ProductVariant/', '');
  const du = payload.du;
  return {
    empi,
    epi,
    du,
  };
};

export const transformId = (id: string, type: string) => {
  return `gid://shopify/${type}/${id}`;
};

export const compareStrings = (str1: string, str2: string) => {
  let lowerStr1 = str1.toLowerCase();
  let lowerStr2 = str2.toLowerCase();
  if (lowerStr1 < lowerStr2) {
    return -1;
  }
  if (lowerStr1 > lowerStr2) {
    return 1;
  }
  // a must be equal to b
  return 0;
};

export const getMissingFields = (
  requiredFields: string[],
  formData: { [k: string]: any },
  invalidFields: { [k: string]: string }
) => {
  let missingFields = { ...invalidFields };
  if (requiredFields) {
    if (formData) {
      requiredFields.forEach(reqfield => {
        if (
          !formData ||
          !formData[reqfield] ||
          formData[reqfield] === '' ||
          (Array.isArray(formData[reqfield]) && formData[reqfield].length === 0)
        ) {
          missingFields[reqfield] = 'missing';
        }
      });
    }
  }

  return missingFields;
};

export const getDataRegisterFromFooter = (footer: IFooterFields | null) => {
  let dataRegister;
  if (footer && footer.section && footer.section.length > 0) {
    dataRegister = footer.section.find(
      s => s.fields?.organism?.sys?.contentType?.sys?.id === 'form'
    );
  }

  return dataRegister?.fields?.organism.fields;
};

export const getDataPopupTimerFromFooter = (footer: IFooterFields | null) => {
  let dataPopupTimer;
  if (footer && footer.section && footer.section.length > 0) {
    dataPopupTimer = footer.section.find(s => {
      if (
        s.fields &&
        s.fields.organism &&
        s.fields.organism.sys.contentType.sys.id === 'popupTimer' &&
        s.fields.organism.fields.content === 'banner_countdown'
      ) {
        return s;
      }
    });
  }

  return dataPopupTimer?.fields?.organism.fields;
};

export const getOrderPopupTimerFromFooter = (footer: IFooterFields | null) => {
  let orderPopupTimer: IOrderPopupTimer[] = [];
  if (footer && footer.section && footer.section.length > 0) {
    footer.section.forEach(s => {
      if (
        s.fields &&
        s.fields.organism &&
        s.fields.organism.sys.contentType.sys.id === 'popupTimer'
      ) {
        orderPopupTimer.push({
          content: s.fields.organism.fields.content,
        });
      }
    });
  }

  return orderPopupTimer;
};

export const getPageParam = (p: string | string[] | undefined) => {
  let page = 1;

  if (p && typeof p === 'string' && !isNaN(+p)) {
    page = parseInt(p);
  }

  return page;
};

// TODO: this function should return a Map to avoid serialization of the keys.
export const groupItemBy = <T>(
  array: T[],
  property: string
): Record<string, T[]> => {
  var hash: Record<string, T[]> = {},
    props = property.split('.');
  for (var i = 0; i < array.length; i++) {
    var key = props.reduce(function (acc, prop) {
      return acc && (acc as any)[prop];
    }, array[i]) as unknown as string;
    if (!hash[key]) hash[key] = [];
    hash[key].push(array[i]);
  }
  return hash;
};

export const findItemNested = (
  arr: any[],
  itemId: string,
  nestingKey: string
): any | null => {
  return arr.reduce((a, item) => {
    if (a) return a;
    if (item?.id && itemId && item?.id.toLowerCase() === itemId.toLowerCase())
      return item;
    if (item[nestingKey])
      return findItemNested(item[nestingKey], itemId, nestingKey);
  }, null);
};

export const getKeyByValue = (
  object: { [k: string]: string },
  value: string
) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const getLocalStorage = (name: string) => {
  return supports_local_storage() ? window.localStorage.getItem(name) : null;
};

export const setLocalStorage = (name: string, payload: string) => {
  if (supports_local_storage()) {
    window.localStorage.setItem(name, payload);
  }
};

export const resetLocalStorage = (name: string) => {
  if (supports_local_storage()) {
    window.localStorage.removeItem(name);
  }
};

export const getParamsQueryString = (
  queryParams: Record<string, string>
): string => {
  const searchParams = new URLSearchParams(queryParams);

  return searchParams.toString();
};

export function createUrlWithParams(
  baseUrl: string,
  params: Record<string, string>
) {
  const searchParams = new URLSearchParams(params);
  const url = new URL(baseUrl);

  searchParams.forEach((value, key) => {
    url.searchParams.append(key, value);
  });

  return url.toString();
}

export const createLabelFromSlug = (slug: string) => {
  let label = '';
  if (slug && slug.length > 0) {
    const slugTokens = slug.split('-');

    slugTokens.forEach((token, index) => {
      slugTokens[index] = capitalize(token);
    });
    label = slugTokens.join(' ');
  }
  return label;
};

const getStandardLabel = (
  slug: string,
  collectionTags: ICollectionTagFields[] | null
): string | null => {
  let label = null;

  if (collectionTags && collectionTags.length > 0) {
    const entryFound = collectionTags.find(entry => {
      return entry.slug.toLowerCase() === slug.toLowerCase();
    });

    if (entryFound?.label) {
      label = entryFound?.label;
    }
  }

  return label;
};

const getPriceLabel = (
  key: string,
  collectionTags: ICollectionTagFields[] | null,
  currencySymbol: string | null
) => {
  const tokens = key ? key.split('-') : null;

  if (
    !collectionTags ||
    !tokens ||
    tokens?.length === 0 ||
    tokens?.length > 2
  ) {
    return null;
  }

  let genericPriceSlug;
  if (tokens.length === 1) {
    if (tokens[0] != '') {
      genericPriceSlug = `[X]`;
    } else {
      return null;
    }
  } else {
    if (tokens[0] === '0') {
      genericPriceSlug = `0-[Y]`;
    } else {
      genericPriceSlug = `[X]-[Y]`;
    }
  }
  let priceLabel = getStandardLabel(genericPriceSlug, collectionTags);

  if (priceLabel) {
    priceLabel = priceLabel
      .replace(/\[X\]/gi, tokens[0])
      .replace(/\[Y\]/gi, tokens[1])
      .replace(/\[CURRENCY\]/gi, currencySymbol || '');
  }

  return priceLabel;
};

export const getLabel = (
  key: string | null,
  collectionTagValues: ICollectionTagFields[] | null,
  fallbackLabel?: string,
  labelType?: string,
  currencySymbol?: string | null
): string => {
  if (!key) {
    return fallbackLabel ? capitalize(fallbackLabel) : '';
  }

  let label = null;
  const lowerPrefix = labelType ? labelType.toLowerCase() : 'standard';
  if (lowerPrefix === 'price' && currencySymbol) {
    label = getPriceLabel(key, collectionTagValues, currencySymbol);
  } else {
    label = getStandardLabel(key, collectionTagValues);
  }
  return capitalize(label || fallbackLabel || createLabelFromSlug(key));
};

export const getSpecificQueryParam = (queryContent: string, param: string) => {
  let sURLVariables = queryContent.split('&');
  let st: null | string = null;
  for (let i = 0; i < sURLVariables.length; i++) {
    let sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] == param) {
      st = sParameterName[1];
    }
  }
  return st;
};

export const stringToSentenceCase = (s: string): string => {
  const words = s.toLowerCase().split(' ');
  if (words.length <= 1) {
    return capitalizeFirstLetter(s);
  }
  for (let i = 0; i < words.length; i++) {
    words[i] = capitalizeFirstLetter(words[i]);
  }

  return words.join(' ');
};

export const capitalizeFirstLetter = (s: string): string => {
  if (s.length <= 0) {
    return s;
  }
  return s[0]?.toUpperCase() + s.slice(1);
}

export const mapImgWithTextColors = (
  colors: {
    borderColor?: string;
    textColor?: string;
    ctaColor?: string;
  },
  theme: DefaultTheme
) => {
  const { borderColor, textColor, ctaColor } = colors;
  const borderColorMapped =
    borderColor && theme?.colors[borderColor]?.value
      ? theme?.colors[borderColor]?.value
      : theme.paletteDefault.primary.main;

  const textColorMapped =
    textColor && theme?.colors?.[textColor]?.value
      ? theme.colors[textColor].value
      : theme.paletteDefault.primary.main;

  const ctaColorMapped =
    ctaColor && theme?.colors?.[ctaColor]?.value
      ? theme?.colors[ctaColor]?.value
      : textColorMapped || theme.paletteDefault.primary.main;

  const ctaContrastColor =
    ctaColor && theme?.colors?.[ctaColor]?.contrastValue
      ? theme.colors[ctaColor].contrastValue
      : theme.paletteDefault.primary.main;

  return {
    borderColorMapped,
    textColorMapped,
    ctaColorMapped,
    ctaContrastColor,
  };
};

export function pluralizeGender(gender: string): string {
  switch (gender) {
    case 'man':
      return 'men';
    case 'woman':
      return 'women';
  }

  return gender;
}

export type PriceSegmentTier = 'HIGH' | 'MID' | 'LOW' | 'ENTRY' | null;

export const mapCategoryPrices = (data: {
  gender: string;
  categories: {
    category?: string;
    category_path: string;
    price_range_segment?: PriceSegmentTier;
  }[];
}) => {
  if (data.categories && data.categories.length > 0) {
    const firstCategory = data.categories[0];
    if (!firstCategory.price_range_segment) {
      return -1;
    }
    switch (firstCategory.price_range_segment) {
      case 'HIGH':
        return 80;
      case 'MID':
        return 60;
      case 'LOW':
        return 40;
      case 'ENTRY':
        return 20;
      default:
        return -1;
    }
  }

  return -1;
};

export const aggregate = (arr: any[], on: string, who: string) => {
  const agg = arr.reduce((a, b) => {
    const onValue = b[on];
    const whoValue = b[who];

    if (a[onValue]) {
      a[onValue] = {
        [on]: onValue,
        [who]: [...a[onValue][who], whoValue],
      };
    } else {
      a[onValue] = {
        [on]: onValue,
        [who]: [whoValue],
      };
    }

    return a;
  }, {});

  return Object.values(agg);
};

export function onlyUniqueFromArray(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index;
}

export function isUsUserFn(customer: any) {
  return customer && customer.defaultAddress.countryCodeV2 === 'US';
}
