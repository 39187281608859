/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SortByValues {
    RELEVANCE = 'relevance',
    LOWER_PRICE = 'lower_price',
    HIGHER_PRICE = 'higher_price',
    NEW_IN = 'new_in',
}