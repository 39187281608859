import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setColors } from '@/store/actions/colors';
import { getColors } from '@/lib/api.cms';
import { IColorFields } from '@/types/contentful/contentful';

export interface IColorsObj {
  [key: string]: IColorFields;
}
function Colors({
  colors,
  locale,
}: {
  colors: IColorFields[];
  locale: string;
}) {
  const dispatch = useDispatch();
  const dispatchColors = (colors: IColorFields[]) => {
    let colorsObj: IColorsObj = {};
    colors.forEach(color => {
      if (color.label) {
        colorsObj[color.label.toLowerCase()] = color;
      }
    });
    dispatch(setColors(colorsObj));
  };
  useEffect(() => {
    (async () => {
      if (!colors || Object.keys(colors).length === 0) {
        colors = await getColors(locale);
        dispatchColors(colors);
      } else {
        dispatchColors(colors);
      }
    })();
  }, []);

  return null;
}

Colors.defaultProps = {
  colors: {},
};

export default Colors;
