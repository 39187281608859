import gql from 'graphql-tag';

const CheckoutReachFragment = gql`
  fragment CheckoutReachFragment on Checkout {
    buyerIdentity {
      countryCode
    }
    id
    completedAt
    updatedAt
    currencyCode
    webUrl
    note
    email
    totalTax {
      amount
      currencyCode
    }
    subtotalPrice {
      amount
      currencyCode
    }
    totalPrice {
      amount
      currencyCode
    }
    customAttributes {
      key
      value
    }
    lineItems(first: 250) {
      edges {
        node {
          id
          title
          unitPrice {
            amount
            currencyCode
          }
          variant {
            id
            title
            sku
            selectedOptions {
              name
              value
            }
            product {
              id
              title
              handle
              vendor
              productType
              metafield(key: "cart_xselling", namespace: "custom_fields") {
                value
                namespace
              }
            }
            image {
              src
            }

            price {
              amount
              currencyCode
            }
          }
          quantity
        }
      }
    }
    shippingAddress {
      id
      address1
      address2
      city
      company
      country
      countryCodeV2
      firstName
      lastName
      phone
      province
      provinceCode
      zip
    }
    shippingLine {
      handle
      price {
        amount
        currencyCode
      }
      title
    }
  }
`;

export default CheckoutReachFragment;
