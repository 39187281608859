import { ClientLogLevel, createClient, Entry } from 'contentful';
import {
  getProductsArtisanFromEntries,
  ICustomArtisanPageFields,
} from '@/utils/Product';
import {
  IColor,
  IColorFields,
  IMagazineFields,
  ICategoryFields,
  IPageFields,
  IProductFields,
} from '@/types/contentful/contentful';
import logger from '@/lib/logger';

const logHandler = (level: ClientLogLevel, data?: any) => {
  if (level === 'error') {
    logger.error(data);
  } else if (level === 'warning') {
    logger.warn({ data: data });
  } else {
    logger.info({ data: data });
  }
};

const client = createClient({
  space: process.env.CONTENTFUL_SPACE_ID!,
  accessToken: process.env.CONTENTFUL_ACCESS_TOKEN!,
  environment: process.env.CONTENTFUL_ENV,
  host: process.env.CONTENTFUL_CDN_ENDPOINT,
  retryLimit: 1,
  removeUnresolved: true,
  logHandler: logHandler,
});

const previewClient = createClient({
  space: process.env.CONTENTFUL_SPACE_ID!,
  accessToken: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN!,
  environment: process.env.CONTENTFUL_ENV,
  host: process.env.CONTENTFUL_PREVIEW_ENDPOINT,
  retryLimit: 1,
  logHandler: logHandler,
});

const contentfulClient = (preview: boolean) =>
  preview ? previewClient : client;

const cmsProductPagesSlugList = (
  process.env.CMS_PRODUCT_PAGES_SLUG_LIST || ''
).split(',');

export async function getEntry<T extends IPageFields>(
  contentType: string,
  fieldName: string,
  fieldValue: string,
  locale: string,
  preview: boolean
) {
  const client = contentfulClient(preview);

  var payload: any = {
    include: 7,
    content_type: contentType,
    locale: locale,
  };
  payload['fields.' + fieldName] = fieldValue;
  return client.getEntries<T>(payload).then(function (entries) {
    if (entries.items && entries.items.length > 0) {
      return entries.items[0];
    } else {
      return entries;
    }
  });
}

export async function getEntries<T extends IPageFields>(
  contentType: string,
  fieldName: string,
  fieldValues: string[],
  locale: string,
  preview: boolean
) {
  const client = contentfulClient(preview);

  var payload: { [k: string]: string | number } = {
    include: 7,
    content_type: contentType,
    locale: locale,
  };

  payload['fields.' + fieldName + '[in]'] = fieldValues.join();
  return client.getEntries<T>(payload).then(function (entries) {
    if (entries.items && entries.items.length > 0) {
      if (entries.items.length > 1) {
        let foundEntry: Entry<T> | undefined;
        fieldValues.forEach(value => {
          if (foundEntry) {
            return;
          }
          foundEntry = entries.items.find(entry => {
            return entry.fields.slug === value;
          });
        });
        if (foundEntry) {
          return foundEntry;
        }
      }
      return entries.items[0];
    } else {
      return entries;
    }
  });
}

export async function getPageSlugById<T extends IPageFields>(
  id: string,
  preview: boolean
) {
  const client = contentfulClient(preview);

  return client
    .getEntry<T>(id)
    .then(entry => entry)
    .catch(console.error);
}

export async function getArtisanList(
  locale: string,
  preview: boolean,
  page: number
  //limit: number,
) {
  const client = contentfulClient(preview);
  const limit = 18; //TODO: da sostituire utilizzando un setting preso da contentful
  const skip = (page - 1) * limit;
  const withProducts = true;
  var payload: any = {
    include: 1,
    content_type: 'artisanPage',
    locale: locale,
    select: [
      'fields.artisanName',
      'fields.slug',
      'fields.featuredImage',
      'fields.region',
      'fields.description',
    ],
    limit: limit,
    skip: skip,
    order: 'fields.artisanName',
  };

  return client
    .getEntries<ICustomArtisanPageFields>(payload)
    .then(async function (entries) {
      if (withProducts) {
        return getProductsArtisanFromEntries(entries, 2);
      } else {
        return entries;
      }
    });
}

export async function fetchMagazineList(pageNumber: number, locale: string) {
  return fetch('/api/v1/magazines?p=' + pageNumber + '&locale=' + locale).then(
    response => response.json()
  );
}

export async function getMagazineList(
  locale: string,
  preview: boolean,
  page: number
) {
  //https://cdn.contentful.com/spaces/560btuj1niry/environments/dev/entries?access_token=tQMG2WG-upgED_awET1KeB-6gUV5JDJJ2BnW9CXE9w4&content_type=artisanPage&include=1&select=fields.title,fields.previewImage,fields.slug

  const client = contentfulClient(preview);
  const limit = 12;
  const skip = (page - 1) * limit;

  var payload: any = {
    include: 1,
    content_type: 'magazine',
    locale: locale,
    select: [
      // 'fields.title',
      'fields.heading',
      'fields.shortDescription',
      'fields.slug',
      'fields.featuredImage',
      'fields.publishDate',
      'fields.readingTime',
    ],
    limit: limit,
    skip: skip,
  };
  // payload['fields.' + fieldName] = fieldValue;

  return client.getEntries<IMagazineFields>(payload).then(function (entries) {
    return entries;
  });
}

export async function getPage(
  locale: string,
  content_type: string,
  select: string[],
  slugs: string[],
  preview: boolean
) {
  const client = contentfulClient(preview);
  const limit = 20;
  var payload: any = {
    content_type: content_type,
    select: select, //['fields.artisanName', 'fields.slug', 'fields.featuredImage'],
    include: 1,
    locale: locale,
    limit: limit,
  };
  if (slugs && slugs.length > 0) {
    payload['fields.slug' + '[in]'] = slugs.join();
  }

  return client
    .getEntries(payload)
    .then(function (entries: { items?: any[] }) {
      return entries.items || [];
    })
    .catch(function () {
      return [];
    });
}

export async function getCollectionColors(
  locale: string,
  preview: boolean
): Promise<IColor[] | Entry<unknown>[]> {
  const client = contentfulClient(preview);
  const limit = 500;
  var payload: {
    content_type: string;
    include: number;
    locale: string;
    limit: number;
  } = {
    content_type: 'color',
    include: 1,
    locale: locale,
    limit: limit,
  };

  return client.getEntries(payload).then(function (entries) {
    return entries.items;
  });
}

export async function getCollectionTags(locale: string, preview: boolean) {
  const client = contentfulClient(preview);
  const limit = 500;
  var payload: any = {
    content_type: 'collectionTag',
    include: 1,
    locale: locale,
    limit: limit,
  };

  return client.getEntries(payload).then(function (entries: { items?: any[] }) {
    let collectionFilters = [];
    if (entries && entries.items) {
      collectionFilters = entries.items.map(item => {
        return item.fields;
      });
    }

    return collectionFilters;
  });
}

export async function getArtisanBySlug(
  locale: string,
  preview: boolean,
  slugs: string,
  products: string | string[],
  region: string | string[]
) {
  const client = contentfulClient(preview);
  const limit = 200;
  //const skip = (page - 1) * limit;
  const select =
    region === 'true'
      ? [
          'fields.artisanName',
          'fields.slug',
          'fields.featuredImage',
          'fields.region',
          'fields.description',
        ]
      : ['fields.artisanName', 'fields.slug', 'fields.featuredImage'];

  var payload: any = {
    include: 1,
    content_type: 'artisanPage',
    locale: locale,
    select: select,
    limit: limit,
    skip: 0,
  };
  // payload['fields.' + fieldName] = fieldValue;

  payload['fields.slug[in]'] = slugs;

  return client
    .getEntries(payload)
    .then(async function (entries: { items?: any[] }) {
      let resultItems: any = { items: [] };
      let sorting = slugs.split(',');
      sorting.forEach(function (key) {
        var found = false;
        if (entries.items && entries.items.length > 0) {
          entries.items = entries.items.filter(function (item) {
            if (!found && item.fields.slug == key) {
              resultItems.items.push(item);
              found = true;
              return false;
            } else return true;
          });
        }
      });

      if (products === 'true') {
        const res = await getProductsArtisanFromEntries(resultItems, 2);

        return res;
      } else {
        return resultItems;
      }
    });
}

export async function getProductInfo(
  contentType: string,
  fieldName: string,
  fieldValue: string,
  locale: string,
  preview: boolean
) {
  const productDefaultInfo = (await getEntry(
    'productPage',
    'title',
    'Default',
    locale,
    preview
  )) as Entry<IProductFields>;

  if (cmsProductPagesSlugList.includes(fieldValue)) {
    const productInfo = (await getEntry(
      contentType,
      fieldName,
      fieldValue,
      locale,
      preview
    )) as Entry<IProductFields>;

    if (productInfo && productInfo.fields) {
      productDefaultInfo.fields.title = productInfo.fields.title;
      productDefaultInfo.fields.meta = productInfo.fields.meta;
      productDefaultInfo.fields.extraInfo = productInfo.fields.extraInfo;
      if (productInfo.fields.content) {
        productDefaultInfo.fields.content = productInfo.fields.content;
      }
    }
  }

  return productDefaultInfo;
}

export async function getColors(locale: string): Promise<IColorFields[]> {
  return fetch('/api/v1/colors?locale=' + locale).then(response =>
    response.json()
  );
}

export async function getCollectionPagePatterns(
  locale: string,
  preview: boolean
) {
  return getPage(
    locale,
    'collectionPage',
    [
      'fields.slug',
      'fields.title',
      'fields.descriptionRich',
      'fields.bottomDescriptionRich',
      'fields.collectionImage',
      'fields.collectionImageMobile',
    ],
    [
      'all',
      // '[handle]',
      // '[handle]/[category]',
      // '[handle]/[category]/[subcategory]',
      '[gender]',
      '[gender]/[category]',
      '[gender]/[category]/[subcategory]',
      '[gender]/[category]/[subcategory]/[subcategory2]',
    ],
    preview
  );
}

export async function searchMagazineArticles(
  locale: string,
  preview: boolean,
  limit: number,
  inputValue: string
) {
  const client = contentfulClient(preview);
  var payload: any = {
    include: 1,
    content_type: 'magazine',
    locale: locale,
    select: ['fields.title', 'fields.slug'],
    limit: limit,
    skip: 0,
  };

  payload['fields.article[match]'] = inputValue;

  return client.getEntries(payload).then(function (entries: { items?: any[] }) {
    return entries;
  });
}

export async function getCategories(
  contentType: string,
  fieldName: string,
  fieldValues: string[],
  locale: string,
  preview: boolean
) {
  const client = contentfulClient(preview);

  let payload: any = {
    include: 7,
    content_type: contentType,
    locale: locale,
  };
  payload['fields.' + fieldName + '[in]'] = fieldValues.join();

  return client.getEntries<ICategoryFields>(payload).then(function (entries) {
    return entries.items;
  });
}

export async function fetchCategories(): Promise<
  Entry<ICategoryFields>[] | void
> {
  return fetch('/api/v1/categories').then(response => {
    const resp = response.json();
    return resp;
  });
}
