import { actionTypes } from './actionTypes';
import {
  loadCartApi,
  createCartApi,
  cloneCartApi,
  resetCartApi,
  addToCartApi,
  removeFromCartApi,
  updateCartApi,
  updateCartNoteApi,
  addMultipleVariantsToCartApi,
} from '@/lib/api.checkout';
import {
  CheckoutFragment,
  CheckoutFragment_lineItems_edges,
  CheckoutFragment_lineItems_edges_node,
} from '@/types/shopify/CheckoutFragment';

import { ICartItem, ICurrencyInfo, ILineItemMutation } from '@/types/index';

import { AppGetState, AppDispatch } from '../types';
import { updateActiveCart } from './user';
import { dispatchCustomEvent } from '@/utils/Impressions';
import { openSnackbar } from './ui';

export interface ToggleCartAction {
  type: actionTypes.TOGGLE_CART;
  payload: boolean;
}

export const toggleCart = (isOpen: boolean): ToggleCartAction => {
  return {
    type: actionTypes.TOGGLE_CART,
    payload: isOpen,
  };
};

export interface LoadingCartAction {
  type: actionTypes.LOADING_CART;
}

const loadingCart = (): LoadingCartAction => {
  return {
    type: actionTypes.LOADING_CART,
  };
};

export interface SetCartAction {
  type: actionTypes.SET_CART;
  payload: CheckoutFragment;
}

export const setCart = (cart: CheckoutFragment): SetCartAction => {
  return {
    type: actionTypes.SET_CART,
    payload: cart,
  };
};

export interface SetCartErrorAction {
  type: actionTypes.SET_CART_ERROR;
  payload: string; // da vedere
}

export const setCartError = (error: string): SetCartErrorAction => {
  return {
    type: actionTypes.SET_CART_ERROR,
    payload: error,
  };
};

export const updateCart = (
  lineItem: ICartItem,
  qty: number,
  currency: ICurrencyInfo,
  customer: any
) => {
  return (dispatch: AppDispatch) => {
    dispatch(loadingCart());

    updateCartApi(lineItem, qty, currency, customer)
      .then(res => {
        if (res.status && res.status.toLocaleLowerCase() === 'success') {
          if (res && res.checkout) {
            dispatch(setCart(res.checkout));
          }

          if (qty === 0) {
            dispatchCustomEvent('removedFromCart', {
              lineItem: lineItem,
              currency,
            });
          } else {
            dispatchCustomEvent('updatedCart', {
              lineItem: lineItem,
              qty: qty,
              currency,
            });
          }
        } else {
          dispatch(setCartError('update item qty failed'));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const removeFromCart = (
  lineItem: ICartItem,
  currency: ICurrencyInfo,
  customer: any,
) => {
  return (dispatch: AppDispatch) => {
    dispatch(loadingCart());

    removeFromCartApi([lineItem], customer)
      .then(res => {
        if (res.status && res.status.toLocaleLowerCase() === 'success') {
          if (res && res.checkout) {
            dispatch(setCart(res.checkout));
          }

          dispatchCustomEvent('removedFromCart', {
            lineItem: lineItem,
            currency,
          });
        } else {
          dispatch(setCartError('remove item from cart failed'));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const addToCart = (
  lineItem: ILineItemMutation,
  currency: ICurrencyInfo,
  foundItem: CheckoutFragment_lineItems_edges | null
) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(loadingCart());
    const accessToken = getState().userReducer.accessToken;
    const email = getState().userReducer.customer.email;
    const customer = getState().userReducer.customer;
    console.log(customer);
    addToCartApi(lineItem, currency, email, accessToken, customer)
      .then(res => {
        if (res.status.toLocaleLowerCase() === 'success') {
          if (res && res.checkout) {
            dispatch(setCart(res.checkout));
            dispatch(toggleCart(true));
            let lineItems: CheckoutFragment_lineItems_edges_node[] = [];
            res.checkout.lineItems.edges.forEach(
              (item: CheckoutFragment_lineItems_edges) => {
                if (
                  item &&
                  item.node &&
                  item.node.variant &&
                  item.node.variant.id === lineItem.variantId
                ) {
                  lineItems.push(item.node);
                }
              }
            );
            dispatchCustomEvent('addedToCart', {
              lineItems: lineItems,
              currency,
              qty: lineItem.quantity,
              foundItem: foundItem ? true : false,
            });
          }
        } else {
          dispatch(setCartError('add to cart failed'));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const loadCart = (currency: ICurrencyInfo, cartId: string) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(loadingCart());
    const accessToken = getState().userReducer.accessToken;
    const customer = getState().userReducer.customer;
    loadCartApi(currency, customer, cartId)
      .then(res => {
        if (res.status && res.status.toLocaleLowerCase() === 'success') {
          if (res.checkout) {
            if (res.checkout.completedAt) {
              resetCartApi(accessToken);
              dispatch(updateActiveCart(null));
            } else {
              dispatch(setCart(res.checkout));
            }
          }
        } else {
          resetCartApi(accessToken);
          dispatch(updateActiveCart(null));
          dispatch(setCartError('init cart failed'));
        }
      })
      .catch(error => {
        console.log(error);
        resetCartApi(accessToken);
        dispatch(updateActiveCart(null));
        dispatch(setCartError('init cart failed'));
      });
  };
};

export const createCart = (
  lineItems: ILineItemMutation[],
  note: string | null,
  currency: ICurrencyInfo,
  includeAddress: boolean = true,
) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(loadingCart());
    const accessToken = getState().userReducer.accessToken;
    const email = getState().userReducer.customer.email;
    const customer = getState().userReducer.customer;
    createCartApi(lineItems, note, currency, email, accessToken, customer, includeAddress)
      .then(res => {
        if (res.status && res.status.toLocaleLowerCase() === 'success') {
          if (res.checkout) {
            dispatch(setCart(res.checkout));
            dispatch(
              updateActiveCart({
                id: res.checkout.id,
                currency: currency.isoCode,
              })
            );
          }
        } else {
          dispatch(setCartError('create cart failed'));
          console.error(res.error);

        }
      })
      .catch((error: string) => {
        dispatch(setCartError('create cart failed'));
        console.log(error);
      });
  };
};

export const cloneCart = (currency: ICurrencyInfo, cartId: string) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(loadingCart());
    const accessToken = getState().userReducer.accessToken;
    const customer = getState().userReducer.customer;
    cloneCartApi(currency, cartId, accessToken, customer)
      .then(res => {
        if (res.status && res.status.toLocaleLowerCase() === 'success') {
          if (res.checkout) {
            dispatch(setCart(res.checkout));
          }
        } else {
          dispatch(updateActiveCart(null));
          dispatch(setCartError('clone cart failed'));
        }
      })
      .catch(() => {
        dispatch(updateActiveCart(null));
        dispatch(setCartError('clone cart failed'));
      });
  };
};

export const updateCartNote = (note: string, customer: any) => {
  return (dispatch: AppDispatch) => {
    dispatch(loadingCart());

    updateCartNoteApi(note, customer)
      .then(res => {
        if (res.status && res.status.toLocaleLowerCase() === 'success') {
          if (res && res.checkout) {
            dispatch(setCart(res.checkout));
          }
        } else {
          dispatch(setCartError('update note failed'));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export interface AddIdWithNoMinQtyAction {
  type: actionTypes.ADD_ID_WITH_NO_MIN_QTY;
  payload: string;
}

export const addIdWithNoMinQty = (id: string): AddIdWithNoMinQtyAction => {
  return {
    type: actionTypes.ADD_ID_WITH_NO_MIN_QTY,
    payload: id,
  };
};

export interface DeleteIdWithNoMinQty {
  type: actionTypes.DELETE_ID_WITH_NO_MIN_QTY;
  payload: string;
}

export const deleteIdWithNoMinQty = (id: string): DeleteIdWithNoMinQty => {
  return {
    type: actionTypes.DELETE_ID_WITH_NO_MIN_QTY,
    payload: id,
  };
};

export interface SetCartHandlesAction {
  type: actionTypes.SET_CART_HANDLES;
  payload: string[];
}

export const setCartHandles = (handles: string[]): SetCartHandlesAction => {
  return {
    type: actionTypes.SET_CART_HANDLES,
    payload: handles,
  };
};

export const multipleAddToCart = (
  lineItems: ILineItemMutation[],
  currency: ICurrencyInfo,
  customer: any
) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(loadingCart());
    const accessToken = getState().userReducer.accessToken;
    const email = getState().userReducer.customer.email;
    const errorMsg = 'Add to cart failed';
    addMultipleVariantsToCartApi(lineItems, currency, email, accessToken, customer)
      .then(res => {
        if (res.status.toLocaleLowerCase() === 'success') {
          if (res && res.checkout) {
            dispatch(setCart(res.checkout));
            dispatch(toggleCart(true));

            const itemsForNotification = res.checkout.lineItems.edges
              .map(item => item.node)
              .filter(
                item =>
                  item.variant?.id &&
                  lineItems
                    .map(lineItem => lineItem.variantId)
                    .includes(item.variant?.id)
              );
            dispatchCustomEvent('addedToCart', {
              lineItems: itemsForNotification,
              currency,
              qty: lineItems.reduce((acc, curr) => acc + curr.quantity, 0),
              foundItem: false,
            });
          }
        } else {
          dispatch(setCartError(errorMsg));
          dispatch(
            openSnackbar(
              'error',
              { vertical: 'bottom', horizontal: 'center' },
              `${errorMsg} : ${res.error}`
            )
          );
        }
      })
      .catch(_error => {
        dispatch(setCartError(errorMsg));
        dispatch(
          openSnackbar(
            'error',
            { vertical: 'bottom', horizontal: 'center' },
            errorMsg
          )
        );
      });
  };
};

export const multipleRemoveFromCart = (
  lineItems: ICartItem[],
  currency: ICurrencyInfo,
  customer: any
) => {
  return (dispatch: AppDispatch) => {
    dispatch(loadingCart());

    removeFromCartApi(lineItems, customer)
      .then(res => {
        if (res.status && res.status.toLocaleLowerCase() === 'success') {
          if (res && res.checkout) {
            dispatch(setCart(res.checkout));
          }

          dispatchCustomEvent('removedFromCart', {
            lineItem: lineItems[0],
            currency,
          });
        } else {
          dispatch(setCartError('remove item from cart failed'));
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
};
