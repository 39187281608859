import gql from 'graphql-tag';

export const productByHandleQuery = gql`
  query productByHandle($handle: String!) {
    productByHandle(handle: $handle) {
      id
      handle
      title
      productType
      descriptionHtml
      vendor
      options {
        id
        name
        values
      }
      variants(first: 75) {
        edges {
          node {
            id
            title
            sku
            quantityAvailable
            selectedOptions {
              name
              value
            }
            image {
              src
              width
              height
            }
            price {
              amount
              currencyCode
            }
          }
        }
      }
      images(first: 75) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            src
            width
            height
          }
        }
      }
    }
  }
`;
