import { actionTypes } from './actionTypes';
import {
  customerLoginApi,
  customerLogoutApi,
  getCustomerByToken,
} from '@/lib/api.customer';
import { setCookieByExpDate, setCookie, deleteCookie } from '@/utils/index';

import { B2B_APPROVED_COOKIE, B2B_NOT_APPROVED_COOKIE } from '@/lib/constants';
import { dispatchCustomEvent } from '@/utils/Impressions';
import { UserProfiling } from '@/types/user';
import {
  customerDetailsQuery_customer_defaultAddress,
  customerDetailsQuery_customer_registrationPreferences,
  customerDetailsQuery_customer_segmentPrice,
} from '@/types/shopify/customerDetailsQuery';
import { isErr } from '@/utils/typings/result';

const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START,
  };
};

const loginSuccess = (accessObj: {}) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: accessObj,
  };
};

const loginError = (error: { code: string; message: string }) => {
  return {
    type: actionTypes.LOGIN_ERROR,
    payload: error,
  };
};

export const updateActiveCart = (
  cart: {
    id: string;
    currency: string;
  } | null
) => {
  return {
    type: actionTypes.CUSTOMER_UPDATE_ACTIVE_CART,
    payload: cart,
  };
};

export const login = (email: string, password: string) => {
  return (dispatch: any) => {
    dispatch(loginStart());
    customerLoginApi(email, password).then(res => {
      if (isErr(res) && res.error.original && 'code' in res.error.original) {
        dispatch(loginError(res.error.original));
      } else if (isErr(res)) {
        dispatch(
          loginError({
            code: '0',
            message: 'Unknown Error',
          })
        );
      } else {
        dispatch(
          setCustomer(res.result.accessToken, res.result.expiresAt, true, true)
        );
        dispatch(loginSuccess(res));
      }
    });
  };
};

export const accountLoading = (loading: boolean) => {
  return {
    type: loading ? actionTypes.LOADING_START : actionTypes.LOADING_END,
  };
};

export const setCustomerStart = () => {
  return {
    type: actionTypes.CUSTOMER_START,
  };
};

export const setCustomerError = (error: string) => {
  return {
    type: actionTypes.CUSTOMER_ERROR,
    payload: error,
  };
};

export const setCustomerEmpty = () => {
  return {
    type: actionTypes.CUSTOMER_EMPTY,
  };
};

export const setCustomer = (
  token: string,
  expiresAt: string | null,
  details: boolean,
  isLogin: boolean
) => {
  return async (dispatch: any) => {
    dispatch(setCustomerStart());
    if (isLogin && expiresAt) {
      setCookieByExpDate('mirtaAccessToken', token, expiresAt);
    }

    const shopifyCustomer = await getCustomerByToken(token, details);

    if (shopifyCustomer && shopifyCustomer.customer) {
      let customer = { ...shopifyCustomer.customer };
      let isApproved = false;
      let hasOrders = 'false';
      let segmentPrice = null;
      let hasRegistrationPreferences = 0;
      if (
        shopifyCustomer.customer.tags &&
        shopifyCustomer.customer.tags.length > 0
      ) {
        isApproved =
          shopifyCustomer.customer.tags.includes('wholesale_approved');
        delete customer.tags;
      }

      if (customer.activeCart) {
        customer.activeCart = JSON.parse(customer.activeCart.value);
      }

      if (customer?.orders?.edges) {
        hasOrders = customer.orders.edges.length > 0 ? 'true' : 'false';
        setCookie('hasMirtaOrders', hasOrders, 7);
      }

      if (customer.segmentPrice?.value) {
        segmentPrice = parseInt(customer.segmentPrice.value);
        setCookie('segmentPrice', customer.segmentPrice.value, 7);
      }
      if (customer.registrationPreferences) {
        hasRegistrationPreferences = 1;
        setCookie(
          'registrationPreferences',
          JSON.stringify(customer.registrationPreferences),
          30
        );
      }

      dispatch(
        setCustomerData({
          customer,
          accessToken: token,
          isApproved: isApproved,
        })
      );

      if (isLogin) {
        dispatchCustomEvent('login', {
          email: customer.email,
          isApproved,
          customerId: customer?.id,
          hasOrders,
          segmentPrice,
          hasRegistrationPreferences,
        });
      }

      let customer_approval = isApproved
        ? B2B_APPROVED_COOKIE
        : B2B_NOT_APPROVED_COOKIE;
      customer_approval = window.btoa(customer_approval);
      setCookie('cu-app', customer_approval, 7);
    } else {
      deleteCookie('mirtaAccessToken');
      deleteCookie('cu-app');
      deleteCookie('hasMirtaOrders');
      dispatch(setCustomerError('Error retrieving customer'));
    }
  };
};

const setCustomerData = (customer: {}) => {
  return {
    type: actionTypes.CUSTOMER_SUCCESS,
    payload: customer,
  };
};

const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
  };
};

const logoutError = (error: { code: string; message: string }) => {
  return {
    type: actionTypes.LOGOUT_ERROR,
    payload: error,
  };
};

export const logout = (
  accessToken: string,
  email: string,
  customerId: string
) => {
  return (dispatch: any) => {
    deleteCookie('mirtaAccessToken');

    customerLogoutApi(accessToken)
      .then(res => {
        if (
          res?.customerAccessTokenDelete?.userErrors &&
          res.customerAccessTokenDelete.userErrors.length === 0
        ) {
          dispatch(logoutSuccess());
          dispatchCustomEvent('logout', {
            email,
            customerId,
          });
        } else {
          dispatch(logoutError(res.error));
        }
      })
      .catch(err => {
        dispatch(logoutError(err));
      });
  };
};

export const setDefaultAddress = (address: {}) => {
  return {
    type: actionTypes.SET_DEFAULT_ADDRESS,
    payload: address,
  };
};

export const activateCustomer = (tokenObj: {
  accessToken: string;
  expiresAt: string;
}) => {
  return (dispatch: any) => {
    dispatch(setCustomer(tokenObj.accessToken, tokenObj.expiresAt, true, true));
  };
};

type SetProfilingAction = {
  type: actionTypes.SET_USER_PROFILING;
  payload: UserProfiling;
};

export const setUserProfile = (
  profiling: UserProfiling
): SetProfilingAction => {
  return {
    type: actionTypes.SET_USER_PROFILING,
    payload: profiling,
  };
};

export const updateCustomerAddress = (
  defaultAddress: Partial<customerDetailsQuery_customer_defaultAddress>
) => {
  return {
    type: actionTypes.CUSTOMER_UPDATE_ADDRESS,
    payload: defaultAddress,
  };
};

export const updateCustomerRegistrationPreferences = (
  registrationPreferences: Partial<customerDetailsQuery_customer_registrationPreferences>
) => {
  return {
    type: actionTypes.CUSTOMER_UPDATE_REGISTRATION_PREFERENCES,
    payload: registrationPreferences,
  };
};

export const updateCustomerSegmentPrice = (
  segmentPrice: Partial<customerDetailsQuery_customer_segmentPrice>
) => {
  return {
    type: actionTypes.CUSTOMER_UPDATE_SEGMENT_PRICE,
    payload: segmentPrice,
  };
};
