const pino = require('pino');

const env = process.env.ENVIRONMENT;
const logLevel = process.env.LOG_LEVEL || 'error';

const opts = {
    level: logLevel,
    messageKey: 'message',
    timestamp: pino.stdTimeFunctions.isoTime,
    formatters: {
        level(label: string) {
            return { level: label };
        },
        log(object: any) {
            return {
                service: "storefront",
                env: env,
                ...object,
            };
        }
    },
};

const builder = (opts:any) => {
    const logPath = process.env.LOG_PATH;
    if (logPath) {
        return pino(opts, pino.destination({ dest: logPath, sync: false }));
    }
    return pino(opts);
}

const logger = builder(opts);

export default logger;