import { actionTypes } from '../actions/actionTypes';
import {
  SetCountDownBarAction,
  SetCountDownBarDestroyAction,
} from '../actions/countdownbar';

type Action = SetCountDownBarAction | SetCountDownBarDestroyAction;

export interface CountdownBarState {
  show: boolean;
  code: string | null;
  bannerCountdown: any;
}

const initialState: CountdownBarState = {
  show: false,
  code: null,
  bannerCountdown: null,
};

const countdownbarReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_COUNTDOWN_BAR:
      return {
        ...state,
        show: true,
        code: action.payload.code,
        bannerCountdown: action.payload.bannerCountdown,
      };
    case actionTypes.SET_COUNTDOWN_BAR_DESTROY:
      return {
        ...state,
        show: false,
        code: null,
        bannerCountdown: null,
      };
    default:
      return state;
  }
};

export default countdownbarReducer;
