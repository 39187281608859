export interface GetAuthorizationTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

/**
 * retrieves aws cognito authorization token
 * use before calling authenticated apis
 */
export async function getAuthorizationToken(): Promise<GetAuthorizationTokenResponse> {
  // TODO caching
  const fetchAuthorization = await fetch(
    `${process.env.NEXT_PUBLIC_AUTHORIZATION_API}/oauth2/token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${process.env.NEXT_PUBLIC_CHECKOUT_TOKEN}`,
      },
      body: new URLSearchParams({
        grant_type: 'client_credentials',
        scope:
          'io.mirta/checkout:read io.mirta/checkout:write io.mirta/artisan-order:read',
      }),
    }
  );
  const authorizationToken: GetAuthorizationTokenResponse =
    await fetchAuthorization.json();
  return authorizationToken;
}
