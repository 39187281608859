import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { openModal } from '@/store/actions/ui';
import styled from 'styled-components';
import { ILinkHandlerCmp } from './ILinkHandlerCmp';
import { Dialog } from '@/components/atoms';
import ImageWithTextBlock from '@/components/organisms/ImageWithTextBlock/ImageWithTextBlock';

const ClickContainer = styled.span`
  cursor: pointer;
  display: block;
`;

const LinkHandler: React.FC<ILinkHandlerCmp> = ({
  href,
  passHref,
  children,
  source,
  shallow,
  type,
  popupContent,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [showDialog, setShowDialog] = useState(false);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const { locale } = router;

  const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (href === '/account/login') {
      dispatch(openModal('logReg', '', 'login'));
    }
    if (href === '/account/register') {
      dispatch(openModal('logReg', source, 'register'));
    }
  };
  let linkCmp;
  let dialog;

  if (type === 'popup') {
    linkCmp = <ClickContainer onClick={openDialog}>{children}</ClickContainer>;
    dialog = (
      <Dialog open={showDialog} onClose={closeDialog}>
        <>
          {popupContent ? (
            <ImageWithTextBlock {...popupContent} index={1} />
          ) : null}
        </>
      </Dialog>
    );
  } else {
    if (href) {
      if (href === '/account/login' || href === '/account/register') {
        linkCmp = (
          <ClickContainer onClick={clickHandler}>{children}</ClickContainer>
        );
      } else {
        linkCmp = (
          <Link
            href={href}
            locale={locale}
            passHref={passHref}
            shallow={shallow}>
            {children}
          </Link>
        );
      }
    }
  }

  return (
    <>
      {linkCmp}
      {dialog}
    </>
  );
};

export default LinkHandler;
