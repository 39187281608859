import React, { useState, useCallback } from 'react';
import { ImageMultiply, ImageOnHover } from '@/components/atoms';

interface IImageOnHoverWrapper {
  imgdefault: string;
  imghover: string | undefined;
  title: string;
}

const ImageOnHoverWrapper: React.FC<IImageOnHoverWrapper> = props => {
  const { imgdefault, imghover, title } = props;
  const [changeImg, setChangeImg] = useState(false);

  const changeImgHandler = useCallback(() => {
    setChangeImg(!changeImg);
  }, [changeImg]);

  return (
    <div onMouseEnter={changeImgHandler} onMouseLeave={changeImgHandler}>
      <ImageMultiply isCover>
        <ImageOnHover
          imgdefault={imgdefault}
          imghover={imghover || imgdefault}
          change={changeImg}
          title={title}
        />
      </ImageMultiply>
    </div>
  );
};

export default ImageOnHoverWrapper;
