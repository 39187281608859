import { Typography } from '@/components/atoms';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from '@/store/actions/ui';
import { TFunction, useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';

export interface INotificationMap {
  [notification: string]: {
    content: JSX.Element;
    type: 'success' | 'error' | 'warning' | 'info' | null;
    position: {
      vertical: 'bottom' | 'top';
      horizontal: 'center' | 'left' | 'right';
    };
  };
}

export enum NotificationEnum {
  ADDED_TO_WISHLIST = 'addedToWishlist',
  /** add other here */
}

export const NotificationMap = (t: TFunction): INotificationMap => {
  return {
    [NotificationEnum.ADDED_TO_WISHLIST]: {
      content: (
        <Typography component="span" datatype="body_1">
          {t('product:cta.added-wishlist')}
        </Typography>
      ),
      type: 'success',
      position: { vertical: 'bottom', horizontal: 'center' },
    },
    /** handle other here */
  };
};

export const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    Object.keys(NotificationMap(t)).forEach(item => {
      const data = NotificationMap(t)[item];
      const eventHandler = () => {
        dispatch(openSnackbar(data.type, data.position, data.content));
      };
      document.addEventListener(item, eventHandler);
      return () => {
        document.removeEventListener(item, eventHandler);
        dispatch(closeSnackbar());
      };
    });
  }, []);
  return <></>;
};
