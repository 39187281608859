import { Document } from '@contentful/rich-text-types';
import { Asset } from 'contentful';
import { actionTypes } from '../actions/actionTypes';
import {
  SetCollectionInfoAction,
  SetCollectionProductsInfoAction,
  SetLastCollectionAction,
} from '../actions/collectionInfo';

export type CollectionInfoState = {
  collectionInfo: {
    title?: string;
    descriptionRich?: Document;
    bottomDescriptionRich?: Document;
    collectionImages?: {
      mobile?: Asset;
      desktop: Asset;
    };
  };
  lastCollection?: { url: string; scrollY: number } | null;
  productsInfo?: {
    numProducts: number;
  };
  brandsInfo?: {
    numBrands?: number;
  };
};
type Action =
  | SetCollectionInfoAction
  | SetLastCollectionAction
  | SetCollectionProductsInfoAction;

const initialState: CollectionInfoState = {
  collectionInfo: {},
  lastCollection: null,
};

const collectionInfoReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_COLLECTION_INFO:
      return {
        ...state,
        collectionInfo: action.payload,
      };
    case actionTypes.SET_LAST_COLLECTION:
      return {
        ...state,
        lastCollection: action.payload,
      };
    case actionTypes.SET_COLLECTION_PRODUCTS_INFO:
      return {
        ...state,
        productsInfo: action.payload,
      };

    default:
      return state;
  }
};

export default collectionInfoReducer;
