import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';

const Profiling: FC = () => {
  const router = useRouter();

  useEffect(() => {
    const { utm_medium } = router.query;
    const mediumStorage = window.sessionStorage.getItem('utm_medium');

    if (!mediumStorage || mediumStorage === 'none') {
      window.sessionStorage.setItem(
        'utm_medium',
        (utm_medium || 'none') as string
      );
    }
  }, [router.query]);

  return null;
};

export default Profiling;
