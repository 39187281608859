import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setItemToWishlist,
  removeItemToWishlist,
} from '@/store/actions/wishlist';
import { RootState } from '@/store/store';
import { transformWishlistPayload } from '@/utils/index';
import CloseIcon from '../../../public/asset/closeIcon.svg';
import styled from 'styled-components';
import { IWishlistItem } from '@/types/wishlist';
import { IHeartCmp } from './IHeartCmp';
import { openModal } from '@/store/actions/ui';
import { dispatchCustomEvent } from '@/utils/Impressions';
import { IconHeart } from '..';

const Close = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${props => props.theme.paletteDefault.white};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
`;
const CircleWrapper = styled.div`
  background-color: ${props => props.theme.paletteDefault.white};
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeartComponent: React.FC<IHeartCmp> = props => {
  const dispatch = useDispatch();
  const wishlist = useSelector(
    (state: RootState) => state.wishlistReducer.wishlist
  );
  const isLogged = useSelector(
    (state: RootState) => state.userReducer.isLogged
  );

  const [payload, setPayload] = useState<IWishlistItem>();
  const [isFill, setIsFill] = useState(false);

  useEffect(() => {
    setPayload(transformWishlistPayload(props));
  }, [props]);

  const clickHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    if (isLogged) {
      if (payload) {
        dispatch(setItemToWishlist(payload, props.productName));
      }
    } else {
      dispatch(openModal('logReg', '', 'register'));
      dispatchCustomEvent('addToWishlistNotLogged', {
        productTitle: props.productName,
      });
    }
  };

  const removeItem = (e: SyntheticEvent) => {
    e.preventDefault();
    if (payload && isLogged) {
      dispatch(removeItemToWishlist(payload, props.productName));
    }
  };

  useEffect(() => {
    if (wishlist && isLogged) {
      if (wishlist.length > 0 && payload) {
        const result = wishlist.filter(
          w => w.empi.toString() === payload.empi.toString()
        );
        setIsFill(result.length > 0);
      } else {
        setIsFill(false);
      }
    }
  }, [wishlist, payload]);

  return (
    <div>
      {props.type === 'wishlist' ? (
        <Close onClick={removeItem}>
          <CloseIcon />
        </Close>
      ) : (
        <CircleWrapper>
          <IconHeart
            isFilled={isFill}
            onClick={clickHandler}
            width={16}
            height={16}
            style={{ cursor: 'pointer' }}
          />
        </CircleWrapper>
      )}
    </div>
  );
};

export default HeartComponent;
