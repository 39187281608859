import { actionTypes } from './actionTypes';
import { IWishlistItem } from '@/types/wishlist';
import { dispatchCustomEvent } from '@/utils/Impressions';

export interface AddWishlistItemAction {
  type: actionTypes.SET_ITEM_TO_WISHLIST;
  payload: IWishlistItem;
}

export const setItemToWishlist = (
  payload: IWishlistItem,
  productTitle: string
): AddWishlistItemAction => {
  dispatchCustomEvent('addedToWishlist', {
    productTitle,
  });

  return {
    type: actionTypes.SET_ITEM_TO_WISHLIST,
    payload,
  };
};

export interface RemoveWishlistItemAction {
  type: actionTypes.REMOVE_ITEM_TO_WISHLIST;
  payload: IWishlistItem;
}

export const removeItemToWishlist = (
  payload: IWishlistItem,
  productTitle: string
): RemoveWishlistItemAction => {
  dispatchCustomEvent('removedFromWishlist', {
    productTitle,
  });

  return {
    type: actionTypes.REMOVE_ITEM_TO_WISHLIST,
    payload,
  };
};

export interface SetWishlistAction {
  type: actionTypes.SET_WISHLIST;
  payload: IWishlistItem[];
}

export const setWishlist = (payload: IWishlistItem[]) => {
  return {
    type: actionTypes.SET_WISHLIST,
    payload,
  };
};
