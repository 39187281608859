import React from 'react';
import { useTheme } from 'styled-components';

const IconClose: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m13.91,12.38c-.07-.07-.1-.16-.1-.26s.04-.19.1-.26l6.12-6.26s.04-.06.04-.09-.01-.07-.04-.09l-1.68-1.71s-.13-.05-.18,0l-6.09,6.26c-.07.07-.16.11-.26.11-.1,0-.19-.04-.26-.11L5.57,3.84s-.06-.04-.09-.04h0s-.07.01-.09.04l-1.6,1.66c-.05.05-.05.13,0,.18l5.99,6.11c.07.07.11.16.11.26s-.04.19-.1.26l-6.05,6.2s-.04.06-.04.09.01.07.04.09l1.69,1.7s.13.05.18,0l6.03-6.2c.07-.07.16-.11.25-.11h0c.1,0,.19.04.26.11l6.15,6.29s.06.04.09.04h0s.07-.01.09-.04l1.59-1.65c.05-.05.05-.13,0-.18l-6.14-6.28Z"
        fill={color1}
      />
      <polygon
        points="20.27 18.01 20.27 18.01 20.27 18.01 20.27 18.01"
        stroke={'none'}
      />
    </svg>
  );
};

export default IconClose;
