import useIsMobile from '@/hooks/useIsMobile';
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Image from 'next/image';
import styled from 'styled-components';
import { IconClose } from '@/components/atoms';

interface IDialog extends DialogProps {
  children: React.ReactElement;
  onClose: () => void;
}

const IconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  z-index: 999999999;
`;

const CustomMuiDialog = styled(MuiDialog)`
  .MuiDialogContent-root {
    padding: 0px;
  }
  .MuiDialog-paperWidthSm {
    min-width: 300px;
    max-width: none;
  }

  ${props => props.theme.breakpoints.up('mobileL')} {
    .MuiDialogContent-root {
      padding: 8px 24px;

      padding: 0; //controllare che tipo di modale
    }
  }
`;

const Dialog: React.FC<IDialog> = ({ children, ...props }) => {
  const isMobile = useIsMobile();

  return (
    <CustomMuiDialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={props.open}
      onClose={props.onClose}
      fullScreen={isMobile ? true : false}
      fullWidth={props.fullWidth}>
      <IconWrapper onClick={props.onClose}>
        <IconClose width={24} height={24} />
      </IconWrapper>

      <DialogContent>{children}</DialogContent>
    </CustomMuiDialog>
  );
};

export default Dialog;
