import { mapResult, Result } from '@/utils/typings/result';

type ExtractType<Fn extends (...args: any[]) => Promise<Result<any, any>>> =
  Fn extends (...args: any[]) => Promise<Result<infer R, any>> ? R : never;
type ExtractError<Fn extends (...args: any[]) => Promise<Result<any, any>>> =
  Fn extends (...args: any[]) => Promise<Result<any, infer E>> ? E : never;

export function adaptRequest<
  Fn extends (...args: any[]) => Promise<Result<any, any>>,
  R
>(
  fn: Fn,
  transformer: (from: ExtractType<Fn>) => R
): (...args: Parameters<Fn>) => Promise<Result<R, ExtractError<Fn>>> {
  return (...args) =>
    fn(...args).then(result => mapResult(result, transformer));
}
