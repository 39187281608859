import { err, ok, Result, sequence } from './result';
import { DecodingError, FetchError } from './errors';

type FetchParams = Parameters<typeof fetch>;

export async function safeFetch<T = any>(
  input: FetchParams[0],
  init?: FetchParams[1]
): Promise<Result<T, FetchError>>;
export async function safeFetch<T>(
  input: FetchParams[0],
  init: FetchParams[1] & {
    decoder: (value: unknown) => value is T;
  }
): Promise<Result<T, FetchError | DecodingError>>;
export async function safeFetch<T>(
  input: FetchParams[0],
  init: FetchParams[1] & {
    decoder?: (value: unknown) => value is T;
  } = {}
): Promise<Result<any, FetchError | DecodingError>> {
  const { decoder, ...initParam } = init;

  try {
    const response = await fetch(input, initParam);
    if (!response.ok) {
      return err(
        new FetchError(
          `api call returned an error:  ${response.status} ${response.statusText}`
        )
      );
    }

    const body = await response.json();
    if (typeof decoder !== 'function') {
      return ok(body);
    }

    if (decoder(body)) {
      return ok(body);
    }

    return err(new DecodingError());
  } catch (e) {
    return err(new FetchError('unknown error', e));
  }
}

export async function safeFetchAll<T>(
  urls: string[]
): Promise<Result<T[], FetchError>>;
export async function safeFetchAll<T>(
  urls: string[],
  decoder: (value: unknown) => value is T
): Promise<Result<T[], FetchError | DecodingError>>;
export async function safeFetchAll<T>(
  urls: string[],
  decoder?: (value: unknown) => value is T
): Promise<Result<T[], FetchError | DecodingError>> {
  const results = urls.map(url =>
    decoder ? safeFetch(url, { decoder }) : safeFetch(url)
  );

  return sequence(await Promise.all(results));
}
