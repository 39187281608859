import { useMemo } from 'react';
import {
  createStore,
  applyMiddleware,
  combineReducers,
  Store as ReduxStore,
  Middleware,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import currencyReducer from './reducers/currency';
import cartReducer from './reducers/cart';
import userReducer from './reducers/user';
import colorsReducer from './reducers/colors';
import impressionsReducer from './reducers/impressions';
import countdownbarReducer from './reducers/countdownbar';
import collectionInfoReducer, {
  CollectionInfoState,
} from './reducers/collectionInfo';
import progressBarReducer from './reducers/progressBar';
import loadingOverlayReducer from './reducers/loadingOverlay';
import wishlistReducer from './reducers/wishlist';
import uiReducer from './reducers/ui';
import { CartState } from './reducers/cart';
import { CurrencyState } from './reducers/currency';
import { CountdownBarState } from './reducers/countdownbar';
import { WishlistState } from './reducers/wishlist';

export let store: ReduxStore<Store> | undefined;

interface Store {
  currencyReducer: CurrencyState;
  cartReducer: CartState;
  userReducer: any;
  colorsReducer: any;
  impressionsReducer: any;
  countdownbarReducer: CountdownBarState;
  collectionInfoReducer: CollectionInfoState;
  progressBarReducer: any;
  loadingOverlayReducer: any;
  wishlistReducer: WishlistState;
  uiReducer: any;
}

export const rootReducer = combineReducers<Store>({
  currencyReducer,
  cartReducer,
  userReducer,
  colorsReducer,
  impressionsReducer,
  countdownbarReducer,
  collectionInfoReducer,
  progressBarReducer,
  loadingOverlayReducer,
  wishlistReducer,
  uiReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

const bindMiddleware = (middlewares: Middleware[]) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middlewares));
  }
  return applyMiddleware(...middlewares);
};

const initialState = {};

export function initStore(preloadedState = initialState) {
  return createStore(
    rootReducer,
    preloadedState,
    //composeWithDevTools(applyMiddleware())
    bindMiddleware([thunkMiddleware])
  );
}

export const initializeStore = (preloadedState: Store) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store

  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState: Store) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
