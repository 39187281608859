import { gql } from '@apollo/client';

export const multipleProductVariantQuantity = gql`
  query multipleProductVariantQuantity($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on ProductVariant {
        id
        quantityAvailable
        currentlyNotInStock
      }
    }
  }
`;
