import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrency } from '@/store/actions/currency';
import CURRENCIES_INFO from '@/utils/currenciesInfos.json';
import { ICurrencyCmp } from './ICurrencyCmp';
import { RootState } from '@/store/store';
import { CurrenciesData, retrieveCurrency } from '@/utils/Currency';
import { CurrencyCode } from '@/types/shopify/globalTypes';

export const getCurrencyInfoObj = (currencyCode: CurrencyCode) => {
  const currenciesInfos: CurrenciesData = CURRENCIES_INFO;
  const currencyInfo = {
    isoCode: currencyCode,
    ...currenciesInfos[currencyCode],
  };
  return currencyInfo;
};

const Currency: React.FC<ICurrencyCmp> = ({ currencyCode }) => {
  const dispatch = useDispatch();

  const userReducer = useSelector((state: RootState) => state.userReducer);

  const loadingCustomer = userReducer.loadingCustomer;
  const userCountryCode =
    userReducer.customer?.defaultAddress?.countryCodeV2 || null;

  const [currentCurrencyCode, setCurrentCurrencyCode] = useState(currencyCode);

  useEffect(() => {
    if (!loadingCustomer) {
      const currencyFromUserCountry = retrieveCurrency(userCountryCode);
      if (currencyFromUserCountry !== currentCurrencyCode) {
        setCurrentCurrencyCode(currencyFromUserCountry);
      }
    }
  }, [userCountryCode, loadingCustomer]);

  useEffect(() => {
    if (currentCurrencyCode) {
      const currencyInfo = getCurrencyInfoObj(currentCurrencyCode);
      dispatch(setCurrency(currencyInfo));
    }
  }, [currentCurrencyCode]);

  return null;
};

export default Currency;
