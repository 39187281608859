import React from 'react';
import MuiCard, { CardProps } from '@material-ui/core/Card';

interface ICard extends CardProps {
  children: React.ReactNode;
}

const Card: React.FC<ICard> = ({ children, ...props }) => {
  return <MuiCard {...props}>{children}</MuiCard>;
};

export default Card;
