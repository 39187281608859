import { actionTypes } from '../actions/actionTypes';
import { SetCurrencyAction } from '../actions/currency';
import { ICurrencyInfo } from '@/types/index';

type Action = SetCurrencyAction;

export interface CurrencyState {
  currency: ICurrencyInfo | null;
}
const initialState: CurrencyState = {
  currency: null,
};

const currencyReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };

    default:
      return state;
  }
};

export default currencyReducer;
