import gql from 'graphql-tag';

export const customerQuery = gql`
  query customerQuery($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      displayName
      email
      acceptsMarketing
      firstName
      lastName
      tags
      defaultAddress {
        id
        address1
        address2
        city
        company
        country
        countryCodeV2
        firstName
        lastName
        phone
        province
        provinceCode
        zip
      }
      activeCart: metafield(namespace: "custom_fields", key: "active_cart") {
        value
      }
      activePromo: metafield(namespace: "custom_fields", key: "active_promo") {
        value
      }
      segmentPrice: metafield(
        namespace: "custom_fields"
        key: "segment_price"
      ) {
        value
      }
      registrationPreferences: metafield(
        namespace: "custom_fields"
        key: "registration_preferences"
      ) {
        value
        id
      }
    }
  }
`;

export const customerDetailsQuery = gql`
  query customerDetailsQuery($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      displayName
      firstName
      lastName
      id
      acceptsMarketing
      email
      defaultAddress {
        id
        address1
        address2
        city
        company
        country
        countryCodeV2
        firstName
        lastName
        phone
        province
        provinceCode
        zip
      }
      tags
      activePromo: metafield(namespace: "custom_fields", key: "active_promo") {
        value
      }
      orders(first: 3, sortKey: PROCESSED_AT, reverse: true) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            visibleToCustomer: metafield(
              namespace: "custom_fields"
              key: "visible_to_customer"
            ) {
              value
            }
            id
            cancelReason
            currencyCode
            currentTotalPrice {
              amount
              currencyCode
            }
            currentTotalTax {
              amount
              currencyCode
            }
            customerUrl
            email
            financialStatus
            fulfillmentStatus
            name
            orderNumber
            phone
            processedAt
            shippingAddress {
              id
              address1
              address2
              city
              company
              country
              countryCodeV2
              firstName
              lastName
              phone
              province
              provinceCode
              zip
            }
            statusUrl
            totalPrice {
              amount
              currencyCode
            }
            totalRefunded {
              amount
              currencyCode
            }
            totalShippingPrice {
              amount
              currencyCode
            }
            totalTax {
              amount
              currencyCode
            }

            lineItems(first: 10) {
              edges {
                node {
                  quantity
                  title
                  variant {
                    price {
                      amount
                      currencyCode
                    }
                    title
                    image {
                      src
                    }
                    selectedOptions {
                      name
                      value
                    }
                  }
                  originalTotalPrice {
                    amount
                    currencyCode
                  }
                  discountedTotalPrice {
                    amount
                    currencyCode
                  }
                  discountAllocations {
                    allocatedAmount {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
          }
        }
      }
      activeCart: metafield(namespace: "custom_fields", key: "active_cart") {
        value
      }
      segmentPrice: metafield(
        namespace: "custom_fields"
        key: "segment_price"
      ) {
        value
      }
      registrationPreferences: metafield(
        namespace: "custom_fields"
        key: "registration_preferences"
      ) {
        value
        id
      }
    }
  }
`;

export const customerOrdersQuery = gql`
  query customerOrdersQuery($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      displayName
      firstName
      id
      acceptsMarketing
      email
      orders(first: 100, sortKey: PROCESSED_AT, reverse: true) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            visibleToCustomer: metafield(
              namespace: "custom_fields"
              key: "visible_to_customer"
            ) {
              value
            }
            id
            cancelReason
            currencyCode
            currentTotalPrice {
              amount
              currencyCode
            }
            currentTotalTax {
              amount
              currencyCode
            }
            customerUrl
            email
            financialStatus
            fulfillmentStatus
            name
            orderNumber
            phone
            processedAt
            shippingAddress {
              id
              address1
              address2
              city
              company
              country
              countryCodeV2
              firstName
              lastName
              phone
              province
              provinceCode
              zip
            }
            statusUrl
            totalPrice {
              amount
              currencyCode
            }
            totalRefunded {
              amount
              currencyCode
            }
            totalShippingPrice {
              amount
              currencyCode
            }
            totalTax {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export const customerAddressesQuery = gql`
  query customerAddressesQuery($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      displayName
      firstName
      id
      acceptsMarketing
      email
      defaultAddress {
        id
        address1
        address2
        city
        company
        country
        countryCodeV2
        firstName
        lastName
        phone
        province
        provinceCode
        zip
      }
      addresses(first: 100) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            address1
            address2
            city
            company
            country
            countryCodeV2
            firstName
            lastName
            phone
            province
            provinceCode
            zip
          }
        }
      }
    }
  }
`;
