import { adaptRequest } from './utils';
import {
  getProductCatalogByHandle as unsafeGetProductCatalogByHandle,
  getProductsArtisan as unsafeGetProductsArtisan,
  getProductsByHandles as unsafeGetProductsByHandle,
  getProductsCollection as unsafeGetProductsCollection,
} from '../api.product';
import {
  getProductVariantsBySku as unsafeGetProductVariantsBySku,
  searchProducts as unsafeSearchProducts,
} from '../api.catalog';
import {
  mapProductWithVariants,
  mapSearchResult,
  mapProductWithDescription,
} from './mappers/product';

export const getProductByHandle = adaptRequest(
  unsafeGetProductCatalogByHandle,
  response => mapProductWithVariants(response.data)
);

export const getProductsByHandles = adaptRequest(
  unsafeGetProductsByHandle,
  products => products.map(mapProductWithVariants)
);

export const searchProducts = adaptRequest(
  unsafeSearchProducts,
  mapSearchResult
);

export const getProductBySku = adaptRequest(
  unsafeGetProductVariantsBySku,
  response => mapProductWithDescription(response.data)
);

export const getProductsByBrand = adaptRequest(
  unsafeGetProductsArtisan,
  mapSearchResult
);

export const getProductsByCollection = adaptRequest(
  unsafeGetProductsCollection,
  mapSearchResult
);
