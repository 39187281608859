import { useState, useEffect } from 'react';
import theme from '@/theme/theme';
import { throttle } from '@/utils/index';

const getDeviceConfig = (width: number) => {
  if (width < theme.breakpoints.values.sm && width > 1) {
    return 'xs';
  } else if (
    width >= theme.breakpoints.values.sm &&
    width < theme.breakpoints.values.tablet
  ) {
    return 'sm';
  } else if (
    width >= theme.breakpoints.values.tablet &&
    width < theme.breakpoints.values.md
  ) {
    return 'tablet';
  } else if (
    width >= theme.breakpoints.values.md &&
    width < theme.breakpoints.values.lg
  ) {
    return 'md';
  } else if (
    width >= theme.breakpoints.values.lg &&
    width < theme.breakpoints.values.xl
  ) {
    return 'lg';
  } else if (width >= theme.breakpoints.values.xl) {
    return 'xl';
  }
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(0));

  const calcInnerWidth = throttle(function () {
    setBrkPnt(getDeviceConfig(window.innerWidth));
  }, 0);

  useEffect(() => {
    setBrkPnt(getDeviceConfig(window.innerWidth));

    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};
export default useBreakpoint;
