import { actionTypes } from './actionTypes';
import { ICurrencyInfo } from '@/types/index';

export interface SetCurrencyAction {
  type: actionTypes.SET_CURRENCY;
  payload: ICurrencyInfo;
}

export const setCurrency = (currency: ICurrencyInfo): SetCurrencyAction => {
  return {
    type: actionTypes.SET_CURRENCY,
    payload: currency,
  };
};
