import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { setUserProfile } from '@/store/actions/user';

function Klaviyo() {
  const router = useRouter();
  const dispatch = useDispatch();
  const { profile } = router.query;
  const customer = useSelector(state => (state as any).userReducer.customer);
  const loadingCustomer = useSelector(
    state => (state as any).userReducer.loadingCustomer
  );

  useEffect(() => {
    if (!loadingCustomer && !customer && window?.Mirta?.klaviyo) {
      window.Mirta.klaviyo.identifyByUrl();
      if (
        !window.Mirta.getCurrentId() ||
        !window.Mirta.getCurrentId()['uuid'] ||
        window.Mirta.getCurrentId()['uuid'] == ''
      ) {
        let tempUUID = window.Mirta.create_UUID();
        window.Mirta.klaviyo.identifyInCookie(null, tempUUID);

        const analyticsPayload = window.Mirta.addUserInfoToAnalyticsPayload({
          pageUrl: location.href,
        });

        window.Mirta.pushEventToAnalytics('firstArrival', analyticsPayload);
      }
    }

    if (!loadingCustomer && customer && window?.Mirta?.klaviyo) {
      //window.Mirta.klaviyo.identifyByUrl();
      let currentIdentification = window.Mirta.getCurrentId() || {};
      window.Mirta.klaviyo.identifyInCookie(
        customer.email,
        currentIdentification['uuid'],
        true
      );

      if (customer.id) {
        const id = customer.id.replace('gid://shopify/Customer/', '');
        window.Mirta.klaviyo.changeId(id);
      }
    }
  }, [customer, loadingCustomer]);

  const setKlavioCookie = (event: any) => {
    // TODO: type this klavioIdentifyInCookie event
    if (window?.Mirta && window?.Mirta?.klaviyo) {
      let curUUID = window.Mirta.getCurrentId()['uuid'];
      var _uuid = window.Mirta.create_UUID();
      if (curUUID && curUUID != '') {
        _uuid = curUUID;
      }

      window.Mirta.klaviyo.identifyInCookie(event.detail.email, _uuid);
    }
  };

  useEffect(() => {
    document.addEventListener('klavioIdentifyInCookie', setKlavioCookie, false);
    return () => {
      document.removeEventListener(
        'klavioIdentifyInCookie',
        setKlavioCookie,
        false
      );
    };
  }, []);

  useEffect(() => {
    if (window?.Mirta?.klaviyo) {
      if (!loadingCustomer) {
        window.Mirta.klaviyo.trackNavigation();
      }

      const urlParamUUID =
        router.query[window.Mirta.klaviyo.urlParamUUID] || null;
      const urlParamEmail =
        router.query[window.Mirta.klaviyo.urlParamEmail] || null;
      if (customer && urlParamUUID && urlParamEmail) {
        window.Mirta.klaviyo.identifyByUrl();
        const id = customer.id.replace('gid://shopify/Customer/', '');
        window.Mirta.klaviyo.identifyInCookie(customer.email, null);
        window.Mirta.klaviyo.changeId(id);
      }
    }
  }, [router.asPath, loadingCustomer, customer]);

  useEffect(() => {
    if (profile) {
      try {
        let profileParam = decodeURIComponent(profile as string);
        if (profileParam) {
          profileParam = profileParam.replace(/&quot;/gi, '"');
          const profiling = JSON.parse(window.atob(profileParam));
          dispatch(setUserProfile(profiling));
        }
      } catch (err) {
        // TODO: this could be an error worthy of log to datadog even if it's catched,  to track that something went wrong in parsing profile queryParam
        return;
      }
    }
  }, [profile]);

  return null;
}

export default Klaviyo;
