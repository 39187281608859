import ProductCardV2 from '@/components/molecules/ProductCard/ProductCard';
import {
  ICmsCategoriesFilters,
  ICollectionPlaceholdersHeading,
  IMetafield,
  IOption,
} from '@/interfaces/index';
import {
  DEFAULT_ORDER,
  DEFAULT_ORDER_LABEL,
  NEW_IN_ORDER_LABEL,
  PRICE_ORDER_ASC,
  PRICE_ORDER_DESC,
} from '@/lib/constants';
import { Product, ProductSearch } from '@/lib/models/types/product';
import { CollectionInfoState } from '@/store/reducers/collectionInfo';
import { SortByValues } from '@/types/catalog';
import {
  CollectionPageContent,
  ICollectionPagePattern,
} from '@/types/collections';
import {
  ICollectionTagFields,
  ISettingsFields,
} from '@/types/contentful/contentful';
import {
  CATALOG_BASE_URL,
  CATALOG_QUERY_DEFAULT_PARAMS,
  FACETS_TO_BE_RETRIEVED,
  filterValidQueryParams,
  getCategoryPathFacetValues,
  getCollapseByParam,
  mashFacetsWithCms,
} from '@/utils/Filters';
import {
  createLabelFromSlug,
  getLabel,
  getPageParam,
  slugifyString,
  sortObjectEntries,
  splitTag,
} from '@/utils/index';
import { Document } from '@contentful/rich-text-types';
import { ParsedUrlQuery } from 'querystring';
import { removeUndefinedValues } from './objects';

export const NUM_VALUES_PER_FILTER = 250;
export const DEFAULT_PRODUCTS_PER_PAGE = 72;

const getCmsSlug = (
  slug: string[],
  selectedGender: ICollectionTagFields | undefined | null
) => {
  let cmsSlug = '[gender]';
  if (slug && slug.length > 0) {
    if (selectedGender) {
      switch (slug.length) {
        case 1:
          cmsSlug = `[gender]`;
          break;
        case 2:
          cmsSlug = `[gender]/[category]`;
          break;
        case 3:
          cmsSlug = `[gender]/[category]/[subcategory]`;
          break;
        case 4:
          cmsSlug = `[gender]/[category]/[subcategory]/[subcategory2]`;
          break;
      }
    } else {
      cmsSlug = `[handle]`;
    }
  }

  return cmsSlug;
};

const makeCategoryHref = (
  slug: string[],
  cat: string,
  category: ICollectionTagFields,
  oldQueryParameters: { [x: string]: string | string[] | undefined },
  isGender: boolean
) => {
  let active = false;
  let newSlugs: string[] = [];
  let newQueryParameters = { ...oldQueryParameters };

  const { isCategoryAll } = splitTag(category.tag);
  let normalizedSubCategory = slugifyString(category.slug);
  let normalizedMacroCategory = slugifyString(cat);

  if (slug && slug[0] && isGender) {
    // page is /collections/<<gender>>/....
    let sluggedHandle = slugifyString(slug[0]);

    newSlugs = [sluggedHandle, normalizedMacroCategory];

    if (!isCategoryAll) {
      if (
        slug[2] &&
        slug[2] === normalizedSubCategory &&
        slug[1] === normalizedMacroCategory
      ) {
        // page is /collections/<<gender>>/normalizedMacroCategory/normalizedSubCategory
        newSlugs = [sluggedHandle, normalizedMacroCategory];
        active = true;
      } else {
        newSlugs.push(normalizedSubCategory);
      }
    } else {
      if (slug[1] && slug[1] === normalizedMacroCategory && !slug[2]) {
        // page is /collections/<<gender>>/normalizedMacroCategory
        newSlugs = [sluggedHandle];
        active = true;
      }
    }
  } else {
    // page is /collections OR /collections/<<handle>>
    let sluggedHandle;
    if (slug && slug[0]) {
      sluggedHandle = slugifyString(slug[0]);
      newSlugs = [sluggedHandle];
    }

    let queryParam: { [key: string]: string } = {};
    let oldParameters: { category?: string; 'sub-category'?: string } = {
      ...oldQueryParameters,
    };

    if (!isCategoryAll) {
      if (
        oldParameters['sub-category'] === normalizedSubCategory &&
        oldParameters['category'] === normalizedMacroCategory
      ) {
        active = true;
        delete oldParameters['category'];
        delete oldParameters['sub-category'];
      } else {
        queryParam['category'] = normalizedMacroCategory;
        queryParam['sub-category'] = normalizedSubCategory;
      }
    } else {
      if (
        oldParameters['category'] === normalizedMacroCategory &&
        !oldParameters['sub-category']
      ) {
        active = true;
        delete oldParameters['category'];
      } else {
        queryParam['category'] = normalizedMacroCategory;
        delete oldParameters['sub-category'];
      }
    }
    newQueryParameters = { ...oldParameters, ...queryParam };
  }

  return { active, newSlugs, newQueryParameters };
};

const makeCrossFilterHref = (
  filterKey: string,
  filter: ICollectionTagFields,
  oldParameters: { [x: string]: string | string[] | undefined }
) => {
  let active = false;
  let queryParam: { [key: string]: string } = {};

  let copyOldParameters = { ...oldParameters };
  if (
    filterKey in copyOldParameters &&
    copyOldParameters[filterKey] === filter.slug
  ) {
    active = true;
    delete copyOldParameters[filterKey];
  } else {
    queryParam[filterKey] = filter.slug;
  }
  const newQueryParameters = { ...copyOldParameters, ...queryParam };
  return { active, newQueryParameters };
};

const makeChips = (
  basePath: string,
  slug: string | string[] | undefined,
  queryParameters: { [key: string]: string | string[] | undefined },
  collectionTags: ICmsCategoriesFilters,
  currencySymbol: string,
  sort_by?: string
) => {
  const chips: {
    label: string;
    href: { pathname: string; query: {} };
  }[] = [];
  let href;

  if (slug && Array.isArray(slug) && slug.length >= 2) {
    let label;
    if (collectionTags?.categories && collectionTags.categories[slug[1]]) {
      slug.slice(1).forEach((s, index) => {
        label = getLabel(s, collectionTags.categories[slug[1]]);

        href = {
          pathname: basePath,
          query: {
            slug: slug.slice(0, index + 1),
            ...queryParameters,
            ...(sort_by ? { sort_by } : {}),
          },
        };

        if (label && href) {
          chips.push({ label, href });
        }
      });
    }
  }

  for (const param in queryParameters) {
    const newQueryParameters = { ...queryParameters };
    delete newQueryParameters[param];

    let label;

    if (param === 'category-path') {
      const arr = (queryParameters[param] as string).split('/');
      arr.forEach(element => {
        label = getLabel(element, collectionTags.categories[arr[0]]);

        let href = {
          pathname: basePath,
          query: {
            slug,
            ...newQueryParameters,
            ...(sort_by ? { sort_by } : {}),
          },
        };
        chips.push({
          label: label || element,
          href,
        });
      });
    } else {
      label = getLabel(
        queryParameters[param] as string,
        collectionTags.filters[param],
        undefined,
        param,
        currencySymbol
      );
    }

    if (param !== 'category-path') {
      href = {
        pathname: basePath,
        query: { slug, ...newQueryParameters, ...(sort_by ? { sort_by } : {}) },
      };

      if (label && href) {
        chips.push({
          label,
          href,
        });
      }
    }
  }

  return chips;
};

const makeChipsInBrand = (
  basePath: string,
  slug: string | string[] | undefined,
  queryParameters: { [key: string]: string | string[] | undefined },
  collectionTags: ICmsCategoriesFilters,
  currencySymbol: string,
  sort_by?: string
) => {
  const chips: {
    label: string;
    href: { pathname: string; query: {} };
  }[] = [];
  let href;

  for (const param in queryParameters) {
    const newQueryParameters = { ...queryParameters };
    delete newQueryParameters[param];
    let qp = { ...newQueryParameters };
    let label;

    if (param === 'category-path') {
      const arr = (queryParameters[param] as string).split(',');

      let arr_cat: string[] = [];
      arr.forEach((element, index) => {
        label = getLabel(element, collectionTags.categories[arr[0]]);

        if (index !== 0) {
          arr_cat.push(arr[index - 1]);
          qp = { ...qp, 'category-path': arr_cat.join(',') };
        }
        let href = {
          pathname: basePath,
          query: {
            slug,
            ...qp,
            ...(sort_by ? { sort_by } : {}),
          },
        };
        chips.push({
          label: label || element,
          href,
        });
      });
    } else {
      label = getLabel(
        queryParameters[param] as string,
        collectionTags.filters[param],
        undefined,
        param,
        currencySymbol
      );
    }

    if (param !== 'category-path') {
      href = {
        pathname: basePath,
        query: { slug, ...newQueryParameters, ...(sort_by ? { sort_by } : {}) },
      };

      if (label && href) {
        chips.push({
          label,
          href,
        });
      }
    }
  }

  return chips;
};

const isGender = (slug: string, genders: ICollectionTagFields[]) => {
  let gender = false;
  if (genders) {
    genders.forEach(element => {
      if (element.slug === slug) {
        gender = true;
      }
    });
  }
  return gender;
};

const getHandle = (slug: string) => {
  let handle;
  switch (slug) {
    case 'women':
      handle = 'woman';
      break;
    case 'men':
      handle = 'man';
      break;
    default:
      handle = slug;
      break;
  }
  return handle;
};

const getHandleReverse = (slug: string | undefined) => {
  let handle;
  switch (slug) {
    case 'woman':
      handle = 'women';
      break;
    case 'man':
      handle = 'men';
      break;
    default:
      handle = slug;
      break;
  }
  return handle;
};

const getSelectedGender = (
  gendersArray: ICollectionTagFields[],
  handle: string
) => {
  let selectedGender = null;
  if (handle) {
    selectedGender = gendersArray.find(genderObj => {
      return genderObj.slug === handle;
    });
  }
  return selectedGender;
};

const getSelectedHandle = (
  collectionTags: ICmsCategoriesFilters,
  slug: string
) => {
  let selectedHandle = null;
  if (
    collectionTags &&
    collectionTags.filters &&
    collectionTags.filters.collection
  ) {
    selectedHandle = collectionTags.filters.collection.find(collection => {
      return collection.slug === slug;
    });
  }

  return selectedHandle;
};

const getSelectedCategory = (
  collectionTags: ICmsCategoriesFilters,
  categorySlug: string
) => {
  let selectedCategory = null;
  if (
    collectionTags &&
    // collectionTags.filters &&
    collectionTags.categories &&
    categorySlug &&
    collectionTags.categories[categorySlug]
  ) {
    selectedCategory = collectionTags.categories[categorySlug].find(
      category => {
        return category.slug === categorySlug;
      }
    );
  }

  return selectedCategory;
};

const getSelectedSubCategory = (
  collectionTags: ICmsCategoriesFilters,
  categorySlug: string,
  subCategorySlug: string
) => {
  let selectedSubCategory = null;
  if (
    collectionTags &&
    // collectionTags.filters &&
    collectionTags.categories &&
    categorySlug &&
    collectionTags.categories[categorySlug]
  ) {
    selectedSubCategory = collectionTags.categories[categorySlug].find(
      category => {
        return category.slug === subCategorySlug;
      }
    );
  }
  return selectedSubCategory;
};

const substitutePlaceholders = (
  str: string,
  data: {
    gender: string | undefined;
    handle: string | undefined;
    category: string | undefined;
    subcategory: string | undefined;
    subcategory2: string | undefined;
  }
) => {
  let resultString = str;
  if (data) {
    if (data.gender) {
      resultString = resultString.replace('$gender$', data.gender);
    }
    if (data.handle) {
      resultString = resultString.replace('$handle$', data.handle);
    }
    if (data.category) {
      resultString = resultString.replace('$category$', data.category);
    }
    if (data.subcategory) {
      resultString = resultString.replace('$subcategory$', data.subcategory);
    }
    if (data.subcategory2) {
      resultString = resultString.replace('$subcategory2$', data.subcategory2);
    }
  }
  resultString = resultString.replace("s's", "s'"); // temporary workaround to avoid infamous cases as PETS'S collection

  return resultString;
};

const replacePlaceHolders = (
  placeholder: string | Document | null,
  data: {
    gender: string | undefined;
    handle: string | undefined;
    category: string | undefined;
    subcategory: string | undefined;
    subcategory2: string | undefined;
  }
) => {
  if (typeof placeholder === 'string') {
    let replacedString = placeholder;
    if (replacedString) {
      replacedString = substitutePlaceholders(replacedString, data);
    }
    return replacedString || null;
  } else {
    let replacedString = JSON.stringify(placeholder);
    if (replacedString && typeof replacedString === 'string') {
      replacedString = substitutePlaceholders(replacedString, data);
    }
    let payload;
    try {
      payload = JSON.parse(replacedString);
    } catch (e) {
      return null;
    }
    return payload || null;
  }
};

const getCmsStandardSlug = (slug: string[]) => {
  if (slug) {
    if (slug[3]) {
      return '[gender]/[category]/[subcategory]/[subcategory2]';
    } else {
      if (slug[2]) {
        return '[gender]/[category]/[subcategory]';
      } else {
        if (slug[1]) {
          return '[gender]/[category]';
        } else {
          return '[gender]';
        }
      }
    }
  } else {
    return null;
  }
};

const replaceHeadingPlaceholders = (
  placeholdersHeading: ICollectionPlaceholdersHeading,
  slug: string[],
  genders: ICollectionTagFields[],
  collectionTags: ICmsCategoriesFilters
): Pick<
  CollectionPageContent,
  'title' | 'descriptionRich' | 'bottomDescriptionRich'
> => {
  let {
    placeholdersTitle,
    placeholdersDescription,
    placeholdersDescriptionBottom,
  } = placeholdersHeading;
  let data;
  let selectedGender,
    selectedHandle,
    selectedCategory,
    selectedSubCategory,
    selectedSubCategory2;
  if (slug && slug[0]) {
    selectedGender = getSelectedGender(genders, slug[0]);
    selectedHandle = getSelectedHandle(collectionTags, slug[0]);
    if (slug[1]) {
      selectedCategory = getSelectedCategory(collectionTags, slug[1]);
      if (slug[2]) {
        selectedSubCategory = getSelectedSubCategory(
          collectionTags,
          slug[1],
          slug[2]
        );
        if (slug[3]) {
          selectedSubCategory2 = getSelectedSubCategory(
            collectionTags,
            slug[1],
            slug[3]
          );
        }
      }
    }

    data = {
      gender: selectedGender
        ? selectedGender?.label
        : createLabelFromSlug(slug && slug[0] ? slug[0] : ''),
      handle: selectedHandle
        ? selectedHandle?.label
        : createLabelFromSlug(slug && slug[0] ? slug[0] : ''),
      category: selectedCategory
        ? selectedCategory?.label
        : createLabelFromSlug(slug && slug[1] ? slug[1] : ''),
      subcategory: selectedSubCategory
        ? selectedSubCategory?.label
        : createLabelFromSlug(slug && slug[2] ? slug[2] : ''),
      subcategory2: selectedSubCategory2
        ? selectedSubCategory2?.label
        : createLabelFromSlug(slug && slug[3] ? slug[3] : ''),
    };
  }

  return {
    title: data
      ? replacePlaceHolders(placeholdersTitle, data)
      : placeholdersTitle || null,
    descriptionRich: data
      ? replacePlaceHolders(placeholdersDescription, data)
      : placeholdersDescription || null,
    bottomDescriptionRich: data
      ? replacePlaceHolders(placeholdersDescriptionBottom, data)
      : placeholdersDescriptionBottom || null,
  };
};

const initializeSelectedOptions = (product: {
  node: { options: { values: string[]; name: string }[] };
}) => {
  let selOptions = {};
  if (product?.node?.options) {
    for (const option of product.node.options) {
      if (option.values && option.values.length === 1) {
        selOptions = {
          ...selOptions,
          [option.name.toLowerCase()]: option.values[0],
        };
      }
    }
  }

  return selOptions;
};

const getExtraInfo = (metafields: { edges: IMetafield[] }) => {
  let userMsgMetafield,
    productDescMetafield,
    materialDesc,
    color,
    subCategory,
    preorder;

  if (metafields && metafields.edges) {
    metafields.edges.forEach(
      (metafield: { node?: { key: string; value: string } }) => {
        if (metafield.node?.key === 'user_message') {
          userMsgMetafield = metafield.node.value.toUpperCase();
        }

        if (metafield.node?.key === 'short_description') {
          productDescMetafield = metafield;
        }

        if (metafield.node?.key === 'material_description') {
          materialDesc = metafield.node.value;
        }

        if (metafield.node?.key === 'cf_') {
          color = metafield.node.value;
        }

        if (metafield.node?.key === 'product_sub-category') {
          subCategory = metafield.node.value;
        }
        if (metafield.node?.key === 'preorder') {
          preorder = metafield.node.value;
        }
      }
    );
  }

  return {
    extraInfo: {
      userMsgMetafield: userMsgMetafield,
      productDescMetafield: productDescMetafield,
      materialDesc: materialDesc,
      color: color,
      subCategory: subCategory,
      preorder: preorder,
    },
  };
};

const getIndexByObjHandle = (
  handle: string,
  array: Product['productMetafields']['variantsAttributes']
) => {
  return (array || []).findIndex(element => element.handle === handle);
};

const addPaginationToQuery = (query: string, pageNumber: number) => {
  return query + `&page=${pageNumber}`;
};

const generateCards = (products: ProductSearch | null) => {
  let cards = null;
  if (products && products.pagination.itemsPerPage) {
    cards = products.items?.map((product, index) => {
      return (
        <ProductCardV2
          // key={index + product.handle}
          key={index.toString() + new Date().getTime()}
          product={product}
          positionindex={index + 1}
          type="collection list"
        />
      );
    });
  }
  return cards;
};

function getCoords(elem: HTMLElement) {
  let box = elem.getBoundingClientRect();

  return {
    top: box.top + window.pageYOffset,
    right: box.right + window.pageXOffset,
    bottom: box.bottom + window.pageYOffset,
    left: box.left + window.pageXOffset,
  };
}

const setStickyPositionTop = (
  filtersContainer: HTMLElement,
  stickyFilters: HTMLElement
) => {
  let bottomFiltersContainer = getCoords(filtersContainer).bottom;
  let bottomStickyFilters = getCoords(stickyFilters).bottom;
  let stickyFiltersOffsetTop = stickyFilters?.offsetTop;

  const bottomDiff = bottomStickyFilters - bottomFiltersContainer;
  if (bottomDiff > 0 && stickyFiltersOffsetTop > bottomDiff) {
    stickyFiltersOffsetTop = stickyFiltersOffsetTop - bottomDiff;
  }

  stickyFilters.style.position = 'relative';
  stickyFilters.style.top = stickyFiltersOffsetTop + 'px';
};

export const makeCatalogQuery = (
  numFilters: number,
  numProducts: number,
  queryContent: Record<string, string>,
  sortBy?: string | string[] | undefined
) => {
  const baseUrl = `${process.env.NEXT_PUBLIC_CATALOG_API}/products`;

  const params: Record<string, string> = {
    ...CATALOG_QUERY_DEFAULT_PARAMS,
    ...queryContent,
  };
  const includeFilters = numFilters && numFilters > 0 ? true : false;

  params['include-facets'] = String(includeFilters);

  if (includeFilters) {
    params['num-of-values'] = String(numFilters);
    params['facets'] = FACETS_TO_BE_RETRIEVED.join(',');
  }

  params['items-per-page'] = String(numProducts);

  if (numProducts > 0 && sortBy && typeof sortBy === 'string') {
    params['sort-by'] = sortBy;
  }

  const cleanParams = removeUndefinedValues(params);

  const searchParams = new URLSearchParams(cleanParams);
  const catalogQuery = new URL(baseUrl);

  searchParams.forEach((value, key) => {
    catalogQuery.searchParams.append(key, value);
  });

  return catalogQuery.toString();
};

export const makeCatalogQueryBrand = (
  numFilters: number,
  numProducts: number,
  queryContent: Record<string, string | undefined>,
  sortBy?: string | string[] | undefined
): string => {
  const baseUrl = `${process.env.NEXT_PUBLIC_CATALOG_API}/products`;
  const validQueryContent = removeUndefinedValues(queryContent);
  const queryParams = Object.fromEntries(
    Object.entries(validQueryContent).map(([key, value]) => [
      key,
      value.replaceAll(',', '/'),
    ])
  );

  const params: Record<string, string> = {
    ...CATALOG_QUERY_DEFAULT_PARAMS,
    ...queryParams,
  };
  const includeFilters = numFilters && numFilters > 0 ? true : false;

  params['include-facets'] = String(includeFilters);

  if (includeFilters) {
    params['num-of-values'] = String(numFilters);
    params['facets'] = FACETS_TO_BE_RETRIEVED.join(',');
  }

  params['items-per-page'] = String(numProducts);

  if (numProducts > 0 && sortBy && typeof sortBy === 'string') {
    params['sort-by'] = sortBy;
  }

  const cleanParams = removeUndefinedValues(params);

  const searchParams = new URLSearchParams(cleanParams);
  const catalogQuery = new URL(baseUrl);

  searchParams.forEach((value, key) => {
    catalogQuery.searchParams.append(key, value);
  });

  return catalogQuery.toString();
};

const getCatalogQueryContent = (
  slug: string | string[] | undefined,
  { slug: _, ...queryParams }: { [key: string]: string },
  collectionTags: ICmsCategoriesFilters,
  alternativeFilter: 'collection' | 'artisan-registry-id'
): Record<string, string> => {
  const query: Record<string, string> = { ...queryParams };

  if (slug && (typeof slug === 'string' || slug.length)) {
    const nSlug: string = Array.isArray(slug) ? slug[0] : slug;

    const gender = isGender(nSlug, collectionTags?.filters?.gender);

    const currentFilter = gender ? 'gender' : alternativeFilter;

    const cmsFilterFound = collectionTags?.filters?.[currentFilter]?.find(
      filter => filter.slug === nSlug
    );

    const getCmsValue = ({ tag }: ICollectionTagFields) => {
      const { suffix } = splitTag(tag);
      return slugifyString(suffix);
    };

    const value = cmsFilterFound ? getCmsValue(cmsFilterFound) : nSlug;

    query[currentFilter] = value;
  }

  const { slug: _slug, sort_by: _sortBy, ...filteredQuery } = query;

  return filteredQuery;
};

const getCatalogQueryBrand = (
  brandId: string | undefined,
  queryParameters: { [key: string]: string | string[] | undefined },
  collectionTags: ICmsCategoriesFilters,
  numProducts: number,
  numFilters: number,
  currency?: string
) => {
  const { sort_by, p, ...queryParams } = queryParameters;

  if (currency) {
    queryParams.currency = currency;
  }

  const queryContent = getCatalogQueryContent(
    brandId,
    queryParams as { [key: string]: string },
    collectionTags,
    'artisan-registry-id'
  );

  const sortBy = queryParams?.sort_by;
  const catalogQuery = makeCatalogQueryBrand(
    numFilters,
    numProducts,
    queryContent,
    sortBy
  );

  const pageNumber = getPageParam(p);
  const paginatedQuery = addPaginationToQuery(catalogQuery, pageNumber);

  return paginatedQuery;
};

export const createCatalogQueries = (
  routeParsedQuery: ParsedUrlQuery,
  routeUrl: string,
  collectionTags: ICmsCategoriesFilters,
  currency: string,
  settings: ISettingsFields,
  segmentPrice?: string,
  mainUrl?: string
) => {
  let { slug, queryParameters: validQueryParams } = getCollectionUrlInfo(
    routeParsedQuery,
    routeUrl,
    settings
  );

  let filtersUrl: { [key: string]: string } = {};

  let mainQuery;

  if (mainUrl) {
    mainQuery = mainUrl;
  } else {
    mainQuery = getCatalogQuery(
      routeParsedQuery,
      routeUrl,
      settings,
      currency,
      collectionTags,
      'full',
      segmentPrice
    );
  }

  filtersUrl[mainQuery] = '*';

  for (const queryParam in validQueryParams) {
    //filters url without queryParam

    const crossFilterQuery = getCatalogQuery(
      routeParsedQuery,
      routeUrl,
      settings,
      currency,
      collectionTags,

      'filters',
      segmentPrice,
      { queryParamToRemove: queryParam }
    );

    filtersUrl[crossFilterQuery] = queryParam;
  }

  if (slug && slug.length >= 3) {
    const categoryPathQuery = getCatalogQuery(
      routeParsedQuery,
      routeUrl,
      settings,
      currency,
      collectionTags,
      'filters',
      segmentPrice,
      {
        slug: slug.slice(0, 2),
      }
    );

    filtersUrl[categoryPathQuery] = 'category-path';
  }

  return filtersUrl;
};

// CREATE CATALOG QUERIES FOR BRANDS
export const createCatalogQueriesBrands = (
  brandId: string,
  queryParameters: { [key: string]: string | string[] | undefined },
  collectionTags: ICmsCategoriesFilters,
  numProductsToFetch: number,
  numFiltersToFetch: number,
  currency?: string,
  mainUrl?: string,
  segmentPrice?: string,
  collapseBy: 'name' | 'handle' | 'none' = 'handle'
) => {
  let filtersUrl: { [key: string]: string } = {};

  const { ...newqueryParameters } = queryParameters;

  let mainQuery;
  if (mainUrl) {
    mainQuery = mainUrl;

    if (currency) {
      mainQuery = `${mainQuery}&currency=${currency}`;
    }

    if (segmentPrice) {
      mainQuery = `${mainQuery}&segment-price=${segmentPrice}`;
    }
  } else {
    mainQuery = getCatalogQueryBrand(
      brandId,
      {
        ...newqueryParameters,
        'collapse-by': collapseBy,
        ...(segmentPrice ? { 'segment-price': segmentPrice } : {}),
      },

      collectionTags,
      numProductsToFetch,
      numFiltersToFetch,
      currency
    );
  }

  filtersUrl[mainQuery] = '*';

  for (const queryParam in newqueryParameters) {
    if (
      queryParam !== 'sort_by' &&
      (queryParam !== 'category' ||
        (queryParam === 'category' &&
          !Object.keys(newqueryParameters).includes('sub-category')))
    ) {
      let copyNewQueryParameters = { ...newqueryParameters };
      delete copyNewQueryParameters[queryParam]; // queryParam must not be contained in new filters url

      //filters url without queryParam

      const crossFilterQuery = getCatalogQueryBrand(
        brandId,
        {
          ...copyNewQueryParameters,
          'collapse-by': collapseBy,
          ...(segmentPrice ? { 'segment-price': segmentPrice } : {}),
        },

        collectionTags,
        0,
        numFiltersToFetch,
        currency
      );
      if (queryParam === 'category' || queryParam === 'sub-category') {
        filtersUrl[crossFilterQuery] =
          newqueryParameters['category'] === 'bags'
            ? 'bag-category'
            : (newqueryParameters['category'] as string);
      } else {
        filtersUrl[crossFilterQuery] = queryParam;
      }
    }
  }

  return filtersUrl;
};

export const getArtisanProductsQuery = (
  brandId: string,
  numProducts: number,
  queryParameters: { [key: string]: string | string[] | undefined }
) => {
  const { sort_by, p, ...queryParams } = queryParameters;

  const catalogQuery = makeCatalogQueryBrand(
    250,
    numProducts,
    {
      'artisan-registry-id': brandId,
      ...queryParams,
    },
    sort_by ? sort_by : SortByValues.RELEVANCE
  );

  const pageNumber = getPageParam(p);
  const paginatedQuery = addPaginationToQuery(catalogQuery, pageNumber);
  return paginatedQuery;
};

export const getCollectionInfo = (
  slug: string[],
  cmsPagePatterns: ICollectionPagePattern[],
  genders: ICollectionTagFields[],
  cmsCollectionTags: ICmsCategoriesFilters
): CollectionInfoState['collectionInfo'] | null => {
  if (cmsPagePatterns) {
    let foundStandard;
    let cmsStandardSlug = getCmsStandardSlug(slug as string[]);

    if (cmsStandardSlug) {
      foundStandard = cmsPagePatterns.find(
        (cmsStandard: ICollectionPagePattern) => {
          return cmsStandard.fields.slug === cmsStandardSlug;
        }
      );
    }

    if (foundStandard) {
      let placeholdersHeading: {
        placeholdersTitle: any;
        placeholdersDescription: any;
        placeholdersDescriptionBottom: any;
      };
      placeholdersHeading = {
        placeholdersTitle: foundStandard.fields.title,
        placeholdersDescription: foundStandard.fields.descriptionRich,
        placeholdersDescriptionBottom:
          foundStandard.fields.bottomDescriptionRich,
      };
      let headingReplaced = replaceHeadingPlaceholders(
        placeholdersHeading,
        slug as string[],
        genders,
        cmsCollectionTags
      );

      if (headingReplaced) {
        return {
          title: headingReplaced.title,
          descriptionRich: headingReplaced.descriptionRich,
          bottomDescriptionRich: headingReplaced.bottomDescriptionRich,
          collectionImages: {
            mobile: foundStandard?.fields.collectionImageMobile,
            desktop: foundStandard?.fields.collectionImage!,
          },
        };
      } else {
        return null;
      }
    }
  }
  return null;
};

const collectionSortOptions: IOption[] = [
  {
    value: 'relevance',
    label: DEFAULT_ORDER_LABEL,
  },
  {
    value: 'lower_price',
    label: PRICE_ORDER_ASC,
  },
  {
    value: 'higher_price',
    label: PRICE_ORDER_DESC,
  },

  {
    value: 'new_in',
    label: NEW_IN_ORDER_LABEL,
  },
];

const initSortSelected = (sort_by: string | null) => {
  const sortValue = sort_by || DEFAULT_ORDER;

  let foundOption = collectionSortOptions.find(opt => {
    return opt.value === sortValue;
  });

  return foundOption || null;
};

export const getCollectionUrlInfo = (
  routeParsedQuery: ParsedUrlQuery,
  routeUrl: string,
  settings: ISettingsFields | undefined
) => {
  let { slug, sort_by, p, ...queryParameters } = routeParsedQuery;

  if (slug && !Array.isArray(slug)) {
    slug = [slug];
  }

  const validQueryParams = filterValidQueryParams(queryParameters);
  const selectedSort = initSortSelected(sort_by as string);
  const collapseBy = getCollapseByParam(routeUrl, settings);
  return {
    slug: slug as string[],
    queryParameters: validQueryParams as {
      [k: string]: string;
    },
    selectedSort,
    pageNumber: getPageParam(p),
    collapseBy: collapseBy as 'name' | 'handle',
  };
};

export const getCatalogQueryFiltersContent = (
  routeParsedQuery: ParsedUrlQuery,
  routeUrl: string,
  settings: ISettingsFields | undefined,
  currency: string,
  cmsCollectionTags: ICmsCategoriesFilters,
  segmentPrice?: string,
  override?: UrlOverride
) => {
  const queyContent = getCatalogQueryCommonContent(
    routeParsedQuery,
    routeUrl,
    settings,
    currency,
    cmsCollectionTags,
    segmentPrice,
    override
  );
  queyContent['include-facets'] = String(true);
  queyContent['num-of-values'] = String(NUM_VALUES_PER_FILTER);
  queyContent['facets'] = FACETS_TO_BE_RETRIEVED.join(',');
  queyContent['items-per-page'] = String(0);

  return queyContent;
};

export const getCatalogQueryProductsContent = (
  routeParsedQuery: ParsedUrlQuery,
  routeUrl: string,
  settings: ISettingsFields | undefined,
  currency: string,
  cmsCollectionTags: ICmsCategoriesFilters,
  segmentPrice?: string,
  override?: UrlOverride
) => {
  const { selectedSort } = getCollectionUrlInfo(
    routeParsedQuery,
    routeUrl,
    settings
  );
  const queryContent = getCatalogQueryCommonContent(
    routeParsedQuery,
    routeUrl,
    settings,
    currency,
    cmsCollectionTags,
    segmentPrice,
    override
  );

  queryContent['include-facets'] = String(false);
  queryContent['items-per-page'] = String(
    settings?.collectionPageNumProducts || DEFAULT_PRODUCTS_PER_PAGE
  );

  if (selectedSort?.value) {
    queryContent['sort-by'] = selectedSort.value;
  }

  return queryContent;
};
export const getCatalogQueryFullContent = (
  routeParsedQuery: ParsedUrlQuery,
  routeUrl: string,
  settings: ISettingsFields | undefined,
  currency: string,
  cmsCollectionTags: ICmsCategoriesFilters,
  segmentPrice?: string,
  override?: UrlOverride
) => {
  const { selectedSort } = getCollectionUrlInfo(
    routeParsedQuery,
    routeUrl,
    settings
  );

  const queryContent = getCatalogQueryCommonContent(
    routeParsedQuery,
    routeUrl,
    settings,
    currency,
    cmsCollectionTags,
    segmentPrice,
    override
  );

  queryContent['items-per-page'] = String(
    settings?.collectionPageNumProducts || DEFAULT_PRODUCTS_PER_PAGE
  );

  if (selectedSort?.value) {
    queryContent['sort-by'] = selectedSort.value;
  }

  queryContent['include-facets'] = String(true);
  queryContent['num-of-values'] = String(NUM_VALUES_PER_FILTER);
  queryContent['facets'] = FACETS_TO_BE_RETRIEVED.join(',');

  return queryContent;
};

export const getCatalogQueryCommonContent = (
  routeParsedQuery: ParsedUrlQuery,
  routeUrl: string,
  settings: ISettingsFields | undefined,
  currency: string,
  cmsCollectionTags: ICmsCategoriesFilters,
  segmentPrice?: string,
  override?: UrlOverride
) => {
  let {
    slug,
    queryParameters: queryParams,
    collapseBy,
    pageNumber,
  } = getCollectionUrlInfo(routeParsedQuery, routeUrl, settings);

  if (override?.slug) {
    slug = override.slug;
  }
  if (override?.queryParamToRemove) {
    if (queryParams[override.queryParamToRemove]) {
      delete queryParams[override.queryParamToRemove];
    }
  }

  const queryContent = getCatalogQueryContent(
    slug,
    queryParams,
    cmsCollectionTags,
    'collection'
  );

  if (currency) {
    queryContent['currency'] = currency;
  }

  const categoryPath = slug ? (slug as string[]).slice(1).join('/') : null;

  if (categoryPath) {
    queryContent['category-path'] = categoryPath;
  }
  if (pageNumber) {
    queryContent['page'] = pageNumber.toString();
  }

  if (segmentPrice) {
    queryContent['segment-price'] = segmentPrice;
  }

  if (collapseBy) {
    queryContent['collapse-by'] = collapseBy;
  }

  const params: Record<string, string> = {
    ...CATALOG_QUERY_DEFAULT_PARAMS,
    ...queryContent,
  };
  return params;
};
type UrlOverride =
  | {
      slug: string[];
      queryParamToRemove?: never;
    }
  | {
      slug?: never;
      queryParamToRemove: string;
    };
export const getCatalogQuery = (
  routeParsedQuery: ParsedUrlQuery,
  routeUrl: string,
  settings: ISettingsFields | undefined,
  currency: string,
  cmsCollectionTags: ICmsCategoriesFilters,
  type: 'filters' | 'products' | 'full',
  segmentPrice?: string,
  override?: UrlOverride
) => {
  let queryContent: Record<string, string>;
  if (type === 'filters') {
    queryContent = getCatalogQueryFiltersContent(
      routeParsedQuery,
      routeUrl,
      settings,
      currency,
      cmsCollectionTags,
      segmentPrice,
      override
    );
  }
  if (type === 'products') {
    queryContent = getCatalogQueryProductsContent(
      routeParsedQuery,
      routeUrl,
      settings,
      currency,
      cmsCollectionTags,
      segmentPrice,
      override
    );
  }
  if (type === 'full') {
    queryContent = getCatalogQueryFullContent(
      routeParsedQuery,
      routeUrl,
      settings,
      currency,
      cmsCollectionTags,
      segmentPrice,
      override
    );
  }

  const cleanParams = removeUndefinedValues(queryContent!);

  const searchParams = new URLSearchParams(cleanParams);
  const catalogQuery = new URL(CATALOG_BASE_URL);

  searchParams.forEach((value, key) => {
    catalogQuery.searchParams.append(key, value);
  });

  return catalogQuery.toString();
};

export const getCategoriesAndFilters = (
  slug: string[],
  catalogQueries: {
    [k: string]: string;
  },
  data: { url: string; result: ProductSearch }[],
  cmsCollectionTags: ICmsCategoriesFilters,
  currency: string
) => {
  let apiFilters;
  let filters, categories;
  const mainUrl = Object.keys(catalogQueries).find(
    k => catalogQueries[k] === '*'
  );
  const mainUrlData = data.find(d => {
    return d.url === mainUrl;
  });
  const mainUrlResult = mainUrlData?.result;
  const additionalData = data.filter(d => d.url !== mainUrl);

  for (const currData of additionalData) {
    const currDataUrl = currData.url;
    const currFilterName = catalogQueries[currDataUrl];
    if (currFilterName !== 'category-path') {
      let additionalValues = currData.result?.facets?.find(facet => {
        return facet.id === currFilterName;
      })?.values;
      let facetToOverwrite = mainUrlResult?.facets?.find(facet => {
        return facet.id === currFilterName;
      });
      if (facetToOverwrite && additionalValues) {
        facetToOverwrite.values = additionalValues;
      }
    }
  }

  if (mainUrlResult) {
    apiFilters = mainUrlResult;
  }

  let mashedFilters = apiFilters?.facets
    ? mashFacetsWithCms(
        apiFilters?.facets,
        cmsCollectionTags?.filters,
        currency
      )
    : null;
  if (mashedFilters) {
    mashedFilters = sortObjectEntries(mashedFilters);
  }
  filters = mashedFilters;

  const categoryPathFacetValues = getCategoryPathFacetValues(
    catalogQueries,
    data
  );
  if (slug && slug.length > 0 && categoryPathFacetValues) {
    categories = categoryPathFacetValues || [];
  }

  return {
    filters,
    categories,
    mainUrlResult,
  };
};

export {
  getCmsSlug,
  makeCategoryHref,
  makeCrossFilterHref,
  makeChips,
  isGender,
  getHandle,
  getSelectedGender,
  getSelectedHandle,
  getSelectedCategory,
  getSelectedSubCategory,
  getCmsStandardSlug,
  replaceHeadingPlaceholders,
  getHandleReverse,
  initializeSelectedOptions,
  getExtraInfo,
  getIndexByObjHandle,
  addPaginationToQuery,
  generateCards,
  setStickyPositionTop,
  collectionSortOptions,
  initSortSelected,
  getCatalogQueryBrand,
  makeChipsInBrand,
};
