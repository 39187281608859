import React from 'react';
import dynamic from 'next/dynamic';
import { makeLink } from '@/utils/Link';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { IImageWithTextBlockCmp } from './IImageWithTextBlockCmp';

const Components: { [key: string]: any } = {
  ImageWithTextHero: dynamic(
    () => import(`@/components/organisms/ImageWithTextBlock/ImageWithTextHero`)
  ),
  ImageWithText2Col: dynamic(
    () => import(`@/components/organisms/ImageWithTextBlock/ImageWithText2Col`)
  ),
  ImageWithTextCollections: dynamic(
    () =>
      import(
        `@/components/organisms/ImageWithTextBlock/ImageWithTextCollections`
      )
  ),
  ImageWithTextWhyMirta: dynamic(
    () =>
      import(`@/components/organisms/ImageWithTextBlock/ImageWithTextWhyMirta`)
  ),
  ImageWithTextWhyMirtaFocusText: dynamic(
    () =>
      import(
        `@/components/organisms/ImageWithTextBlock/ImageWithTextWhyMirtaFocusText`
      )
  ),
  ImageWithTextArtisanWeek: dynamic(
    () =>
      import(
        `@/components/organisms/ImageWithTextBlock/ImageWithTextArtisanWeek`
      )
  ),
  ImageWithTextMirtaInTheNews: dynamic(
    () =>
      import(
        `@/components/organisms/ImageWithTextBlock/ImageWithTextMirtaInTheNews`
      )
  ),
  ImageWithTextCategoryList: dynamic(
    () =>
      import(
        `@/components/organisms/ImageWithTextBlock/ImageWithTextCategoryList`
      )
  ),
  ImageWithTextBlockListImages: dynamic(
    () =>
      import(
        `@/components/organisms/ImageWithTextBlock/ImageWithTextBlockListImages`
      )
  ),
  ImageWithTextLeftRight: dynamic(
    () =>
      import(`@/components/organisms/ImageWithTextBlock/ImageWithTextLeftRight`)
  ),
  ImageWithTextGridIconsAbout: dynamic(
    () =>
      import(
        `@/components/organisms/ImageWithTextBlock/ImageWithTextGridIconsAbout`
      )
  ),
  ImageWithTextCarouselCustom: dynamic(
    () =>
      import(
        '@/components/organisms/ImageWithTextBlock/ImageWithTextCarouselCustom'
      )
  ),
  ImageWithTextAccordionList: dynamic(
    () =>
      import(
        '@/components/organisms/ImageWithTextBlock/ImageWithTextAccordionList'
      )
  ),
  ImageWithText404: dynamic(
    () => import(`@/components/organisms/ImageWithTextBlock/ImageWithText404`)
  ),

  ImageWithTextProductBox: dynamic(
    () =>
      import(
        `@/components/organisms/ImageWithTextBlock/ImageWithTextProductBox`
      )
  ),

  ImageWithTextPopupDescription: dynamic(
    () =>
      import(
        `@/components/organisms/ImageWithTextBlock/ImageWithTextPopupDescription`
      )
  ),
  Hero: dynamic(() => import(`@/components/organisms/ImageWithTextBlock/Hero`)),
  ContentBlock: dynamic(
    () => import(`@/components/organisms/ImageWithTextBlock/ContentBlock`)
  ),
  ImageWithTextAnimatedText: dynamic(
    () =>
      import(
        `@/components/organisms/ImageWithTextBlock/ImageWithTextAnimatedText`
      )
  ),
  ImageWithTextReviews: dynamic(
    () =>
      import(`@/components/organisms/ImageWithTextBlock/ImageWithTextReviews`)
  ),
  ShowCase: dynamic(
    () => import(`@/components/organisms/ImageWithTextBlock/ShowCase`)
  ),
  BannerWithBackgroundColor: dynamic(
    () =>
      import(
        `@/components/organisms/ImageWithTextBlock/BannerWithBackgroundColor`
      )
  ),
  CategoryChips: dynamic(
    () => import(`@/components/organisms/ImageWithTextBlock/CategoryChips`)
  ),
};

const ImageWithTextBlock: React.FC<IImageWithTextBlockCmp> = props => {
  let content = null;

  const { imageWithText, maxWidthText, template, backgroundColor } =
    props.fields;
  const { index } = props;

  if (!imageWithText) return null;

  const headingTag = index === 0 ? 'h1' : 'h2';
  const descriptionRich = imageWithText[0]?.fields?.descriptionRich || null;

  const options = {
    renderText: (text: any) =>
      text
        .split('\n')
        .flatMap((text: any, i: number) => [i > 0 && <br />, text]),
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_: any, children: React.ReactNode) => children,
    },
  };

  let desc = null;
  if (descriptionRich) {
    desc = documentToReactComponents(descriptionRich, options);
  }

  const headingText = imageWithText[0].fields?.headingText
    ? imageWithText[0]?.fields?.headingText?.replace(/\n\r?/g, '<br>')
    : '';

  const textColor = imageWithText[0]?.fields?.textColor || '';
  const borderColor = imageWithText[0]?.fields?.borderColor || '';
  const ctaStyle = imageWithText[0]?.fields?.ctaStyle || '';
  const ctaColor = imageWithText[0]?.fields?.ctaColor || '';
  const enableFont = imageWithText[0]?.fields?.enableFont;

  let url = '';
  if (
    imageWithText &&
    imageWithText.length > 0 &&
    imageWithText[0]?.fields?.link &&
    imageWithText[0]?.fields?.link[0].fields.type &&
    imageWithText[0]?.fields?.link[0].fields.href
  ) {
    url = makeLink(
      imageWithText[0]?.fields?.link[0].fields.type,
      imageWithText[0]?.fields?.link[0].fields.href,
      null
    );
  }
  let target;

  if (
    imageWithText &&
    imageWithText.length > 0 &&
    imageWithText[0]?.fields?.link &&
    imageWithText[0]?.fields?.link[0].fields.target
  ) {
    target = imageWithText[0]?.fields?.link[0].fields.target;
  }

  let label = null;

  if (
    imageWithText &&
    imageWithText.length > 0 &&
    imageWithText[0]?.fields?.link &&
    imageWithText[0]?.fields?.link[0].fields.text
  ) {
    label = imageWithText[0]?.fields?.link[0].fields.text;
  }

  const bgColor = backgroundColor || null;

  const media = imageWithText[0]?.fields?.media || null;

  const mediaMobile = imageWithText[0]?.fields?.mediaMobile
    ? imageWithText[0]?.fields?.mediaMobile
    : media;

  const altTag = imageWithText[0]?.fields?.altTag;
  let imageWithTextData: any = [];

  if (
    template === 'why_mirta' ||
    template === 'why_mirta_focus_text' ||
    template === 'category_card' ||
    template === 'category_card_1_item_mobile' ||
    template === 'mirta_in_the_news' ||
    template === 'banner_collections' ||
    template === 'list_images' ||
    template === 'carousel_custom' ||
    template === 'carousel_custom_small' ||
    template === 'accordion_list' ||
    template === 'product_box' ||
    template === 'popup_content' ||
    template === 'hero_custom_carousel' ||
    template === 'hero_custom_carousel_small' ||
    template === 'animated_text' ||
    template === 'reviews' ||
    template === 'showcase' ||
    template === 'category_chips'
  ) {
    let slides =
      template === 'banner_collections' ||
      template === 'hero_custom_carousel' ||
      template === 'hero_custom_carousel_small'
        ? imageWithText.slice(1)
        : imageWithText;

    slides.forEach(singleElement => {
      if (singleElement) {
        const headingText = singleElement?.fields?.headingText
          ? singleElement?.fields?.headingText?.replace(/\n\r?/g, '<br>')
          : '';

        let url = '';
        let urlLabel = '';
        let popupContent, linkType;
        let target = '_self';

        //TODO: this sequence of if could be rewritten in a more concise way
        if (
          singleElement &&
          singleElement?.fields?.link &&
          singleElement?.fields?.link[0].fields.type &&
          singleElement?.fields?.link[0].fields.href
        ) {
          url = makeLink(
            singleElement?.fields?.link[0].fields.type,
            singleElement?.fields?.link[0].fields.href,
            null
          );
        }
        if (
          singleElement &&
          singleElement?.fields?.link &&
          singleElement?.fields?.link[0].fields.text
        ) {
          urlLabel = singleElement?.fields?.link[0].fields.text;
        }

        if (
          singleElement &&
          singleElement?.fields?.link &&
          singleElement?.fields?.link[0].fields.target
        ) {
          target = singleElement?.fields?.link[0].fields.target;
        }

        if (
          singleElement &&
          singleElement?.fields?.link &&
          singleElement?.fields?.link[0]?.fields.type
        ) {
          linkType = singleElement?.fields?.link[0]?.fields?.type;
        }

        if (
          singleElement &&
          singleElement?.fields?.link &&
          singleElement?.fields?.link[0].fields.popupContent
        ) {
          popupContent = singleElement?.fields?.link[0].fields.popupContent;
        }

        let desc = null;
        if (singleElement?.fields?.descriptionRich) {
          desc = documentToReactComponents(
            singleElement?.fields?.descriptionRich,
            options
          );
        }

        imageWithTextData.push({
          id: singleElement.sys.id,
          headingText: headingText,
          media: singleElement?.fields?.media,
          mediaMobile:
            singleElement?.fields?.mediaMobile || singleElement?.fields?.media,
          description: desc,
          url: url,
          urlLabel: urlLabel,
          linkType: linkType,
          popupContent: popupContent,
          target: target,
          altTag: singleElement?.fields?.altTag,
          content: singleElement?.fields?.content,
          category: singleElement?.fields?.categoryPath,
        });
      }
    });
  }

  let DynamicComponent = null;

  switch (template) {
    case 'banner_hero':
      DynamicComponent = Components.ImageWithTextHero;
      break;
    case 'banner_hero_text_center':
      DynamicComponent = Components.ImageWithTextHero;
      break;
    case 'banner_hero_text_below':
      DynamicComponent = Components.ImageWithTextHero;
      break;
    case 'banner_2_columns':
      DynamicComponent = Components.ImageWithText2Col;
      break;
    case 'banner_collections':
      DynamicComponent = Components.ImageWithTextCollections;
      break;
    case 'banner_with_background_color':
      DynamicComponent = Components.BannerWithBackgroundColor;
      break;
    case 'banner_with_background_color_small':
      DynamicComponent = Components.BannerWithBackgroundColor;
      break;
    case 'why_mirta':
      DynamicComponent = Components.ImageWithTextWhyMirta;
      break;
    case 'why_mirta_focus_text':
      DynamicComponent = Components.ImageWithTextWhyMirtaFocusText;
      break;
    case 'artisan_of_the_week':
      DynamicComponent = Components.ImageWithTextArtisanWeek;
      break;
    case 'mirta_in_the_news':
      DynamicComponent = Components.ImageWithTextMirtaInTheNews;
      break;
    case 'category_card':
      DynamicComponent = Components.ImageWithTextCategoryList;
      break;
    case 'category_card_1_item_mobile':
      DynamicComponent = Components.ImageWithTextCategoryList;
      break;
    case 'list_images':
      DynamicComponent = Components.ImageWithTextBlockListImages;
      break;
    case 'image_left':
      DynamicComponent = Components.ImageWithTextLeftRight;
      break;
    case 'image_left_with_button':
      DynamicComponent = Components.ImageWithTextLeftRight;
      break;
    case 'image_right':
      DynamicComponent = Components.ImageWithTextLeftRight;
      break;
    case 'image_right_with_button':
      DynamicComponent = Components.ImageWithTextLeftRight;
      break;
    case 'carousel_custom':
      DynamicComponent = Components.ImageWithTextCarouselCustom;
      break;
    case 'carousel_custom_small':
      DynamicComponent = Components.ImageWithTextCarouselCustom;
      break;
    case 'hero_custom_carousel':
      DynamicComponent = Components.ImageWithTextCarouselCustom;
      break;
    case 'hero_custom_carousel_small':
      DynamicComponent = Components.ImageWithTextCarouselCustom;
      break;
    case 'accordion_list':
      DynamicComponent = Components.ImageWithTextAccordionList;
      break;
    case 'product_box':
      DynamicComponent = Components.ImageWithTextProductBox;
      break;
    case 'popup_content':
      DynamicComponent = Components.ImageWithTextPopupDescription;
      break;
    case 'hero_img_left':
    case 'hero_img_right':
      DynamicComponent = Components.Hero;
      break;
    case 'content_block_img_left':
    case 'content_block_img_right':
      DynamicComponent = Components.ContentBlock;
      break;
    case 'animated_text':
      DynamicComponent = Components.ImageWithTextAnimatedText;
      break;
    case 'reviews':
      DynamicComponent = Components.ImageWithTextReviews;
      break;
    case 'showcase':
      DynamicComponent = Components.ShowCase;
      break;
    case 'category_chips':
      DynamicComponent = Components.CategoryChips;
      break;
  }

  const headingDescription = descriptionRich?.content
    ?.map(item => item.content.map((content: any) => content.value).join(''))
    .join('<br>');

  if (DynamicComponent) {
    if (
      template === 'why_mirta' ||
      template === 'why_mirta_focus_text' ||
      template === 'category_card' ||
      template === 'category_card_1_item_mobile' ||
      template === 'mirta_in_the_news' ||
      template === 'list_images' ||
      template === 'carousel_custom' ||
      template === 'carousel_custom_small' ||
      template === 'accordion_list' ||
      template === 'animated_text' ||
      template === 'reviews' ||
      template === 'showcase' ||
      template === 'category_chips'
    ) {
      content = (
        <DynamicComponent
          numberOfItems={imageWithText.length}
          imageWithTextData={imageWithTextData}
          bgColor={bgColor}
          template={template}
          maxWidthText={maxWidthText}
        />
      );
    } else if (
      template === 'banner_collections' ||
      template === 'product_box'
    ) {
      content = (
        <DynamicComponent
          numberOfItems={imageWithText.length}
          imageWithTextData={imageWithTextData}
          bgColor={bgColor}
          headingTag={headingTag}
          headingText={headingText}
          label={label}
          target={target}
          url={url}
          mediaMobile={mediaMobile}
          media={media}
          maxWidthText={maxWidthText}
          template={template}
          index={index}
          desc={desc}
          textColor={textColor}
          borderColor={borderColor}
          ctaStyle={ctaStyle}
          ctaColor={ctaColor}
          altTag={altTag}
        />
      );
    } else if (
      template === 'hero_custom_carousel' ||
      template === 'hero_custom_carousel_small'
    ) {
      content = (
        <DynamicComponent
          numberOfItems={imageWithText.length}
          imageWithTextData={imageWithTextData}
          bgColor={bgColor}
          template={template}
          headingText={headingText}
          headingDescription={headingDescription}
          enableFont={enableFont}
        />
      );
    } else {
      content = (
        <DynamicComponent
          headingTag={headingTag}
          headingText={headingText}
          label={label}
          target={target}
          url={url}
          mediaMobile={mediaMobile}
          media={media}
          maxWidthText={maxWidthText}
          template={template}
          index={index}
          desc={desc}
          headingDescription={headingDescription} // for now "desc" and "headingDescription" are the same description but in different formats
          textColor={textColor}
          borderColor={borderColor}
          ctaStyle={ctaStyle}
          ctaColor={ctaColor}
          altTag={altTag}
          bgColor={bgColor}
          enableFont={enableFont}
        />
      );
    }
  }

  return content;
};

export default ImageWithTextBlock;
