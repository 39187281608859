import { httpGateway } from '@/shared/HttpGateway';
import { ProductVariantResponse, SearchResultResponse } from '@/types/catalog';
import { removeUndefinedValues, stringifyValues } from '@/utils/objects';
import { FetchError } from '@/utils/typings/errors';
import { Result } from '@/utils/typings/result';

type CatalogSearchParams = {
  'items-per-page'?: number;
  page?: number;
  'segment-price'?: string;
};

export function searchProducts(
  query: string,
  params: CatalogSearchParams = {}
): Promise<Result<SearchResultResponse, FetchError>> {
  const extraParams = stringifyValues(removeUndefinedValues(params));

  const queryParams = new URLSearchParams({
    ...extraParams,
    search: query,
    'include-facets': 'false',
    'not-color': 'custom',
    'sold-out': 'false',
    'search-profile': 'storefront',
  });

  return httpGateway.get<SearchResultResponse>(
    `${process.env.NEXT_PUBLIC_CATALOG_API}/products?${queryParams.toString()}`
  );
}

export function getProductVariantsBySku(
  sku: string
): Promise<Result<ProductVariantResponse, FetchError>> {
  return httpGateway.get<ProductVariantResponse>(
    `${process.env.NEXT_PUBLIC_CATALOG_API}/products/${sku}`
  );
}
