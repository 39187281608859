export type Err<T> = {
  _tag: 'error';
  error: T;
};

export type Ok<T> = {
  _tag: 'ok';
  result: T;
};

export type Result<R, E> = Ok<R> | Err<E>;

export const ok = <T>(result: T): Result<T, never> => ({ _tag: 'ok', result });
export const err = <T>(error: T): Result<never, T> => ({
  _tag: 'error',
  error,
});

export function isOk<T>(result: Result<T, unknown>): result is Ok<T> {
  return result._tag === 'ok';
}

export function isErr<T>(result: Result<unknown, T>): result is Err<T> {
  return result._tag === 'error';
}

export function foldResult<R, E, T>(
  result: Result<R, E>,
  onOk: (result: R) => T,
  onErr: (error: E) => T
) {
  if (isOk(result)) {
    return onOk(result.result);
  }

  return onErr(result.error);
}

export function mapResult<A, E, B>(
  result: Result<A, E>,
  map: (a: A) => B
): Result<B, E> {
  if (isErr(result)) {
    return result;
  }

  return ok(map(result.result));
}

export function flatMapResult<A, E1, B, E2>(
  result: Result<A, E1>,
  map: (a: A) => Result<B, E2>
): Result<B, E1 | E2> {
  if (isErr(result)) {
    return result;
  }

  return map(result.result);
}

export async function mapResultAsync<A, E, B>(
  result: Result<A, E>,
  map: (a: A) => Promise<B>
): Promise<Result<B, E>> {
  if (isErr(result)) {
    return result;
  }

  return ok(await map(result.result));
}

export async function flatMapResultAsync<A, E1, B, E2>(
  result: Result<A, E1>,
  map: (a: A) => Promise<Result<B, E2>>
): Promise<Result<B, E1 | E2>> {
  if (isErr(result)) {
    return result;
  }

  return map(result.result);
}

type ResultArrayToValuesArray<Results extends Result<unknown, any>[]> =
  Results extends [Result<infer A, any>, ...infer NewRest]
  ? NewRest extends Result<unknown, any>[]
  ? [A, ...ResultArrayToValuesArray<NewRest>]
  : never
  : Results extends []
  ? []
  : Results extends Result<infer B, any>[]
  ? B[]
  : never;

type SequenceResult<Results extends Result<unknown, any>[]> =
  Results extends Result<unknown, infer E>[]
  ? Result<ResultArrayToValuesArray<Results>, E>
  : Result<ResultArrayToValuesArray<Results>, any>;

export function sequence<Results extends Result<unknown, any>[]>(
  results: Results
): SequenceResult<Results> {
  if (results.every(isOk)) {
    return ok(
      results.map(result => (result as Ok<any>).result)
    ) as SequenceResult<Results>;
  }

  return err(results.find(isErr)!.error) as any;
}

export function partialSequence<Results extends Result<unknown, any>[]>(
  results: Results
): SequenceResult<Results> {

  return ok(
    results.filter(r => isOk(r)).map(result => (result as Ok<any>).result)
  ) as SequenceResult<Results>;
}

export function swallow<R>(result: Result<R, any>): R | null {
  if (isErr(result)) {
    return null;
  }

  return result.result;
}
