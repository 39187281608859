import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'nb_international_promedium',
  },
  zIndex: {
    modal: 1000000004,
    drawer: 1000000002,
  },
  //https://material-ui.com/customization/breakpoints/
  breakpoints: {
    values: {
      xs: 0,
      mobileS: 320,
      mobileM: 375,
      mobileL: 425,
      sm: 600,
      tablet: 768,
      md: 960,
      laptop: 1024,
      lg: 1280,
      xl: 1920,
    },
  },

  palette: {
    error: {
      main: '#b00020',
      light: 'rgba(247, 229, 232)',
    },
    warning: {
      main: '#ff9800',
    },
  },

  props: {
    MuiAppBar: {
      position: 'relative',
      color: 'inherit',
      elevation: 0,
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        color: '#484b5d',
        '&$checked': {
          color: '#484b5d',
        },
      },
    },

    MuiDrawer: {
      paper: {
        overflowY: 'hidden',
      },
    },
    MuiCard: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '10px',
        marginLeft: '14px',
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
});

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobileS: true;
    mobileM: true;
    mobileL: true;
    tablet: true;
    laptop: true;
  }
}

export default theme;
