import { Card, Heart, Typography, TypoWithUnderline } from '@/components/atoms';
import { ProductCardTop, WholesaleMsg, Price } from '@/components/molecules';
import useElementSize from '@/hooks/useElementSize';
import { useIntersect } from '@/hooks/useIntersect';
import { getProductByHandle } from '@/lib/models/product';
import { RootState } from '@/store/store';
import { ICurrencyInfo } from '@/types/index';
import { capitalize } from '@/utils/index';
import { getIndexByObjHandle } from '@/utils/Catalog';
import { dispatchCustomEvent, ProductCardProductV2 } from '@/utils/Impressions';
import { sortVariantAttributes } from '@/utils/Product';
import { isOk } from '@/utils/typings/result';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { IProductCardV2Cmp as IProductCardCmp } from './IProductCardCmp';
import { useAppContext } from '@/context/state';

interface IStyledProps {
  template?: string;
  ref?: any;
}

const CardContainer = styled(Card)<IStyledProps>`
  background-color: ${props => props.theme.paletteDefault.white};
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 276px;
  ${props =>
    props?.template === 'carousel' &&
    css`
      width: 224px;
      ${props => props.theme.breakpoints.up('sm')} {
        width: 256px;
      }
    `}

  ${props =>
    props?.template === 'carousel_small' &&
    css`
      width: 192px;
      ${props => props.theme.breakpoints.up('sm')} {
        width: 224px;
      }
    `}
		${props =>
    props?.template === 'search' &&
    css`
      display: flex;
      width: 100%;
      margin-bottom: 8px;
      max-width: 100%;
      ${props => props.theme.breakpoints.up('sm')} {
        flex-direction: column;
      }
    `}

    a {
    color: inherit;
  }
`;
const Header = styled.header`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  ${props => props.theme.breakpoints.up('sm')} {
    padding: 6px;
  }
  width: 100%;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;
const SubInfo = styled.div<IStyledProps>`
  padding: 12px 0px;
  text-align: left;
  ${props =>
    props?.template === 'search' &&
    css`
      padding: 16px 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    `}
`;
const ProductName = styled.div`
  min-height: 32px;
  margin-bottom: 8px;
  & p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const Vendor = styled.div`
  text-transform: uppercase;
`;

const TopContainer = styled.div<IStyledProps>`
  position: relative;
  padding-top: 120%;
  ${props => props.theme.breakpoints.up('sm')} {
    padding-top: 120%;
  }
  ${props =>
    props?.template === 'search' &&
    css`
      width: 100px;
      padding-top: 0;
      ${props => props.theme.breakpoints.up('sm')} {
        padding-top: 120%;
        width: 100%;
      }
    `}
  figure :before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.productCard.backgroundColor};
    mix-blend-mode: multiply;
    z-index: 1;
    padding-bottom: 0;
  }
`;

const ContentCard = styled.div<IStyledProps>`
  display: flex;
  flex-direction: column;
  ${props =>
    props?.template === 'search' &&
    css`
      display: flex;
      flex-direction: row;
      width: 100%;
      ${props => props.theme.breakpoints.up('sm')} {
        flex-direction: column;
      }
    `}
`;
const Anchor = styled.a`
  display: block;
  width: 100%;
  cursor: pointer;
`;
const CardWrapper = styled.div<IStyledProps>`
  ${props =>
    props?.template === 'search' &&
    css`
      width: 250px;

      ${props => props.theme.breakpoints.up('mobileS')} {
        width: 280px;
      }
      ${props => props.theme.breakpoints.up('mobileM')} {
        width: 320px;
      }
      ${props => props.theme.breakpoints.up('mobileL')} {
        width: 360px;
      }

      ${props => props.theme.breakpoints.up('sm')} {
        flex-direction: column;
        width: 120px;
      }

      ${props => props.theme.breakpoints.up('tablet')} {
        width: 160px;
      }
    `};
`;

const VendorTypography = styled(Typography)`
  line-height: 150%;
`;

const ProductCard: React.FC<IProductCardCmp> = props => {
  const [imgRef, { height }] = useElementSize();

  const { type, customization, positionindex, template, setArrowTopPos } =
    props;
  const currency = useSelector(
    (state: RootState) => state.currencyReducer.currency
  );

  const router = useRouter();

  const [variants, setVariants] = useState([{ ...props.product }]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [product, setProduct] = useState({ ...props.product });

  const context = useAppContext();

  const enableWishlist = context?.settings
    ? context.settings['enableWishlist']
    : true;

  useEffect(() => {
    if (setArrowTopPos && height) {
      setArrowTopPos(height);
    }
  }, [height]);

  useEffect(() => {
    if (
      variants[currentIndex] &&
      product.handle !== variants[currentIndex].handle
    ) {
      setProduct(variants[currentIndex]);
    }
  }, [currentIndex, variants]);

  const productToImpress: ProductCardProductV2 = {
    product,
    positionindex,
    type,
    page: router.asPath,
  };

  const currentElement = useIntersect('productCardV2', ref => ({
    payload: productToImpress,
    ref,
  }));
  const variantAttributes = sortVariantAttributes(
    props.product?.productMetafields?.variantsAttributes,
    props.product.handle
  );

  const changeColor = async (handle: string) => {
    const selectedIndex = getIndexByObjHandle(handle, variantAttributes);
    setCurrentIndex(selectedIndex);
    if (!variants[selectedIndex]) {
      const newProduct = await getProductByHandle(handle);

      if (isOk(newProduct)) {
        variants[selectedIndex] = newProduct.result;

        setVariants([...variants]);
      }
    }
  };

  const productClickEvent = (
    handle: string,
    path: string,
    type: string | undefined,
    currency: ICurrencyInfo | null
  ) => {
    const prodIndex = getIndexByObjHandle(handle, variantAttributes);
    dispatchCustomEvent('productCardClickV2', {
      product: variants[prodIndex],
      path: path,
      type: type,
      currency: currency,
    });
  };

  const showColorVariants =
    customization?.showColorVariants === undefined ||
    customization?.showColorVariants === true;
  const showVendor =
    customization?.showVendor === undefined ||
    customization?.showVendor === true;
  const showHeader =
    customization?.showHeader === undefined ||
    customization?.showHeader === true;

  const productName = capitalize(product.title.toLowerCase());
  const userMessage = product?.productMetafields?.userMessage
    ? product.productMetafields.userMessage.toUpperCase()
    : null;
  return (
    <CardWrapper template={template} ref={currentElement}>
      <CardContainer {...props} template={template}>
        {showHeader && product.productMetafields && (
          <Header>
            <div>
              {userMessage && (
                <TypoWithUnderline>{userMessage}</TypoWithUnderline>
              )}
            </div>

            {enableWishlist && (
              <div>
                <Heart
                  epi={product.variant.id}
                  empi={product.id ?? ''}
                  du={product.handle}
                  productName={productName}
                  type={template === 'wishlist' ? 'wishlist' : null}
                />
              </div>
            )}
          </Header>
        )}
        <Link
          href={`/products/${product.handle}`}
          locale={router.locale}
          passHref>
          <Anchor
            target={customization?.openNewTab ? '_blank' : '_self'}
            onClick={() =>
              productClickEvent(product.handle, router.asPath, type, currency)
            }>
            <ContentCard template={template}>
              {
                <>
                  <TopContainer template={template} ref={imgRef}>
                    {product.images[0] && (
                      <ProductCardTop
                        selectedHandle={product.handle}
                        imgdefault={product.images[0].url}
                        imghover={product.images[1]?.url}
                        title={product.title}
                        {...(showColorVariants && variantAttributes
                          ? {
                              onChangeVariant: changeColor,
                              variantAttributes,
                            }
                          : {})}
                      />
                    )}
                  </TopContainer>
                  <SubInfo template={template}>
                    {showVendor && (
                      <Vendor>
                        <VendorTypography datatype="caption">
                          {product.vendor}
                        </VendorTypography>
                      </Vendor>
                    )}
                    <ProductName>
                      <Typography datatype="caption_1">
                        {`${productName} - ${product.shortDescription || ''}`}
                      </Typography>
                    </ProductName>
                    {props?.template !== 'search' && <WholesaleMsg />}
                    {currency && (
                      <Price
                        template="small"
                        prices={product.prices}
                        currency={currency}
                      />
                    )}
                  </SubInfo>
                </>
              }
            </ContentCard>
          </Anchor>
        </Link>
      </CardContainer>
    </CardWrapper>
  );
};

ProductCard.defaultProps = {
  customization: {
    showColorVariants: true,
    showVendor: true,
    showHeader: true,
  },
};

export default ProductCard;
