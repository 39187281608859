import React from 'react';
import Image from 'next/image';
interface ImageOnHoverProps {
  imgdefault: string;
  imghover?: string;
  change: boolean;
  title: string;
}

const ImageOnHover: React.FC<ImageOnHoverProps> = ({
  imgdefault,
  imghover,
  change,
  title,
}) => {
  return (
    <Image
      src={
        !change
          ? imgdefault
          : imghover ||
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
      }
      alt={title}
      layout="fill"
      unoptimized={true}
    />
  );
};

export default ImageOnHover;
