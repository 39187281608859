export type Environment = 'dev' | 'prod';

export const foldEnvironment = <R>(onDev: () => R, onProd: () => R): R => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod') {
    return onProd();
  }

  return onDev();
};

export const onDev = (fn: () => void) => foldEnvironment(fn, () => {});
