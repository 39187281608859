import { CustomEventMap, dispatchCustomEvent } from '@/utils/Impressions';
import { useEffect, useState } from 'react';

export const useIntersect = <K extends keyof CustomEventMap>(
  event: K,
  payload: (ref: HTMLElement) => CustomEventMap[K]['detail']
) => {
  const [node, setNode] = useState<HTMLElement>();

  useEffect(() => {
    if (node) {
      dispatchCustomEvent(event, payload(node));
    }
  }, [node]);

  return setNode;
};

const useLegacyIntersect = (
  payload: CustomEventMap['productCardV2']['detail']['payload']
) => useIntersect('productCardV2', ref => ({ payload, ref }));

export default useLegacyIntersect;
