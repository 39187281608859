import { actionTypes } from '../actions/actionTypes';
import { CloseSnackbarAction, OpenSnackbarAction } from '../actions/ui';

type Action = OpenSnackbarAction | CloseSnackbarAction;

export type SnackBar = {
  open: boolean;
  type: 'success' | 'error' | 'warning' | 'info' | null;
  position: {
    vertical: 'bottom' | 'top';
    horizontal: 'center' | 'left' | 'right';
  } | null;
  closeCallback?: () => void;
  message: string | JSX.Element | null;
};

//TODO: interface to be completed
interface UiState {
  snackBar: SnackBar;
}
const initialState: UiState = {
  snackBar: {
    open: false,
    type: null,
    position: null,
    message: null,
  },

  modalOpen: false,
  modalType: null,
  modalSource: null,
  modalCloseCallback: () => null,
  orderPopupTimer: [],
  promoBar: {
    show: true,
    sendImpression: true,
  },
};

const uiReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        modalType: action.payload.modalType,
        modalSource: action.payload.modalSource,
        modalWho: action.payload.modalWho,
        modalCloseCallback: action.payload.modalCloseCallback,
        modalOpen: true,
      };
    case actionTypes.CLOSE_MODAL:
      if (state.modalCloseCallback) {
        state.modalCloseCallback();
      }
      return {
        ...state,
        modalType: null,
        modalSource: null,
        modalWho: null,
        modalCloseCallback: null,
        modalOpen: false,
      };
    case actionTypes.OPEN_SNACKBAR:
      return {
        ...state,
        snackBar: {
          open: true,
          type: action.payload.snackBarType,
          position: action.payload.snackBarPosition,
          message: action.payload.snackBarMessage,
          closeCallback: action.payload.snackBarCloseCallback,
        },
      };
    case actionTypes.CLOSE_SNACKBAR:
      if (state?.snackBar?.closeCallback) {
        state.snackBar.closeCallback();
      }
      return {
        ...state,
        snackBar: {
          open: false,
          type: null,
          position: null,
          message: null,
          closeCallback: null,
        },
      };
    case actionTypes.SET_ORDER_POPUPTIMER:
      return {
        ...state,
        orderPopupTimer: action.payload,
      };
    case actionTypes.CLOSE_PROMO_BAR:
      return {
        ...state,
        promoBar: {
          ...state.promoBar,
          show: false,
        },
      };
    case actionTypes.STOP_SEND_IMPRESSION:
      return {
        ...state,
        promoBar: {
          ...state.promoBar,
          sendImpression: false,
        },
      };

    default:
      return state;
  }
};

export default uiReducer;
