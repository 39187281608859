import React from 'react';
import { useTheme } from 'styled-components';

interface IIconHeart extends React.SVGProps<SVGSVGElement> {
  isFilled?: boolean;
}

const IconHeart: React.FC<IIconHeart> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m22.77,8.65c-.2-1.75-1.15-3.28-2.62-4.2-1.01-.64-2.21-.94-3.43-.87-1.4.09-2.75.71-3.81,1.76l-.59.59s-.07.05-.11.05h-.36s-.09,0-.12-.04l-.56-.56c-1.13-1.13-2.63-1.75-4.24-1.75-2.01,0-3.83.99-4.88,2.65-.64,1.02-.94,2.21-.87,3.43.08,1.4.71,2.75,1.76,3.81l1.62,1.62,5.33,5.23s.07.05.11.05h3.98s.08-.01.11-.04l5.35-5.24,1.58-1.58c1.3-1.29,1.92-3.08,1.71-4.91Z"
        fill={props.isFilled ? color1 : 'transparent'}
        stroke={color1}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconHeart;
