import gql from 'graphql-tag';

const CheckoutFragmentContext = gql`
  fragment CheckoutFragmentContext on Checkout {
    buyerIdentity {
      countryCode
    }
    id
    completedAt
    updatedAt
    currencyCode
    webUrl
    note
    email
    totalTax {
      amount
      currencyCode
    }
    subtotalPrice {
      amount
      currencyCode
    }
    totalPrice {
      amount
      currencyCode
    }
    lineItems(first: 250) {
      edges {
        node {
          id
          title
          unitPrice {
            amount
            currencyCode
          }
          variant {
            id
            title
            sku
            selectedOptions {
              name
              value
            }
            product {
              artisan_id: metafield(
                namespace: "custom_fields"
                key: "artisan_id"
              ) {
                value
              }
              id
              title
              options {
                name
                values
              }
              handle
              vendor
              productType
              metafield(key: "cart_xselling", namespace: "custom_fields") {
                value
                namespace
              }
              minimum_order_quantity: metafield(
                key: "minimum_order_quantity"
                namespace: "custom_fields"
              ) {
                value
              }
            }
            image {
              src
            }
            price {
              amount
              currencyCode
            }
            unitPrice {
              amount
              currencyCode
            }
          }
          quantity
        }
      }
    }
  }
`;

export default CheckoutFragmentContext;
