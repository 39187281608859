import { useEffect } from 'react';
import { getCookie, deleteCookie } from '@/utils/index';
import { useDispatch } from 'react-redux';
import { setCustomer, setCustomerEmpty } from '@/store/actions/user';
import { useRouter } from 'next/router';

function CustomerSession() {
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    (async () => {
      const mirtaAccessToken = getCookie('mirtaAccessToken');
      if (mirtaAccessToken) {
        dispatch(setCustomer(mirtaAccessToken, null, true, false));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const mirtaAccessToken = getCookie('mirtaAccessToken');
      if (!mirtaAccessToken) {
        dispatch(setCustomerEmpty());
      } else {
        if (
          //router.asPath.indexOf('/account/register') > -1 ||
          router.asPath.indexOf('/account/activate/') > -1 ||
          router.asPath.indexOf('/account/reset/') > -1
        ) {
          deleteCookie('mirtaAccessToken');
        }
      }
    })();
  }, [router.asPath]);

  return null;
}

CustomerSession.defaultProps = {
  details: false,
};

export default CustomerSession;
