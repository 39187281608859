import { IWishlistItem } from '@/types/wishlist';
import { actionTypes } from '../actions/actionTypes';
import {
  AddWishlistItemAction,
  RemoveWishlistItemAction,
  SetWishlistAction,
} from '../actions/wishlist';

type Action =
  | AddWishlistItemAction
  | RemoveWishlistItemAction
  | SetWishlistAction;
export interface WishlistState {
  wishlist: IWishlistItem[] | null;
  addListItem: IWishlistItem | null;
  removeListItem: IWishlistItem | null;
  totalWishlist: number;
}

const initialState: WishlistState = {
  wishlist: null,
  addListItem: null,
  removeListItem: null,
  totalWishlist: 0,
};

const wishlistReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case actionTypes.SET_ITEM_TO_WISHLIST:
      return {
        ...state,
        addListItem: action.payload,
      };
    case actionTypes.REMOVE_ITEM_TO_WISHLIST:
      return {
        ...state,
        removeListItem: action.payload,
      };
    case actionTypes.SET_WISHLIST:
      return {
        ...state,
        addListItem: null,
        removeListItem: null,
        wishlist: action.payload,
        totalWishlist: action.payload.length,
      };

    default:
      return state;
  }
};

export default wishlistReducer;
