import { ISectionProdCarouselCustom } from '@/interfaces/index';
import { getProductsArtisan } from '@/lib/api.product';
import {
  getProductBySku,
  getProductsByBrand,
  getProductsByCollection,
} from '@/lib/models/product';
import { Product } from '@/lib/models/types/product';
import { Image } from '@/types/catalog';
import {
  IArtisanPageFields,
  IParagraph,
  ISection,
} from '@/types/contentful/contentful';
import { EntryCollection } from 'contentful';
import { ICurrencyInfo } from '../types';
import { getIndexByObjHandle } from './Catalog';
import { isOk, mapResult, sequence, swallow } from './typings/result';

export const CARD_NUM_VARIANTS = 4;
export const CARD_VARIANT_THUMBNAIL_SIZE = 128;
export interface IProdCarousel {
  id: string;
  data: Product[];
}

export interface ICustomArtisanPageFields extends IArtisanPageFields {
  product_images?: Image[];
  totalProductsNumber?: number | undefined;
}

export interface IProductDataBrand {
  totalProductsNumber: number;
  product_images: Image[];
  markup: number | undefined;
  delivery_time: number | undefined;
}

export const ONE_VARIANT_QTY_ADDCART = 2;

export const getProductCarousel = async (
  fieldsContent: (ISection | IParagraph)[],
  artisan_slug?: string,
  handle?: string
) => {
  /* custom */

  let filteredProdResolved: IProdCarousel[] = [];
  const sectionsWithProductCarouselCustom = fieldsContent.reduce(
    (a: ISectionProdCarouselCustom[], d: any) => {
      if (d && d.fields && d.fields.organism) {
        if (
          d.fields?.organism?.sys?.contentType?.sys?.id === 'productCarousel'
        ) {
          if (d.fields?.organism?.fields?.type === 'custom') {
            a.push({
              id: d.fields?.organism.sys.id,
              sku: d.fields?.organism?.fields?.productSku,
            });
          }
        }
      }
      return a;
    },
    []
  );
  const productsCustomToBeResolved = sectionsWithProductCarouselCustom.map(
    async a => {
      const data = a.sku.map(getProductBySku);
      const res = await Promise.all(data);

      return {
        id: a.id,
        data: res.filter(isOk).map(result => result.result),
      };
    }
  );

  const productsCustomResolved = await Promise.all(productsCustomToBeResolved);

  filteredProdResolved = productsCustomResolved;

  /* Collection */

  const sectionsWithProductCarouselCollection = fieldsContent.reduce(
    (a: { id: string; limit: number; collection: string }[], d: any) => {
      if (d && d.fields && d.fields.organism) {
        if (
          d.fields?.organism?.sys?.contentType?.sys?.id === 'productCarousel'
        ) {
          if (d.fields?.organism?.fields?.type === 'collection') {
            a.push({
              id: d.fields?.organism.sys.id,
              collection: d.fields?.organism?.fields?.collection,
              limit: d.fields?.organism?.fields.limit,
            });
          }
        }
      }
      return a;
    },
    []
  );

  const resultCollection = sectionsWithProductCarouselCollection.map(
    async d => {
      const data = await getProductsByCollection(d.collection, d.limit);

      return mapResult(data, data => ({
        id: d.id,
        data: data.items,
      }));
    }
  );

  const filteredProdResolvedCollection: IProdCarousel[] =
    swallow(sequence(await Promise.all(resultCollection))) ?? [];

  /* Artisan */
  let filteredProdResolvedArtisan: IProdCarousel[] = [];
  const sectionsWithProductCarouselArtisan = fieldsContent.reduce(
    (a: { id: string; limit: number }[], d: any) => {
      if (d && d.fields && d.fields.organism) {
        if (
          d.fields?.organism?.sys?.contentType?.sys?.id === 'productCarousel'
        ) {
          if (d.fields?.organism?.fields?.type === 'artisan') {
            a.push({
              id: d.fields?.organism.sys.id,
              limit: d.fields?.organism?.fields.limit,
            });
          }
        }
      }
      return a;
    },
    []
  );

  if (artisan_slug) {
    const resultProducsArtisan = sectionsWithProductCarouselArtisan.map(
      async d => {
        const data = await getProductsByBrand(
          artisan_slug,
          d.limit ? d.limit : 16
        );

        return mapResult(data, data => {
          const filteredDataFromHandle = data.items.filter(
            d => d.handle !== handle
          );
          return {
            id: d.id,
            data: filteredDataFromHandle,
          };
        });
      }
    );

    filteredProdResolvedArtisan =
      swallow(sequence(await Promise.all(resultProducsArtisan))) ?? [];
  }

  const result = [
    ...filteredProdResolved,
    ...filteredProdResolvedCollection,
    ...filteredProdResolvedArtisan,
  ];

  return result;
};

export const getProductsArtisanFromEntries = async (
  entries: EntryCollection<ICustomArtisanPageFields>,
  limit: number
) => {
  let data = [];
  let productsResolved = [];
  if (entries && entries.items && entries.items.length > 0) {
    data = entries.items.map(async entry => {
      return await getProductsArtisan(entry.fields.slug, limit);
    });

    productsResolved = await Promise.all(data);

    if (productsResolved && productsResolved.length > 0) {
      productsResolved.map((pr, index) => {
        let dataArrayImages: Image[] = [];
        let totalItems: number | undefined = 0;
        if (pr && isOk(pr) && pr.result.data && pr.result.data.length > 0) {
          pr.result.data.map(product => {
            if (
              product &&
              product.images_object &&
              product.images_object.length > 0
            ) {
              dataArrayImages.push(product.images_object[0]);
            }
          });

          totalItems = pr.result.pagination?.total_items;
        }

        let customEntries: EntryCollection<ICustomArtisanPageFields> = entries;

        if (entries && entries.items && entries.items.length > 0) {
          customEntries.items[index].fields.product_images = dataArrayImages;
          customEntries.items[index].fields.totalProductsNumber = totalItems;
        }
      });
    }
    return entries as EntryCollection<ICustomArtisanPageFields>;
  } else {
    return [];
  }
};

// artisan component
export const getProductsBrandBySlug = async (
  slugs: string[],
  limit: number
) => {
  let data = [];
  let productsResolved = [];
  const res: IProductDataBrand[] = [];
  if (slugs && slugs.length > 0) {
    data = slugs.map(async slug => {
      return await getProductsArtisan(slug, limit);
    });

    productsResolved = await Promise.all(data);

    if (productsResolved && productsResolved.length > 0) {
      productsResolved.map(pr => {
        let dataArrayImages: Image[] = [];
        let totalItems: number | undefined = 0;

        if (pr && isOk(pr) && pr.result.data && pr.result.data.length > 0) {
          pr.result.data.map(product => {
            if (
              product &&
              product.images_object &&
              product.images_object.length > 0
            ) {
              dataArrayImages.push(product.images_object[0]);
            }
          });

          totalItems = pr.result.pagination?.total_items;
          res.push({
            totalProductsNumber: totalItems as number,
            product_images: dataArrayImages,
            markup: pr.result.metadata?.avg_markup,
            delivery_time: pr.result.metadata?.avg_delivery_time,
          });
        } else {
          res.push({
            totalProductsNumber: totalItems as number,
            product_images: dataArrayImages,
            markup: undefined,
            delivery_time: undefined,
          });
        }
      });
    }

    return res;
  } else {
    return [];
  }
};

export const soldOut = (
  currentlyNotInStock: boolean | undefined,
  quantityAvailable: number | null
): boolean => {
  return quantityAvailable === 0 && currentlyNotInStock === false;
};

export const getVariantQuantityMinValue = (
  productMinimumOrderQuantity: number | null,
  currentlyNotInStock: boolean,
  quantityAvailable: number,
  defaultQuantity: number
): number | null => {
  if (!productMinimumOrderQuantity || productMinimumOrderQuantity === 1) {
    // no moq
    if (currentlyNotInStock) {
      // you can sell anytime, quantityAvailable is not checked
      return defaultQuantity;
    } else {
      // you have to check quantityAvailable
      if (quantityAvailable >= defaultQuantity) {
        return defaultQuantity;
      }
      if (quantityAvailable === 1) {
        return 1;
      }
    }
  } else {
    // there is moq
    if (currentlyNotInStock) {
      // you can sell anytime, quantityAvailable is not checked
      return productMinimumOrderQuantity;
    } else if (quantityAvailable > 0) {
      return Math.min(quantityAvailable, defaultQuantity);
    }
  }
  return null; // it means out of stock
};

export const sortVariantAttributes = (
  variantAttributes: Product['productMetafields']['variantsAttributes'],
  selectedHandle: string
) => {
  if (variantAttributes && variantAttributes.length > 0) {
    const fromIndex = getIndexByObjHandle(
      selectedHandle.toLowerCase(),
      variantAttributes
    );
    if (fromIndex > 0) {
      const toIndex = 0;
      const element = variantAttributes.splice(fromIndex, 1)[0];
      variantAttributes.splice(toIndex, 0, element);
    }
  }

  return variantAttributes || [];
};

export const isVariantWithCustomColor = (handle: Product['handle']) => {
  if (handle) {
    return handle.toLowerCase().endsWith('custom'); //
  }
  return false;
};

export const formatPrice = (price: number, currency: ICurrencyInfo) => {
  const getFormatOptions = (price: number) => {
    return {
      style: 'currency',
      currency: currency?.isoCode,
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: price && price % 1 == 0 ? 0 : 2,
      maximumFractionDigits: price && price % 1 == 0 ? 0 : 2,
    };
  };

  let formatOptions: {
    style: string;
    currency: string | undefined;
    currencyDisplay?: string;
    minimumFractionDigits: number;
    maximumFractionDigits: number;
  } = getFormatOptions(price);

  let displayPrice = null;

  try {
    displayPrice = new Intl.NumberFormat(currency.locale, formatOptions).format(
      price
    );
  } catch (err) {
    formatOptions = (({
      //IIFE to copy formatOptions object removing the currencyDisplay property
      currencyDisplay,
      ...formatOptionsObj
    }) => formatOptionsObj)(formatOptions);

    //retry format without currencyDisplay option
    displayPrice = new Intl.NumberFormat(currency.locale, formatOptions).format(
      price
    );
  }

  return displayPrice;
};
