import React from 'react';
import { Typography } from '@/components/atoms';
import styled from 'styled-components';
import { TypoDataType } from '../Typography/Typography';

interface ITypoWithUnderline {
  children: React.ReactNode;
  datatype?: TypoDataType;
  size?: 'big';
}

interface ICustomTypo {
  size?: 'big';
}

const CustomTypography = styled(Typography)<ICustomTypo>`
  display: inline-block;
  padding: ${props => (props.size === 'big' ? '8px' : '4px')};
  background: ${props => props.theme.paletteDefault.green};
  color: ${props => props.theme.paletteDefault.lightBrown};
  border-radius: 80px;
  text-transform: uppercase;
`;

const TypoWithUnderline: React.FC<ITypoWithUnderline> = ({
  datatype = 'caption_2',
  children,
  size,
}) => {
  return (
    <CustomTypography component="span" datatype={datatype} size={size}>
      {children}
    </CustomTypography>
  );
};

export default TypoWithUnderline;
