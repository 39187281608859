import { actionTypes } from '../actions/actionTypes';

const initialState = {
  accessToken: null,
  isLogged: false,
  isApproved: false,
  loading: false,
  error: null,
  customer: null,
  loadingCustomer: true,
  errorCustomer: null,
  profiling: null,
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        isLogged: true,
        loading: false,
        error: null,
      };
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        accessToken: null,
        isLogged: false,
        loading: false,
        error: action.payload,
      };

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        accessToken: null,
        isLogged: false,
        customer: null,
      };
    case actionTypes.LOGOUT_ERROR:
      return {
        ...state,
        accessToken: null,
        isLogged: false,
        error: action.payload,
      };
    case actionTypes.CUSTOMER_START:
      return {
        ...state,
        loadingCustomer: true,
      };
    case actionTypes.CUSTOMER_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        isLogged: true,
        isApproved: action.payload.isApproved,
        customer: action.payload.customer,
        loadingCustomer: false,
      };
    case actionTypes.CUSTOMER_EMPTY:
      return {
        ...state,
        accessToken: null,
        isLogged: false,
        customer: null,
        loadingCustomer: false,
        loading: false,
      };
    case actionTypes.CUSTOMER_ERROR:
      return {
        ...state,
        accessToken: null,
        isLogged: false,
        customer: null,
        loadingCustomer: false,
        loading: false,
        errorCustomer: action.payload,
      };
    case actionTypes.LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SET_DEFAULT_ADDRESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          customer: {
            defaultAddress: action.payload,
          },
        },
      };
    case actionTypes.CUSTOMER_UPDATE_ACTIVE_CART:
      return {
        ...state,
        customer: {
          ...state.customer,
          activeCart: action.payload,
        },
      };
    case actionTypes.SET_USER_PROFILING:
      return {
        ...state,
        profiling: action.payload,
      };
    case actionTypes.CUSTOMER_UPDATE_ADDRESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          defaultAddress: {
            ...(state.customer as any)?.defaultAddress,
            ...action.payload,
          },
        },
      };
    case actionTypes.CUSTOMER_UPDATE_REGISTRATION_PREFERENCES:
      return {
        ...state,
        customer: {
          ...state.customer,
          registrationPreferences: {
            ...action.payload,
          },
        },
      };
    case actionTypes.CUSTOMER_UPDATE_SEGMENT_PRICE:
      return {
        ...state,
        customer: {
          ...state.customer,
          segmentPrice: {
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default userReducer;
