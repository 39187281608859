import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IProductCardTop } from './IProductCardTopCmp';
import { ImageOnHoverWrapper, Typography } from '@/components/atoms';
import { ProductCardVariantSwitcher } from '@/components/molecules';
import useBreakpoint from '@/hooks/useBreakpoint';

interface StyleProps {
  show?: boolean;
}

const MoreColors = styled.div<StyleProps>`
  max-width: 150px;
  padding: 2px;
  ${props => props.theme.breakpoints.up('mobileM')} {
    padding: 4px;
  }
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 6px 8px;
  }
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: ${props => props.theme.palette.common.white};
  display: ${props => (props?.show ? 'block' : 'none')};
  border: 0.5px solid #a6a6a6;
  border-radius: 8px 8px 0px 0px;
  text-align: center;
  letter-spacing: 1px;
`;

const Variants = styled.div<StyleProps>`
  width: 100%;
  bottom: 0;
  position: absolute;
  cursor: default;
  padding: 8px;
  border: 0.5px solid #a6a6a6;
  border-radius: 8px 8px 0px 0px;
  display: ${props => (props?.show ? 'block' : 'none')};
  background-color: ${props => props.theme.palette.common.white};
`;

const MoreColorsHeading = styled.div`
  margin-bottom: 8px;
`;

const ProductCardTop: React.FC<IProductCardTop> = ({
  selectedHandle,
  title,
  imgdefault,
  imghover,
  onChangeVariant,
  variantAttributes,
}) => {
  const { t } = useTranslation('product');
  const [showHoverContent, setShowHoverContent] = useState(false);
  const breakpoints = useBreakpoint();

  const showVariants = breakpoints === 'lg' || breakpoints === 'xl';

  const otherColorsNum =
    variantAttributes && variantAttributes.length > 0
      ? variantAttributes.length - 1
      : 0;

  const otherColorsMsg =
    otherColorsNum > 1
      ? t('product:colors.more-products', {
          count: otherColorsNum,
        })
      : t('product:colors.one-more-product');

  return (
    <>
      <ImageOnHoverWrapper
        imgdefault={imgdefault}
        imghover={imghover}
        title={title}
      />
      {variantAttributes &&
        variantAttributes.length > 0 &&
        onChangeVariant &&
        otherColorsNum > 0 && (
          <>
            <MoreColors
              show={!showHoverContent}
              onClick={e => {
                if (showVariants) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onMouseEnter={() => {
                if (showVariants && !showHoverContent) {
                  setShowHoverContent(true);
                }
              }}>
              <Typography datatype="caption_1">{otherColorsMsg}</Typography>
            </MoreColors>
            {showVariants && (
              <Variants
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                show={showHoverContent}
                onMouseLeave={() => {
                  if (showHoverContent) {
                    setShowHoverContent(false);
                  }
                }}>
                <MoreColorsHeading>
                  <Typography datatype="caption_1">{otherColorsMsg}</Typography>
                </MoreColorsHeading>
                <ProductCardVariantSwitcher
                  variantAttributes={variantAttributes}
                  onChangeVariant={onChangeVariant}
                  selectedHandle={selectedHandle}
                />
              </Variants>
            )}
          </>
        )}
    </>
  );
};

export default ProductCardTop;
