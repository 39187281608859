import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
} from '@apollo/client';
import fetch from 'isomorphic-unfetch';

let apolloInstance: ApolloClient<NormalizedCacheObject> | null = null;
export default function apolloClient(ctx: any) {
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.

  function createApolloClient() {
    return new ApolloClient({
      ssrMode: Boolean(ctx),
      link: new HttpLink({
        uri: `${process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_URL}/graphql.json`, // Server URL (must be absolute)
        credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
        headers: {
          'X-Shopify-Storefront-Access-Token':
            process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        },
        fetch,
      }),
      //cache: new InMemoryCache().restore(initialState),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
        },
      },
    });
  }

  if (!apolloInstance) {
    apolloInstance = createApolloClient();
  }

  return apolloInstance;
}
