import styled from 'styled-components';

interface IStyledProps {
  isCover?: boolean;
}

const ImageMultiply = styled.figure<IStyledProps>`
  padding-bottom: 0;
  display: block;
  mix-blend-mode: multiply;
  height: 100%;
  /* object-fit: contain; */

  img {
    max-width: 100%;
    margin: auto;
    /* height: 100%; */
    display: block;
    object-fit: ${props => (props.isCover ? 'cover' : 'contain')};
  }
  /* &::before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: ${props => props.theme.paletteDefault.grey1};
		mix-blend-mode: multiply;
		z-index: 1;
		padding-bottom: 0;
	} */
`;

export default ImageMultiply;
