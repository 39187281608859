import { actionTypes } from '../actions/actionTypes';

const initialState = {
  filters: false,
  products: false,
};

const loadingOverlayReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_LOADING: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default loadingOverlayReducer;
