import React, { createContext, useContext, useState } from 'react';

type ScriptState = 'idle' | 'loading' | 'loaded';
type AsyncScriptState = {
  getScriptState: (script: string) => ScriptState;
  setScriptState: (script: string, state: Exclude<ScriptState, 'idle'>) => void;
};

const AsyncScriptStateContext = createContext<AsyncScriptState>({
  getScriptState: () => 'idle',
  setScriptState: () => {},
});

export const ScriptStateProvider: React.FC = ({ children }) => {
  const [scripts, setScripts] = useState<
    Record<string, Exclude<ScriptState, 'idle'>>
  >({});

  const getScriptState = (script: string) => scripts[script] ?? 'idle';
  const setScriptState = (
    script: string,
    state: Exclude<ScriptState, 'idle'>
  ) => setScripts(prev => ({ ...prev, [script]: state }));

  return (
    <AsyncScriptStateContext.Provider
      value={{
        getScriptState,
        setScriptState,
      }}>
      {children}
    </AsyncScriptStateContext.Provider>
  );
};

export const useScriptState = () => useContext(AsyncScriptStateContext);
