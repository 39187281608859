import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { IPriceCmp } from './IPriceCmp';
import { useAppContext } from '@/context/state';
import { Typography } from '@/components/atoms';
import { formatPrice } from '@/utils/Product';
import { TypoDataType } from '@/components/atoms/Typography/Typography';

const RetailPrice = styled.span`
  opacity: 0.5;
`;

const PriceWrapper = styled.span`
  display: flex;
  gap: 16px;
`;

const Price: React.FC<IPriceCmp> = ({
  prices,
  currency,
  template = 'small',
}) => {
  const { t } = useTranslation('common');

  const isLogged = useSelector(
    (state: RootState) => state.userReducer.isLogged
  );

  const context = useAppContext();
  const settings = context?.settings;
  let displayPrice = null;
  let displayComparePrice = null;
  let selectedVariantPrice = prices.find(variantPrice => {
    return variantPrice.currencyCode === currency?.isoCode;
  });

  const price = selectedVariantPrice?.amount;

  if (price && currency) {
    displayPrice = formatPrice(price, currency);
  }

  let compareAtPrice = selectedVariantPrice?.fullAmount;

  if (compareAtPrice && currency) {
    displayComparePrice = formatPrice(compareAtPrice, currency);
  }

  const wholesalePrice = displayPrice ?? null;

  const retailPrice = displayComparePrice ? (
    <RetailPrice>
      {t('common:retail-price')} {displayComparePrice}
    </RetailPrice>
  ) : null;

  let priceToShow = null;
  if (isLogged) {
    if (retailPrice) {
      priceToShow = (
        <PriceWrapper>
          {wholesalePrice}
          {retailPrice}
        </PriceWrapper>
      );
    } else {
      priceToShow = wholesalePrice;
    }
  } else {
    priceToShow =
      retailPrice && settings?.RRPVisibleToAnonymousUsers === true
        ? retailPrice
        : null;
  }
  let datatype: TypoDataType;
  switch (template) {
    case 'medium':
      datatype = 'body_1';
      break;
    case 'large':
      datatype = 'subtitle_1';
      break;

    default:
      datatype = 'caption_1';
  }
  return <Typography datatype={datatype}>{priceToShow}</Typography>;
};

export default Price;
