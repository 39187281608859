import gql from 'graphql-tag';

export const productIdFutureFashion = gql`
  query productIdFutureFashion($handle: String!) {
    productByHandle(handle: $handle) {
      viewer_product_id: metafield(
        namespace: "custom_fields"
        key: "3d_viewer_product_id"
      ) {
        value
      }
    }
  }
`;
