import {
  customerQuery,
  customerDetailsQuery,
  customerAddressesQuery,
  customerOrdersQuery,
} from '@/graphQL/Shopify/queries/customerQuery';

import { customerByEmailQuery } from '@/graphQL/ShopifyAdmin/queries/customers';

import { customerByEmailQuery_customers } from '@/types/shopify/admin/customerByEmailQuery';

import {
  customerAccessTokenCreate,
  customerAccessTokenDelete,
  customerAddressDelete,
  customerAddressUpdate,
  customerAddressCreate,
  customerDefaultAddressUpdate,
  customerActivate,
  customerRecover,
  customerReset,
  customerUpdate,
} from '@/graphQL/Shopify/mutations/customerMutations';
import { fetcher, safeFetcher } from '@/lib/api';
import { customerQuery as CustomerQueryResult } from '@/types/shopify/customerQuery';
import { customerDetailsQuery as CustomerDetailsQueryResult } from '@/types/shopify/customerDetailsQuery';
import {
  getClientIpAddress,
  getCookieValue,
  NextRequest,
} from '@/utils/nextjs';
import apolloClientServerSide from './apolloClientServerSide';
import { customerAccessTokenCreate as customerAccessTokenCreateType } from '@/types/shopify/customerAccessTokenCreate';
import { err, flatMapResult, ok } from '@/utils/typings/result';
import { FetchError } from '@/utils/typings/errors';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

function customerLogin(email: string, password: string) {
  const input = {
    mutation: customerAccessTokenCreate,
    variables: {
      input: {
        email,
        password,
      },
    },
  };

  return safeFetcher<customerAccessTokenCreateType>(input);
}

export async function customerLoginApi(email: string, password: string) {
  let response = await customerLogin(email, password);

  return flatMapResult(response, result => {
    if (result.customerAccessTokenCreate?.customerAccessToken) {
      const accessObj = {
        accessToken:
          result.customerAccessTokenCreate.customerAccessToken.accessToken,
        expiresAt:
          result.customerAccessTokenCreate.customerAccessToken.expiresAt,
      };

      return ok(accessObj);
    }

    if (result.customerAccessTokenCreate?.customerUserErrors) {
      const error = result.customerAccessTokenCreate?.customerUserErrors?.[0];
      return err(
        new FetchError('Error logging in', {
          code: error.code!,
          message: error.message,
        })
      );
    }

    return err(new FetchError('Error logging in'));
  });
}

export async function getCustomerByToken<D extends boolean>(
  customerAccessToken: string,
  details: D
): Promise<D extends true ? CustomerDetailsQueryResult : CustomerQueryResult> {
  const key = {
    query: details ? customerDetailsQuery : customerQuery,
    variables: {
      customerAccessToken,
    },
  };

  let customer;

  try {
    customer = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }

  return customer;
}

export async function getCustomerOrders(customerAccessToken: string) {
  const key = {
    query: customerOrdersQuery,
    variables: {
      customerAccessToken,
    },
  };
  let customer;

  try {
    customer = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }
  return customer;
}

export async function customerAccessTokenDeleteApi(
  customerAccessToken: string
) {
  const key = {
    mutation: customerAccessTokenDelete,
    variables: {
      customerAccessToken,
    },
  };

  let result;

  try {
    result = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }
  return result;
}

export async function customerLogoutApi(customerAccessToken: string) {
  return new Promise((resolve, reject) => {
    customerAccessTokenDeleteApi(customerAccessToken).then(response => {
      if (response) {
        // if (
        // 	response.customerAccessTokenDelete.userErrors.length === 0
        // ) {
        // setCookie(
        // 	'mirtaAccessToken',
        // 	'',
        // 	'Thu, 01 Jan 1970 00:00:01 GMT'
        // );
        // setCookie(
        // 	'mirtaAccessToken',
        // 	'',
        // 	new Date('Thu, 01 Jan 1970 00:00:01 GMT').getTime()
        // );
        //cookieCutter.delete('mirtaAccessToken');
        // }
        resolve(response);
      }
    });
  });
}

export async function getCustomerAddresses(customerAccessToken: string) {
  const key = {
    query: customerAddressesQuery,
    variables: {
      customerAccessToken,
    },
  };

  let customer;

  try {
    customer = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }
  return customer;
}

export async function customerAddressDeleteApi(
  id: string,
  customerAccessToken: string
) {
  const key = {
    mutation: customerAddressDelete,
    variables: {
      id,
      customerAccessToken,
    },
  };

  let result;

  try {
    result = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }
  return result;
}

export async function customerAddressCreateApi(
  customerAccessToken: string,
  address: {}
) {
  const key = {
    mutation: customerAddressCreate,
    variables: {
      customerAccessToken,
      address,
    },
  };

  let result;

  try {
    result = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }
  return result;
}

export async function customerAddressUpdateApi(
  customerAccessToken: string,
  id: string,
  address: {}
) {
  const key = {
    mutation: customerAddressUpdate,
    variables: {
      customerAccessToken,
      id,
      address,
    },
  };

  let result;

  try {
    result = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }
  return result;
}

export async function customerDefaultAddressUpdateApi(
  customerAccessToken: string,
  addressId: string
) {
  const key = {
    mutation: customerDefaultAddressUpdate,
    variables: {
      customerAccessToken,
      addressId,
    },
  };

  let result;

  try {
    result = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }
  return result;
}

export async function customerDefaultAddressApi(
  customerAccessToken: string,
  addressId: string
) {
  return new Promise((resolve, reject) => {
    customerDefaultAddressUpdateApi(customerAccessToken, addressId).then(
      response => {
        if (response) {
          resolve(response);
        }
      }
    );
  });
}

export async function customerActiveCartUpdateApi(
  accessToken: string | null,
  cart: {
    id: string;
    currency: string;
  }
) {
  return fetch('/api/v1/customerActiveCartUpdate', {
    method: 'POST',
    body: JSON.stringify({
      accessToken,
      cart,
    }),
  });
}

export async function customerCreateAdmin(data: any) {
  const res = await fetch('/api/v1/customers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  let customer;

  if (res && res.body) {
    customer = await res.json();

    if (customer) {
      var event = new CustomEvent('klavioIdentifyInCookie', {
        detail: {
          email: customer.email,
        },
      });
      document.dispatchEvent(event);
    }
  }

  return customer;
}

export async function customerUpdateAdmin(data: any) {
  const res = await fetch('/api/v1/customersUpdate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  let customer;

  if (res) {
    customer = await res.json();
  }

  return customer;
}

export async function customerActivateApi(payload: {
  id: string;
  activationToken?: string;
  password?: string;
}) {
  const key = {
    mutation: customerActivate,
    variables: {
      id: 'gid://shopify/Customer/' + payload.id,
      input: {
        activationToken: payload.activationToken,
        password: payload.password,
      },
    },
  };

  let res;
  try {
    res = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }

  return res;
}

export async function customerRecoverPasswordApi(payload: { email: string }) {
  const key = {
    mutation: customerRecover,
    variables: {
      email: payload.email,
    },
  };

  let res;
  try {
    res = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }

  return res;
}

export async function customerResetPasswordApi(payload: {
  id: string;
  resetToken?: string;
  password?: string;
}) {
  const key = {
    mutation: customerReset,
    variables: {
      id: 'gid://shopify/Customer/' + payload.id,
      input: {
        resetToken: payload.resetToken,
        password: payload.password,
      },
    },
  };

  let res;
  try {
    res = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }

  return res;
}

export async function customerUpdateApi(payload: {
  customerAccessToken: string;
  customer: {
    firstName?: string;
    lastName?: string;
    acceptsMarketing?: boolean;
    password?: string;
  };
}) {
  const key = {
    mutation: customerUpdate,
    variables: {
      customerAccessToken: payload.customerAccessToken,
      customer: payload.customer,
    },
  };
  let res;

  try {
    res = await fetcher(JSON.stringify(key));
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }

  return res;
}

export async function retrieveCustomerInfo(customerId: string) {
  const res = await fetch(
    process.env.BASE_DOMAIN_URL + '/api/v1/customerInfo',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ customerId }),
    }
  );
  let customer;
  try {
    customer = await res.json();
  } catch (err) {
    return { error: { code: err.code, message: err.message } };
  }
  return customer;
}

export async function retrieveClientCustomerInfo(customerRequest: NextRequest) {
  const customerAccessToken = getCookieValue(
    customerRequest,
    'mirtaAccessToken'
  );

  if (!customerAccessToken) {
    return null;
  }

  const userIp = getClientIpAddress(customerRequest);

  const apolloClient = apolloClientServerSide(userIp);

  const query = {
    query: customerQuery,
    variables: {
      customerAccessToken,
    },
  };

  const result = await apolloClient.query<CustomerQueryResult>(query);

  return result.data.customer;
}

interface ICustomerByEmail {
  data: {
    customers: customerByEmailQuery_customers;
  };
  loading: boolean;
  networkStatus: number;
}

export async function getCustomerByEmailQuery(
  client: ApolloClient<NormalizedCacheObject>,
  email: string
) {
  const customerDetail = await client.query<ICustomerByEmail>({
    query: customerByEmailQuery,
    variables: {
      email,
    },
  });

  return customerDetail;
}

export async function fetchCustomerByEmail(
  email: string
): Promise<ICustomerByEmail> {
  return fetch('/api/v1/getCustomerByEmail?email=' + email).then(response =>
    response.json()
  );
}
