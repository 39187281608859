import { getPageName, getObjectfromArray } from '@/utils/index';
import {
  CustomEventDetailsMap,
  ProductCardPayloadV2,
} from '@/utils/Impressions';
import { ICurrencyInfo } from '../types';
import { onDev } from '@/utils/environment';
import { Product, ProductInCart } from './models/types/product';
import { CheckoutFragment_lineItems_edges_node } from '@/types/shopify/CheckoutFragment';

interface ProductTrackingPayload {
  item_brand: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_color: string;
  item_size: string;
  item_variant_id: string;
  item_product_id: string;
  item_id: string;
  item_list_name?: string;
  item_name: string;
  index?: number;
  price: string;
  item_variant: string;
  quantity?: number;
}

function isProductInCart(pet: Product | ProductInCart): pet is ProductInCart {
  return (pet as ProductInCart).quantity !== undefined;
}

const buildProductPayload = (
  product: Product | ProductInCart,
  currency: ICurrencyInfo | null
): ProductTrackingPayload => {
  const variant = product?.variant;
  const colorsArray = variant?.selectedOptions
    ? getObjectfromArray(variant.selectedOptions, 'color')
    : [];
  const price = product.prices
    ? product.prices?.find(
      price => price.currencyCode === currency?.isoCode || 'EUR'
    )?.amount
    : //@ts-ignore
    variant?.price; //TODO: fix type of variant
  let categories = null;
  if (product.productMetafields?.categories_paths && product.productMetafields?.categories_paths.length > 0) {
    product.productMetafields?.categories_paths?.[0].split('/');
  }

  const payload: ProductTrackingPayload = {
    item_name: product.title, //nome del prodotto
    item_id: product.productType, //sku di prodotto
    price: price?.toString() || '', //prezzo prodotto
    item_brand: product.vendor, //brand da cui il prodotto è realizzato
    item_category: categories?.[0] || '', //la categoria di appartenenza del prodotto(es. travel bags)
    item_category2: categories?.[1] || '', //la categoria di appartenenza del prodotto(es. travel bags)
    item_category3: categories?.[2] || '', //la categoria di appartenenza del prodotto(es. travel bags)
    item_variant: product.title + '-' + variant?.title,
    item_color:
      variant?.selectedOptions && variant.selectedOptions.length > 0
        ? colorsArray && colorsArray.length > 0
          ? colorsArray[0].value
          : ''
        : '', // colore del prodotto

    item_size:
      variant?.selectedOptions && variant.selectedOptions.length > 0
        ? getObjectfromArray(variant.selectedOptions, 'size').length > 0
          ? getObjectfromArray(variant.selectedOptions, 'size')[0].value
          : ''
        : '', //  taglia del prodotto
    item_variant_id: variant?.id
      ? variant.id.replace('gid://shopify/ProductVariant/', '')
      : '', // id della variante di prodotto
    item_product_id: product?.id
      ? product.id.replace('gid://shopify/Product/', '')
      : '', // product id
  };

  if (isProductInCart(product)) {
    payload.quantity = product.quantity;
  }

  return payload;
};

const buildCartPayload = (
  products: CheckoutFragment_lineItems_edges_node[],
  currency: ICurrencyInfo | null,
  qty: number
) => {
  const productsArray: ProductTrackingPayload[] = [];

  products.forEach(product => {
    const variant = {
      ...product.variant,
      prices: [
        {
          amount: product.variant?.price,
          currency,
        },
      ],
    };
    const item = {
      ...product,
      productType: variant?.product?.productType,
      id: variant.product?.id,
      vendor: variant.product?.vendor,
      quantity: Math.abs(qty),
      variant,
    };

    let payload = buildProductPayload(item as any, currency); //TODO:  it should be created our own CartLineItem type in order to get rid of this any

    productsArray.push(payload);
  });

  return productsArray;
};

function logTrackingFunction<T extends (...args: any[]) => any>(
  key: string,
  fn: T
): (...args: Parameters<T>) => ReturnType<T> {
  return (...args) => {
    const result = fn(...args);

    onDev(() => console.log(`${key} dataLayer`, result));

    return result;
  };
}

export const productClickEventV2 = logTrackingFunction(
  'productClickEvent',
  ({
    product,
    path,
    type,
    currency,
  }: CustomEventDetailsMap['productCardClickV2']) => {
    const productData = buildProductPayload(product, currency);

    return {
      item_list_id: product.id,
      item_list_name: product.title, //nome del prodotto
      items: [productData],
    };
  }
);

export const productDetailEvent = logTrackingFunction(
  'productDetailEvent',
  (product: Product, currency: ICurrencyInfo | null) => {
    const productData = buildProductPayload(product, currency);
    return {
      currency: currency?.isoCode,
      value: productData.price,
      items: [productData],
    };
  }
);

const getCartEventPayload = (
  products: CheckoutFragment_lineItems_edges_node[],
  qty: number,
  currency: ICurrencyInfo | null
) => {
  const items = buildCartPayload(products, currency, qty);
  const total = qty * parseFloat(items[0].price);

  return {
    currency: currency?.isoCode,
    value: total,
    items,
  };
};

export const addToCartEvent = logTrackingFunction(
  'addToCartEvent',
  (
    products: CheckoutFragment_lineItems_edges_node[],
    qty: number,
    currency: ICurrencyInfo | null
  ) => {
    return getCartEventPayload(products, qty, currency);
  }
);

export const removeFromCartEvent = logTrackingFunction(
  'removeFromCartEvent',
  (
    product: CheckoutFragment_lineItems_edges_node,
    qty: number,
    currency: ICurrencyInfo | null
  ) => {
    return getCartEventPayload([product], qty, currency);
  }
);

export const changeCartEvent = logTrackingFunction(
  'changeCartEvent',
  (
    product: CheckoutFragment_lineItems_edges_node,
    qty: number,
    currency: ICurrencyInfo | null
  ) => {
    const action = 'changeQuantity'; // qty === 1 ? 'add' : 'remove';

    return {
      category: 'cart',
      action,
      label: product.title, //nome del prodotto
      ecommerce: {
        currencyCode: currency,
        [action]: {
          products: buildCartPayload([product], currency, qty),
        },
      },
    };
  }
);

export const sendImpressionsEvent = logTrackingFunction(
  'sendImpressionsEvent',
  (impressions: ProductCardPayloadV2[], currency: ICurrencyInfo | null) => {
    const newImpressions = impressions.map(impression => {
      const payload = {
        ...buildProductPayload(impression.payload.product, currency),
        item_list_name: `${getPageName(impression.payload.page)} - ${impression.payload.type
          }`,
        index: impression.payload.positionindex,
      };
      return payload;
    });

    return {
      currency: currency?.isoCode,
      items: newImpressions,
    };
  }
);

export const pageViewEvent = logTrackingFunction(
  'pageViewEvent',
  (pagePath: string, pageTitle: string) => ({
    pagePath: pagePath,
    pageTitle: pageTitle,
  })
);

export const modalImpressionEvent = logTrackingFunction(
  'modalImpressionEvent',
  (modalSource: string) => ({
    // event: 'registrationPopupImpression',
    impressionSource: modalSource || '',
  })
);

export const originalLocationEvent = logTrackingFunction(
  'originalLocationEvent',
  (protocol: string, hostname: string, pathname: string, search: string) => ({
    originalLocation: protocol + '//' + hostname + pathname + search,
  })
);

export const registerEvent = logTrackingFunction(
  'registerEvent',
  (formData: {}, modalSource: string, customerId: string) => {
    let uuid = window.Mirta.create_UUID();
    let dataLayerObj: { [k: string]: string | number } | null = null;
    if (formData) {
      dataLayerObj = {
        is_customer: 1,
        impressionSource: modalSource,
        UUID: uuid,
        customerId: customerId,
        ...formData,
      };
    }
    return dataLayerObj;
  }
);

export const loginEvent = logTrackingFunction(
  'loginEvent',
  (
    email: string | null,
    isApproved: boolean,
    customerId: string | undefined,
    hasOrders: string,
    segmentPrice: number | null,
    hasRegistrationPreferences: number
  ) => ({
    UUID: customerId ?? null,
    email,
    is_customer: 1,
    visitorLoginState: 1,
    customer_approval: isApproved ? 1 : 0,
    hasOrders: hasOrders === 'true' ? 1 : 0,
    segmentPrice: segmentPrice,
    hasRegistrationPreferences: hasRegistrationPreferences,
  })
);

export const logoutEvent = logTrackingFunction(
  'logoutEvent',
  (email: string, customerId: string) => ({
    email,
    UUID: customerId,
    visitorLoginState: 0,
  })
);

export const addToWishlistEvent = logTrackingFunction(
  'addToWishlistEvent',
  (productName: string) => ({
    category: 'wishlist',
    action: 'add',
    label: productName,
  })
);

export const removeFromWishlistEvent = logTrackingFunction(
  'removeFromWishlistEvent',
  (productName: string) => ({
    category: 'wishlist',
    action: 'remove',
    label: productName,
  })
);

export const addToWishlistNotLoggedEvent = logTrackingFunction(
  'addToWishlistNotLoggedEvent',
  (productName: string) => ({
    category: 'Signup|wishlist',
    action: 'addNotLogged',
    label: productName,
  })
);

export const pushRegisterManualAddress = () => {
  window.Mirta.pushEventToAnalytics('click-manualAddress');
};

export const pushRegisterErrors = (
  errors: string | { [k: string]: string | string[] }
) => {
  window.Mirta.pushEventToAnalytics('accountRegistration-error', {
    errorType: errors,
  });
};
