import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { RootState } from '@/store/store';
import { Typography, LinkHandler } from '@/components/atoms';
import LockIcon from '@material-ui/icons/Lock';

const CustomLockIcon = styled(LockIcon)`
  font-size: 16px;
  transform: translate3d(-2px, 2px, 0px);
`;
const CustomTypography = styled(Typography)`
  line-height: normal;
`;

const WholesaleMsg: React.FC = ({}) => {
  const { t } = useTranslation('common');

  const isLogged = useSelector(
    (state: RootState) => state.userReducer.isLogged
  );
  if (!isLogged && process.env.NEXT_PUBLIC_B2B === '1') {
    return (
      <CustomTypography datatype={'caption_1'} className="smalltitle">
        <LinkHandler href={'/account/register'} source={'priceVisibility'}>
          <>
            <CustomLockIcon />
            {t('common:wholesale')}
          </>
        </LinkHandler>
      </CustomTypography>
    );
  } else {
    return <></>;
  }
};

export default WholesaleMsg;
