/**
 * Isomorphic base64 encoder
 */
export function encodeBase64(value: string): string {
  if (typeof window !== 'undefined' && Boolean(window.btoa)) {
    return window.btoa(value);
  }

  return Buffer.from(value).toString('base64');
}
