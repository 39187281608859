import {
  Pagination as CatalogPagination,
  Facet as CatalogFacet,
  FacetValue as CatalogFacetValue,
  Metadata as CatalogMetadata,
  ProductVariant,
  ProductVariantWithDescription,
  ProductVariantWithVariants,
  SearchResultResponse,
} from '@/types/catalog';
import {
  Product,
  ProductWithDescription,
  ProductWithVariants,
  Pagination,
  FacetValue,
  Facet,
  ProductSearch,
  Metadata,
} from '../types/product';

const mapSearchProduct = (product: ProductVariant): Product => {
  //@ts-ignore
  return {
    title: product.name,
    productType: product.product_type ?? '',
    id: product.product_id,
    sku: product.sku,
    handle: product.handle ?? '',
    vendor: product.artisan ?? '',
    availability: product.availability,
    deliveryTime: product.delivery_time,
    options: product.options ?? [],
    images: product.images_object ?? [],
    prices:
      product.prices?.map(price => ({
        amount: price.amount,
        fullAmount: price.full_amount,
        currencyCode: price.currency,
      })) ?? [],
    variant: {
      id: product.variant_id ?? '',
      title: product.variant_title ?? '',
      selectedOptions:
        product.options?.map(option => ({
          name: option.name,
          value: option.values[0],
        })) ?? [],
    },
    brand: {
      id: product.artisan_object.id,
      name: product.artisan_object.label,
    },
    productMetafields: {
      materialDescription: product.material_description,
      colorDescription: product.color_description,
      category: product.category,
      subCategory: product.sub_category,
      userMessage: product.user_message,
      variantsAttributes: product.variants_attributes,
      categories_paths: product.categories_paths,
    },
    websiteMetafields: {
      title: product.meta.title,
      description: product.meta.description,
      canonicalRelative: product.meta.canonical_relative,
    },
    preorder: product.preorder,
    userMessage: product.user_message,
    minimumOrderQuantity: product.b2b_minimum_order_quantity,
    shortDescription: product.short_description,
    artisanProductDetail: product.artisan_product_detail,
    sales_channels: product.sales_channels ?? [],
    sold_out: product.sold_out,
  };
};
export const mapProductWithDescription = (
  product: ProductVariantWithDescription
): ProductWithDescription => {
  return {
    ...mapSearchProduct(product),
    xsellingProductpage: product.xselling_productpage,
    extraInfo: product.extra_info,
    artisan_registry_id: product.artisan_object.registry_id,
    productDescription: {
      description: product.product_description?.description,
      details: product.product_description?.details,
      shippingAndReturns: product.product_description?.shipping_and_returns,
      size: product.product_description?.size,
    },
  };
};
export const mapProductWithVariants = (
  product: ProductVariantWithVariants
): ProductWithVariants => {
  return {
    ...mapProductWithDescription(product),
    variants: product.variants.map(variant => ({
      sku: variant.sku,
      variantId: variant.variant_id,
      color: variant.color,
      size: variant.size,
      embroidery: variant.embroidery,
      markup: variant.markup,
      prices: variant.prices?.map(price => ({
        currencyCode: price.currency,
        fullAmount: price.full_amount,
        amount: price.amount,
      })),
      image: variant.image_object,
      options: variant.variant_options,
    })),
  };
};
const mapPagination = (pagination: CatalogPagination): Pagination => ({
  totalItems: pagination.total_items ?? 0,
  currentPage: pagination.current_page ?? 0,
  itemsPerPage: pagination.items_per_page ?? 0,
  hasNextPage: pagination.has_next_page ?? false,
});
const mapFacetValue = (facet: CatalogFacetValue): FacetValue => ({
  id: facet.id,
  displayValue: facet.display_value,
  queryValue: facet.query_value,
  numItems: facet.num_items,
  values: facet.values?.map(mapFacetValue),
});

const mapFacet = (facet: CatalogFacet): Facet => ({
  id: facet.id,
  type: facet.type,
  displayValue: facet.display_value,
  queryParam: facet.query_param,
  values: facet.values.map(mapFacetValue),
});

const mapMetadata = (metadata: CatalogMetadata): Metadata => ({
  avgMarkup: metadata.avg_markup,
  avgDeliveryTime: metadata.avg_delivery_time,
});

export const mapSearchResult = (
  searchResult: SearchResultResponse
): ProductSearch => ({
  items: searchResult.data.map(mapSearchProduct),
  pagination: mapPagination(searchResult.pagination),
  facets: searchResult.facets.map(mapFacet),
  metadata: mapMetadata(searchResult.metadata),
});
