import React, { useState } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import Link from 'next/link';
import { IProductCardVariantSwitcherCmp } from './IProductCardVariantSwitcherCmp';
import { ImageMultiply, Tooltip } from '@/components/atoms';
import {
  CARD_NUM_VARIANTS,
  CARD_VARIANT_THUMBNAIL_SIZE,
  isVariantWithCustomColor,
} from '@/utils/Product';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'next-i18next';

type StyleProps = {
  highlighted?: boolean;
};

const Container = styled.div`
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const VariantThumb = styled.div`
  cursor: pointer;
  width: 25%;
  position: relative;
  padding-top: calc((5 / 4) * 25%);
`;

const VariantImageWrapper = styled(VariantThumb)<StyleProps>`
  border: ${props =>
    props.highlighted
      ? `1.5px solid ${props.theme.paletteDefault.primary.main} `
      : 'none'};
`;

const OtherVariantsPlaceholderIcon = styled(AddIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CustomColorIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ProductCardVariantSwitcher: React.FC<IProductCardVariantSwitcherCmp> = ({
  selectedHandle,
  onChangeVariant,
  variantAttributes,
}) => {
  const { t } = useTranslation(['common', 'product']);
  const [shownHandle, setShownHandle] = useState(selectedHandle);

  if (selectedHandle !== shownHandle) {
    setShownHandle(selectedHandle);
  }

  const isOtherVariantsPlaceholder =
    variantAttributes && variantAttributes.length > CARD_NUM_VARIANTS;
  const numvariantsToShow =
    variantAttributes && variantAttributes.length > 0
      ? isOtherVariantsPlaceholder
        ? CARD_NUM_VARIANTS - 1
        : CARD_NUM_VARIANTS
      : 0;

  const changeVariant = (handle: string) => {
    if (handle) {
      onChangeVariant(handle);
      setShownHandle(handle);
    }
  };

  const variantImages =
    variantAttributes && variantAttributes.length > 0
      ? variantAttributes.slice(0, numvariantsToShow).map((va, index) => {
          const resizedImgUrl =
            va.image_url + `&width=${CARD_VARIANT_THUMBNAIL_SIZE}`;
          const isCustomColor = isVariantWithCustomColor(va.handle);
          return (
            <Tooltip
              key={`thumb-${va.handle}-${index}`}
              title={
                isCustomColor ? <>{t('common:custom')}</> : <>{va.color}</>
              }
              placement="bottom">
              <VariantImageWrapper highlighted={selectedHandle === va.handle}>
                <ImageMultiply
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    changeVariant(va.handle);
                  }}>
                  {isCustomColor ? (
                    <CustomColorIcon>
                      <Image
                        alt="product variant thumbnail"
                        src={'/asset/customColorThumbnail.svg'}
                        width={40}
                        height={40}
                        unoptimized={true}
                      />
                    </CustomColorIcon>
                  ) : (
                    <Image
                      alt="product variant thumbnail"
                      src={resizedImgUrl}
                      layout="fill"
                      unoptimized={true}
                    />
                  )}
                </ImageMultiply>
              </VariantImageWrapper>
            </Tooltip>
          );
        })
      : [];

  if (isOtherVariantsPlaceholder) {
    variantImages.push(
      <Tooltip
        key={`thumb-others`}
        title={<>{t('product:colors.other-colors')}</>}
        placement="bottom">
        <VariantThumb>
          <Link
            href={{
              pathname: '/products/[handle]',
              query: { handle: selectedHandle },
            }}
            passHref>
            <a>
              <ImageMultiply>
                <OtherVariantsPlaceholderIcon />
              </ImageMultiply>
            </a>
          </Link>
        </VariantThumb>
      </Tooltip>
    );
  }

  return (
    <Container>{variantImages}</Container> //onClick={e => e.preventDefault()}
  );
};

export default ProductCardVariantSwitcher;
