import { actionTypes } from './actionTypes';
import { IColorsObj } from '@/utils/Colors';
export interface SetColorsAction {
  type: actionTypes.SET_COLORS;
  payload: IColorsObj;
}

export const setColors = (colors: IColorsObj): SetColorsAction => {
  return {
    type: actionTypes.SET_COLORS,
    payload: colors,
  };
};
