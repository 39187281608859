import { createGlobalStyle } from 'styled-components';
import { ISettingsTheme } from './types';
import { Theme } from '@material-ui/core';

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'FuturaPOTExtraBold_19035';
    src: url('/fonts/futuraExtraBold/FuturaPOTExtraBold_19035.woff2') format('woff2');
    font-display: optional;
         
}
@font-face {
    font-family: 'nb_international_promedium';
    src: url('/fonts/nbMedium/nbinternationalpromed-webfont.woff2') format('woff2');
    font-display: optional;         
    font-weight: 500;
    font-style: normal;
}





    * {
        margin:0;
        padding:0;
        box-sizing: border-box;
    }
   
    main {
        /* min-height: 100vh; */
    }
    img {
        max-width: 100%;
        height: auto;
    }
    a {text-decoration: none; color: inherit;}
    ul {list-style-type: none; margin:0; padding:0}

    html {
      font-family:  ${props => props.theme.typog.fontFamily};
      height: 100%;
      color: ${props => props.theme.typog.color};
      font-size: 16px;
      letter-spacing: ${props => props.theme.typog.letterSpacing};
    }

    .titlebig {
      font-family:  ${props => props.theme.fonts.titlebig.fontFamily};
      text-transform: lowercase !important;
    }
    .bigtitle {
        font-family:  ${props => props.theme.fonts.bigtitle.fontFamily};
        font-weight: ${props => props.theme.fonts.bigtitle.fontWeight};
        letter-spacing: ${props => props.theme.fonts.bigtitle.letterSpacing};
    }
    .smalltitle {
      font-family:  ${props => props.theme.fonts.smalltitle.fontFamily};
        font-weight: ${props => props.theme.fonts.smalltitle.fontWeight};
        letter-spacing: ${props => props.theme.fonts.smalltitle.letterSpacing};
    }

    .resetFontFamily {
      font-family:  ${props => props.theme.typog.fontFamily};
    }

  body {
    height: 100%;
  }

  b {
    font-weight: 700;
  }

/* Works on Firefox */
.customWrapper {
  scrollbar-width: thin;
  scrollbar-color: #191919 #E9E5D9;
  /* border-radius: 10px; */
}

/* Works on Chrome, Edge, and Safari */
.customWrapper::-webkit-scrollbar {
  width: 6px;
}

/* Track */
/* Works on Chrome, Edge, and Safari */
.customWrapper::-webkit-scrollbar-track {
  background:#E9E5D9;
  /* border-radius: 10px; */
}

/* Handle */
/* Works on Chrome, Edge, and Safari */
.customWrapper::-webkit-scrollbar-thumb {
  background: #191919;
  /* border-radius: 10px; */
}

/* Handle on hover */
/* Works on Chrome, Edge, and Safari */
.customWrapper::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 121, 121);
}

body.annBar .drawerContent {
  padding-top: 34px;
}

#nprogress .bar {
  background: ${props => props.theme.progressBar.color};
  position: fixed;
  z-index: 1000000004;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

.MuiFormLabel-root {
  line-height: normal;
}


.MuiPaper-root {
  color: ${props => props.theme.typog.color}; 
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


`;

export default GlobalStyle;

declare module 'styled-components' {
  export interface DefaultTheme extends Theme, ISettingsTheme {}
}
