require('intersection-observer');
import { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import theme from '../theme/theme';
import GlobalStyle from '../theme/globalStyle';
import { Provider } from 'react-redux';
import { useStore } from '../store/store';
import CustomerSession from '@/utils/CustomerSession';
import Colors from '@/utils/Colors';
import Currency from '@/utils/Components/Currency/Currency';
import Impressions from '@/utils/Impressions';
import Klaviyo from '@/utils/Klaviyo';
import Wishlist from '@/utils/Wishlist';
import { datadogRum } from '@datadog/browser-rum';
import { AppWrapper } from '../context/state';
import { appWithTranslation } from 'next-i18next';
import { ProgressBar } from '@/components/molecules';
import CookieConsent from 'react-cookie-consent';
import { ScriptStateProvider } from '@/context/scritpsState';
import Cart from '@/utils/Components/Cart';
import { Notification } from '@/utils/NotificationMap';
import { ISettingsTheme } from '@/theme/types';

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('../mocks');
}

import { DefaultTheme } from 'styled-components';
import Profiling from '@/utils/Components/Profiling';

declare global {
  interface Window {
    Mirta: any;
    gtag: any;
    implementManyExperiments: any;
    dataLayer: any;
  }
}

let datadogRUMInitialized = false;

function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode)
      jssStyles.parentNode.removeChild(jssStyles);

    const popState = () => {
      if (window.history.state.url.indexOf('/loyalty') > -1) {
        window.location.reload();
      }
    };

    window.addEventListener('popstate', popState);

    return () => {
      window.removeEventListener('popstate', popState);
    };
  }, []);

  const store = useStore(pageProps.initialReduxState);
  if (
    datadogRUMInitialized === false &&
    process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local'
  ) {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID!,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN!,
      site: 'datadoghq.eu',
      service: 'storefront',
      env: process.env.NEXT_PUBLIC_ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '1.0.0',
      sampleRate: parseInt(process.env.NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE!),
      replaySampleRate: parseInt(
        process.env.NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE!
      ),
      trackInteractions: true,
    });
    datadogRUMInitialized = true;
    datadogRum.startSessionReplayRecording();
  }

  type NewTheme = DefaultTheme & ISettingsTheme;

  const newtheme: NewTheme = pageProps?.settingsTheme &&
    theme && { ...pageProps?.settingsTheme, ...theme };

  return (
    <>
      {pageProps &&
      Object.keys(pageProps).length > 0 &&
      pageProps?.settingsTheme ? (
        <Provider store={store}>
          <CookieConsent
            location="bottom"
            buttonText="ACCEPT"
            style={{
              background: '#000000',
              text: '#ffffff',
              flex: 'display',
              justifyContent: 'flex-end',
            }}
            buttonStyle={{
              color: '#000000',
              backgroundColor: '#ffffff',
              fontSize: '14px',
              fontWeight: 500,
              letterSpacing: '1.25px',
              borderRadius: '4px',
            }}
            expires={150}>
            We use cookies to provide and improve our services. By continuing to
            use our site, you consent to our use of cookies.{' '}
            <a
              style={{
                textDecoration: 'underline',
                color: '#ffffff',
              }}
              href="/m/privacy-policy"
              target="_blank"
              rel="noopener noreferrer">
              Learn more
            </a>
          </CookieConsent>
          <ProgressBar />
          <Currency currencyCode={pageProps.currency} />
          <Cart />
          <Colors colors={pageProps.colors} locale={pageProps.locale} />
          <Impressions
            pageTitle={pageProps.data && pageProps?.data?.layout?.meta?.title}
          />
          <Klaviyo />
          <CustomerSession />
          <Wishlist />
          <Notification />
          <Profiling />
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={newtheme}>
                <GlobalStyle />
                <ScriptStateProvider>
                  <AppWrapper>
                    <Component {...pageProps} />
                  </AppWrapper>
                </ScriptStateProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </Provider>
      ) : null}
    </>
  );
}

export default appWithTranslation(App);
