import { actionTypes } from '../actions/actionTypes';

const initialState = {
  impressions: [],
  carouselMoved: false,
};

const impressionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_IMPRESSION:
      return {
        ...state,
        impressions: state.impressions.concat(action.payload),
      };
    case actionTypes.SLICE_IMPRESSION:
      return {
        ...state,
        impressions: [],
      };
    case actionTypes.CAROUSEL_MOVED:
      return {
        ...state,
        carouselMoved: true,
      };
    default:
      return state;
  }
};

export default impressionsReducer;
