import {
  checkoutQuery,
  checkoutReachQuery,
  checkoutShippingRatesQuery,
} from '@/graphQL/Shopify/queries/checkoutQuery';
import {
  checkoutCreate,
  checkoutLineItemsAdd,
  checkoutLineItemsRemove,
  checkoutLineItemsUpdate,
  checkoutNoteUpdate,
  checkoutShippingAddressUpdate,
  checkoutShippingLineUpdate,
} from '@/graphQL/Shopify/mutations/checkoutMutations';
import { ICartItem, ICurrencyInfo } from '@/types/index';
import { ILineItemMutation } from '@/types/index';
import { fetcher } from '@/lib/api';
import { supports_local_storage } from '@/utils/index';
import {
  CheckoutFragment,
  CheckoutFragment_lineItems_edges,
} from '@/types/shopify/CheckoutFragment';
import { customerActiveCartUpdateApi } from '@/lib/api.customer';
import { store } from '@/store/store';
import { updateActiveCart } from '@/store/actions/user';
import { CheckoutReachFragment } from '@/types/shopify/CheckoutReachFragment';
import { getAuthorizationToken } from './api.authorization';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { getDraftOrder as DraftOrder } from '@/types/shopify/admin/getDraftOrder';
import {
  getDraftOrderLineItemsQuery,
  getDraftOrderQuery,
} from '@/graphQL/ShopifyAdmin/queries/draftOrderQueries';
import {
  getDraftOrderLineItem as DraftOrderWithLineItems,
  getDraftOrderLineItem_draftOrder_lineItems_edges_node as DraftOrderLineItem,
} from '@/types/shopify/admin/getDraftOrderLineItem';
import {
  ICheckoutDraftOrder,
  InitReachSessionRequest,
  InitReachSessionResponse,
} from '@/types/checkout';
import { MailingAddressInput } from '@/types/shopify/globalTypes';
import { checkoutShippingRatesQuery as checkoutShippingRatesQueryType } from '@/types/shopify/checkoutShippingRatesQuery';
import { checkoutLineItemsAdd as checkoutLineItemsAddType } from '@/types/shopify/checkoutLineItemsAdd';
import {
  selectPaymentProviderRequest,
  SelectPaymentProviderResponse,
} from '@/types/checkout/selectPaymentProvider';
import { encodeBase64 } from '@/utils/strings';

export interface ICheckout {
  node: CheckoutFragment;
}

export async function resetCartApi(accessToken: string) {
  if (supports_local_storage()) {
    window.localStorage.removeItem('mirtaCheckout');
  }

  return fetch('/api/v1/customerActiveCartUpdate', {
    method: 'DELETE',
    body: JSON.stringify({
      accessToken,
    }),
  });
}

interface IActiveCart {
  id: string;
  currency: string;
}

// TODO: This function can be greatly simplified when the local storage is not used anymore.
export async function getLastCart(): Promise<IActiveCart | null> {
  const updateCartInCustomerCustomField = async (cart: {
    id: string;
    currency: string;
  }) => {
    window.localStorage.removeItem('mirtaCheckout');
    const customerAccessToken = store.getState().userReducer.accessToken;
    await customerActiveCartUpdateApi(customerAccessToken, cart);
  };

  const customFieldCart = store.getState().userReducer.customer.activeCart;
  const customer = store?.getState().userReducer.customer;

  if (supports_local_storage()) {
    //retrieve cart id from 'mirtaCheckout' local storage
    const mirtaCheckoutJson =
      window.localStorage.getItem('mirtaCheckout') || '';
    const mirtaCheckout = mirtaCheckoutJson
      ? JSON.parse(mirtaCheckoutJson)
      : null;

    if (mirtaCheckout && customFieldCart) {
      //there are 2 carts , one in customer custom field and one in local storage

      //retrieve both carts content (custom field cart and local storage cart )
      const [activeCheckout, localStorageCheckout] = await Promise.all([
        loadCartApi(customFieldCart.currency, customer, customFieldCart.id).catch(() =>
          console.log('error on loading custom field cart')
        ),
        loadCartApi(mirtaCheckout.currency, customer, mirtaCheckout.id).catch(() =>
          console.log('error on loading local storage cart')
        ),
      ]);
      if (activeCheckout?.checkout && localStorageCheckout?.checkout) {
        // find out which is the most recent cart
        const newCart =
          activeCheckout.checkout.updatedAt >
            localStorageCheckout.checkout.updatedAt
            ? mirtaCheckout
            : customFieldCart;

        // set most recent cart id in customer custom field
        await updateCartInCustomerCustomField(newCart);
        // set most recent cart id in redux customer data
        store.dispatch(updateActiveCart(newCart));

        return newCart;
      }
    } else if (mirtaCheckout) {
      // there is only local storage cart...

      // so i set this cart also in customer custom field  ...
      await updateCartInCustomerCustomField(mirtaCheckout);
      // and in redux customer data
      store.dispatch(updateActiveCart(mirtaCheckout));

      return mirtaCheckout;
    }
  }
  return customFieldCart ?? null;
}

export async function updateCartNoteApi(
  note: string,
  customer: any,
): Promise<{
  checkout: CheckoutFragment | null;
  status: string;
  error: string;
}> {
  const mirtaCheckout = await getLastCart();
  if (!mirtaCheckout) {
    return { checkout: null, status: 'error', error: '' };
  }

  const input = {
    mutation: checkoutNoteUpdate,
    variables: {
      checkoutId: mirtaCheckout.id,
      input: {
        country: customer.defaultAddress.countryCodeV2,
        note: note,
      },
    },
  };

  const result = await fetcher(JSON.stringify(input));

  if (result && result.checkoutAttributesUpdateV2) {
    return {
      checkout: result.checkoutAttributesUpdateV2.checkout,
      status: 'success',
      error: '',
    };
  } else {
    return {
      checkout: null,
      status: 'failed',
      error: result.errors || [],
    };
  }
}

export async function updateCartApi(
  lineItem: ICartItem,
  qty: number,
  currency: ICurrencyInfo,
  customer: any,
): Promise<{
  checkout: CheckoutFragment | null;
  status: string;
  error: string;
}> {
  const mirtaCheckout = await getLastCart();
  if (!mirtaCheckout) {
    return { checkout: null, status: 'error', error: '' };
  }

  const input = {
    mutation: checkoutLineItemsUpdate,
    variables: {
      checkoutId: mirtaCheckout.id,
      country: customer.defaultAddress.countryCodeV2 || currency.isoCountryCode,
      lineItems: [
        {
          id: lineItem?.id,
          variantId: lineItem?.variant?.id,
          quantity: qty,
        },
      ],
    },
  };
  const result = await fetcher(JSON.stringify(input));

  if (result && result.checkoutLineItemsUpdate) {
    return {
      checkout: result.checkoutLineItemsUpdate.checkout,
      status: 'success',
      error: '',
    };
  } else {
    return {
      checkout: null,
      status: 'failed',
      error: result.errors || [],
    };
  }
}

interface RemoveFromCart {
  checkout: CheckoutFragment | null;
  status: string;
  error: '' | [];
}

export async function removeFromCartApi(lineItem: ICartItem[], customer: any): Promise<RemoveFromCart> {
  const mirtaCheckout = await getLastCart();
  if (!mirtaCheckout) {
    return { checkout: null, status: 'error', error: '' };
  }

  const input = {
    mutation: checkoutLineItemsRemove,
    variables: {
      checkoutId: mirtaCheckout.id,
      lineItemIds: lineItem.map(item => item.id),
      country: customer.defaultAddress.countryCodeV2,
    },
  };

  const result = await fetcher(JSON.stringify(input));

  if (result && result.checkoutLineItemsRemove) {
    return {
      checkout: result.checkoutLineItemsRemove.checkout,
      status: 'success',
      error: '',
    };
  } else {
    return {
      checkout: null,
      status: 'failed',
      error: result.errors || [],
    };
  }
}

export async function addToCartApi(
  lineItem: ILineItemMutation,
  currency: ICurrencyInfo,
  email: string,
  customerAccessToken: string | null,
  customer: any
): Promise<{
  checkout: CheckoutFragment | null;
  status: string;
  error: string;
}> {
  const cart = await getLastCart();

  if (!cart) {
    return await createCartApi(
      [lineItem],
      null,
      currency,
      email,
      customerAccessToken,
      customer
    );
  }

  const input = {
    mutation: checkoutLineItemsAdd,
    variables: {
      country: customer.defaultAddress.countryCodeV2,
      checkoutId: cart.id,
      lineItems: lineItem,
    },
  };

  const result: checkoutLineItemsAddType = await fetcher(JSON.stringify(input));

  if (result && result.checkoutLineItemsAdd) {
    return {
      checkout: result.checkoutLineItemsAdd.checkout,
      status: 'success',
      error: '',
    };
  } else {
    return {
      checkout: null,
      status: 'failed',
      error: result?.checkoutLineItemsAdd?.userErrors?.[0].message ?? '',
    };
  }
}

export async function loadCartApi(
  currency: ICurrencyInfo,
  customer: any,
  cartId: string | null,
): Promise<{
  checkout: CheckoutFragment | null;
  status: string;
  error: string;
}> {
  if (!cartId) {
    return { checkout: null, status: 'success', error: '' };
  }

  const input = {
    query: checkoutQuery,
    variables: {
      id: cartId,
      country: customer.defaultAddress.countryCodeV2 || currency.isoCountryCode,
    },
  };

  const checkout: ICheckout = await fetcher(JSON.stringify(input));

  if (checkout.node) {
    return {
      checkout: checkout.node,
      status: 'success',
      error: '',
    };
  } else {
    return {
      checkout: null,
      status: 'failed',
      error: 'failed to load cart',
    };
  }
}

export async function createCartApi(
  lineItems: ILineItemMutation[],
  note: string | null,
  currency: ICurrencyInfo,
  customerEmail: string | null,
  customerAccessToken: string | null,
  customer: any,
  includeAddress: boolean = true,

): Promise<{
  checkout: CheckoutFragment | null;
  status: string;
  error: string;
}> {
  const input = {
    mutation: checkoutCreate,
    variables: {
      input: {
        lineItems: lineItems,
        note,
        email: customerEmail,
        buyerIdentity: {
          countryCode: customer.defaultAddress.countryCodeV2 || currency.isoCountryCode
        },
        shippingAddress: includeAddress ? {
          address1: customer.defaultAddress.address1,
          address2: customer.defaultAddress.address2,
          city: customer.defaultAddress.city,
          company: customer.defaultAddress.company,
          country: customer.defaultAddress.country,
          firstName: customer.defaultAddress.firstName,
          lastName: customer.defaultAddress.lastName,
          phone: customer.defaultAddress.phone,
          zip: customer.defaultAddress.zip,
          province: customer.defaultAddress.province
        } : null,
      },
      country: customer.defaultAddress.countryCodeV2 || currency.isoCountryCode,
    },
  };

  const result = await fetcher(JSON.stringify(input));

  if (result && result.checkoutCreate && result.checkoutCreate.checkout) {
    await customerActiveCartUpdateApi(customerAccessToken, {
      id: result.checkoutCreate.checkout.id,
      currency: currency.isoCode,
    });
    return {
      checkout: result.checkoutCreate.checkout,
      status: 'success',
      error: '',
    };
  } else {
    return {
      checkout: null,
      status: 'failed',
      error: result.checkoutCreate?.checkoutUserErrors || [],
    };
  }
}

export async function cloneCartApi(
  currency: ICurrencyInfo,
  cartId: string | null,
  customerAccessToken: string | null,
  customer: any
): Promise<{
  checkout: CheckoutFragment | null;
  status: string;
  error: string;
}> {
  const res = await loadCartApi(currency, customer, cartId);

  if (res.status.toLowerCase() === 'success') {
    let lineItems: ILineItemMutation[] = [];
    if (res.checkout && res.checkout.lineItems) {
      res.checkout.lineItems.edges.forEach(
        (lineItem: CheckoutFragment_lineItems_edges) => {
          if (lineItem?.node?.variant) {
            lineItems.push({
              variantId: lineItem.node.variant.id,
              quantity: lineItem.node.quantity,
            });
          }
        }
      );
    }

    if (res.checkout) {
      return createCartApi(
        lineItems,
        res.checkout.note,
        currency,
        res.checkout.email,
        customerAccessToken,
        customer
      );
    }
  }

  throw new Error();
}

export async function checkoutMultipassApi(hash: string) {
  return fetch('/api/v1/checkout', {
    method: 'POST',
    headers: { Authorization: hash },
  }).then(response => response.json());
}

/**
 * add more variants to cart
 */
export async function addMultipleVariantsToCartApi(
  lineItems: ILineItemMutation[],
  currency: ICurrencyInfo,
  email: string,
  customerAccessToken: string | null,
  customer: any
) {
  const cart = await getLastCart();

  if (!cart) {
    return await createCartApi(
      lineItems,
      null,
      currency,
      email,
      customerAccessToken,
      customer
    );
  }

  const input = {
    mutation: checkoutLineItemsAdd,
    variables: {
      checkoutId: cart.id,
      lineItems: lineItems,
      country: customer.defaultAddress.countryCodeV2 || currency.isoCountryCode,
    },
  };

  const result: checkoutLineItemsAddType = await fetcher(JSON.stringify(input));

  return {
    checkout: result?.checkoutLineItemsAdd?.checkout ?? null,
    status:
      typeof result?.checkoutLineItemsAdd?.userErrors !== 'undefined' &&
        result.checkoutLineItemsAdd.userErrors.length > 0
        ? 'failed'
        : 'success',
    error: result?.checkoutLineItemsAdd?.userErrors?.[0]?.message ?? '',
  };
}

/**
 * update more variants in cart
 */
export async function multipleUpdateCartApi(
  lineItems: ICartItem[],
): Promise<{
  checkout: CheckoutFragment | null;
  status: string;
  error: string;
}> {
  const cart = await getLastCart();

  if (!cart) {
    return { checkout: null, status: 'error', error: '' };
  }

  const input = {
    mutation: checkoutLineItemsUpdate,
    variables: {
      checkoutId: cart.id,
      lineItems: lineItems.map(item => ({
        id: item.id,
        variantId: item.variant?.id,
        quantity: item.quantity,
      })),
    },
  };

  const result = await fetcher(JSON.stringify(input));

  return result?.checkoutLineItemsUpdate
    ? {
      checkout: result.checkoutLineItemsUpdate.checkout,
      status: 'success',
      error: '',
    }
    : {
      checkout: null,
      status: 'failed',
      error: result.errors || [],
    };
}

/**
 * Retrieves a checkout
 * @param isoCountryCode es. "DE"
 * @param checkoutId
 * @returns Promise<CheckoutReachFragment>
 */
export async function loadCheckoutApi(
  isoCountryCode: string,
  checkoutId: string
): Promise<CheckoutReachFragment> {
  const input = {
    query: checkoutReachQuery,
    variables: {
      id: checkoutId,
      country: isoCountryCode,
    },
  };

  const checkout: { node: CheckoutReachFragment } = await fetcher(
    JSON.stringify(input)
  );

  if (!checkout.node) {
    throw new Error('Impossible to load checkout');
  }

  return checkout.node;
}

/**
 * retrieves a session id for Reach SDK starting
 */
export async function initReachSession(
  payload: InitReachSessionRequest
): Promise<InitReachSessionResponse> {
  const authorizationToken = await getAuthorizationToken();

  const fetchSession = await fetch(
    `${process.env.NEXT_PUBLIC_CHECKOUT_API}/checkout`, // backend api CreateCheckout
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorizationToken.access_token}`,
      },
      body: JSON.stringify(payload),
    }
  );
  const response: InitReachSessionResponse = await fetchSession.json();

  if (!response.data) {
    let errorMsg = response?.message || response.toString();
    if (response.errors) {
      const errorObj = response.errors[0];
      errorMsg =
        errorObj.status +
        ' ' +
        errorObj.code +
        ' ' +
        errorObj.title +
        ' ' +
        errorObj.detail;
    }
    throw new Error('Impossible to start reach session : ' + errorMsg);
  }
  return response;
}

/** retrieves a draft order */
export async function getDraftOrder(
  client: ApolloClient<NormalizedCacheObject>,
  draftOrderId: string
): Promise<ICheckoutDraftOrder> {
  const draftOrder = await client.query<DraftOrder>({
    query: getDraftOrderQuery,
    variables: {
      id: `gid://shopify/DraftOrder/${draftOrderId}`,
    },
  });

  if (!draftOrder.data?.draftOrder) {
    throw new Error('Draft order not found');
  }

  return {
    ...draftOrder.data.draftOrder,
    lineItems: await retrieveLineItems(client, draftOrderId),
  };
}

async function retrieveLineItems(
  client: ApolloClient<NormalizedCacheObject>,
  draftOrderId: string
) {
  const getLineItems = async (cursor?: string) =>
    client.query<DraftOrderWithLineItems>({
      query: getDraftOrderLineItemsQuery,
      variables: {
        id: `gid://shopify/DraftOrder/${draftOrderId}`,
        after: cursor,
      },
    });
  const lineItems: DraftOrderLineItem[] = [];

  let cursor: string | undefined = undefined;

  do {
    const retrievedLineItems: { data: DraftOrderWithLineItems } =
      await getLineItems(cursor);

    lineItems.push(
      ...(retrievedLineItems.data.draftOrder?.lineItems.edges.map(
        node => node.node
      ) ?? [])
    );

    if (retrievedLineItems.data.draftOrder?.lineItems.pageInfo.hasNextPage) {
      cursor =
        retrievedLineItems.data.draftOrder.lineItems.edges.at(-1)?.cursor;
    } else {
      cursor = undefined;
    }
  } while (cursor);

  return lineItems;
}

export async function updateCheckoutShippingAddressApi(
  checkoutId: string,
  shippingAddress: MailingAddressInput,
): Promise<{
  checkoutShippingAddressUpdateV2: {
    userErrors?: { message: string; field: string }[];
    checkout: CheckoutReachFragment;
  };
}> {
  const input = {
    mutation: checkoutShippingAddressUpdate,
    variables: {
      checkoutId: checkoutId,
      input: { ...shippingAddress },
    },
  };
  const checkout = await fetcher(JSON.stringify(input));
  return checkout;
}

export async function getShippingRates(checkoutId: string) {
  const input = {
    query: checkoutShippingRatesQuery,
    variables: {
      checkoutId,
    },
  };

  const result: checkoutShippingRatesQueryType = await fetcher(
    JSON.stringify(input)
  );

  if (result?.node?.__typename !== 'Checkout') {
    throw new Error('Checkout id not valid');
  }

  return result.node.availableShippingRates?.shippingRates ?? null;
}

export async function updateCheckoutShippingRate(
  checkoutId: string,
  shippingRateHandle: string,
  currency: ICurrencyInfo
): Promise<CheckoutReachFragment> {
  const input = {
    mutation: checkoutShippingLineUpdate,
    variables: {
      checkoutId,
      shippingRateHandle,
      country: currency.isoCountryCode,
    },
  };
  const checkout = await fetcher(JSON.stringify(input));
  return checkout.checkoutShippingLineUpdate.checkout;
}

//? is this function needed?  it was used in Payment organism to retrieve client-side the reach sessionId, but reach sessionId comes already from the server
export async function createCheckoutReachSession(checkoutId: string) {
  return fetch(`/api/v1/checkout/${encodeBase64(checkoutId)}`).then(response =>
    response.json()
  );
}

export const selectPaymentProvider = async (
  checkoutId: selectPaymentProviderRequest['checkoutId'],
  payload: selectPaymentProviderRequest['payload']
): Promise<SelectPaymentProviderResponse | null> => {
  const authorizationToken = await getAuthorizationToken();
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_CHECKOUT_API}/checkout/${checkoutId}`, // backend api Select Payment Provider
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorizationToken.access_token}`,
      },
      body: JSON.stringify(payload),
    }
  );

  try {
    return await res.json();
  } catch (err) {
    // 204 ok response with no body
    return null;
  }
};
