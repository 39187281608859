import { multipleProductVariantQuantity } from '@/graphQL/Shopify/queries/multipleProductVariantQuantity';
import { productByHandleQuery } from '@/graphQL/Shopify/queries/productByHandleQuery';
import { productIdFutureFashion } from '@/graphQL/Shopify/queries/productIdFutureFashion';
import { productVariantQuantity } from '@/graphQL/Shopify/queries/productVariantQuantity';
import { safeFetcher } from '@/lib/api';
import { httpGateway } from '@/shared/HttpGateway';
import { SearchResultResponse as SearchResultResponseProduct } from '@/types/catalog';
import { multipleProductVariantQuantity as multipleProductVariantQuantityType } from '@/types/shopify/multipleProductVariantQuantity';
import { productIdFutureFashion as productIdFutureFashionType } from '@/types/shopify/productIdFutureFashion';
import { productVariantQuantity as productVariantQuantityType } from '@/types/shopify/productVariantQuantity';
import { makeCatalogQuery } from '@/utils/Catalog';
import { SUPPORTED_CURRENCIES } from '@/utils/Currency';
import { FetchError } from '@/utils/typings/errors';
import { mapResult, partialSequence, Result } from '@/utils/typings/result';
import { IProductResponse } from './../types/IProductResponse';
import { mapSearchResult } from './models/mappers/product';

export async function getProductsByHandles(productHandleArray: string[]) {
  const data = productHandleArray.map(getProductCatalogByHandle);

  const results = partialSequence(await Promise.all(data));
  return mapResult(results, results =>
    results.flatMap(r => (r.data ? [r.data] : []))
  );
}

export function getCartProductCrossSell(handle: string) {
  const key = {
    query: productByHandleQuery,
    variables: {
      handle: handle,
      currency: SUPPORTED_CURRENCIES,
    },
  };
  return key;
}

export function getProductIdFutureFashion(handle: string) {
  const query = {
    query: productIdFutureFashion,
    variables: {
      handle: handle,
    },
  };

  return safeFetcher<productIdFutureFashionType>(query);
}

export async function getProductSEO(handle: string) {
  const url = `${process.env.NEXT_PUBLIC_CATALOG_API}/products/handle/${handle}`;

  return fetch(url).then(response => response.json());
}

export async function getProductCatalogByHandle(handle: string) {
  const url = `${process.env.NEXT_PUBLIC_CATALOG_API}/products/handle/${handle}`;
  return httpGateway.get<IProductResponse>(url).catch();
}

export function getProductVariantQuantity(id: string) {
  const query = {
    query: productVariantQuantity,
    variables: {
      id: id,
    },
  };
  return safeFetcher<productVariantQuantityType>(query);
}

export function getProductsCollection(collection: string, limit: number) {
  const url = makeCatalogQuery(0, limit, { collection });

  return httpGateway.get<SearchResultResponseProduct>(url);
}

export async function getProductsArtisan(
  artisan_slug: string,
  limit: number
): Promise<Result<SearchResultResponseProduct, FetchError>> {
  const url = makeCatalogQuery(0, limit, { artisan: artisan_slug });

  return httpGateway.get<SearchResultResponseProduct>(url);
}

export async function getProductsArtisanById(
  id: string,
  limit: number
): Promise<Result<SearchResultResponseProduct, FetchError>> {
  const url = makeCatalogQuery(0, limit, { 'artisan-registry-id': id });
  return httpGateway.get(url);
}

export async function getMultipleProductVariantQuantity(ids: string[]) {
  const query = {
    query: multipleProductVariantQuantity,
    variables: {
      ids: ids,
    },
  };
  return safeFetcher<multipleProductVariantQuantityType>(query);
}

export async function getPersonalizedProducts(
  limit: number,
  gender: string,
  category_path: string,
  segmentPrice?: string
) {
  const url = makeCatalogQuery(0, limit, {
    gender: gender,
    'category-path': category_path,
    ...(segmentPrice ? { 'segment-price': segmentPrice } : {}),
  });

  return httpGateway.get(url).then(res => mapResult(res, mapSearchResult));
}
