import { createContext, useContext } from 'react';
import { ISettingsFields } from '@/types/contentful/contentful';
import { ISettingsTheme } from '@/theme/types';

interface IContext {
  isCrawler?: boolean;
  pageType?: string | null;
  settings?: ISettingsFields | null;
  settingsTheme: ISettingsTheme;
}

interface IAppWrapper {
  children: {
    props: IContext;
  };
}

const AppContext = createContext<IContext | null>(null);

export function AppWrapper({ children }: IAppWrapper) {
  let sharedState: IContext = {
    isCrawler: children.props.isCrawler,
    pageType: children.props.pageType || null,
    settings: children.props.settings || null,
    settingsTheme: children.props.settingsTheme || null,
  };

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
